import React from 'react';

const NotFound = () => {
    let  homeLink = '/dashboard';

    if (localStorage['userGroup'] === undefined) {
        homeLink = '/';
    }

    return (
        <>
            <div className="forgot-bg">
                <img src="/images/login.jpg" className="fixed-image" alt="Laptop" type="Login"/>
                <div className="center">
                    <h1>Page not found</h1>
                    <a href={homeLink} className="btn">Back to Yadore</a>
                </div>
            </div>
        </>
    );
};

export default NotFound;

import React from 'react';
import Ajax from '../../../Helper/Ajax';
import BlobDownloadHelper from "../../../Helper/BlobDownloadHelper";

type CsvExportButtonProps = {
    getParametersFunction: Function,
    changeState: Function,
    csvControllerUrl: string,
    exportFilename: string,
    warningMessage: string,
}

type CsvExportButtonState = {
    isBusy: boolean,
}

class CsvExportButton extends React.Component<CsvExportButtonProps, CsvExportButtonState> {
    state: CsvExportButtonState = {
        isBusy: false,
    };

    createCsv(): void {
        if (this.props.warningMessage !== '') {
            // eslint-disable-next-line no-restricted-globals
            if (!confirm(this.props.warningMessage)) {
                return;
            }
        }

        const parametersArray = this.props.getParametersFunction();
        const paramtersFormated = [];
        for (const key in parametersArray) {
            paramtersFormated.push(key + '=' + parametersArray[key]);
        }
        const parameters = '?' + paramtersFormated.join('&');

        const url = `${this.props.csvControllerUrl}${parameters}`;
        const promise: Promise<string> = Ajax.createGetXhr(url);

        this.setState({ isBusy: true });

        promise.then(response => {
            const parsedResponse = JSON.parse(response);

            if (parsedResponse.errorMessage || parsedResponse.warningMessage) {
                this.props.changeState({
                    isErrorCritical: !parsedResponse.success,
                    errorMessage: parsedResponse.errorMessage || parsedResponse.warningMessage,
                });
                return;
            }

            this.createFileDownload(parsedResponse);
            this.props.changeState({errorMessage: ''});
            this.setState({ isBusy: false });
        }).catch(error => {
            console.error(error);
            this.props.changeState({
                errorMessage: 'An unexpected error occured. Please try again later.',
            });
        });
    }

    createFileDownload(parsedResponse) {
        const datePrefix = BlobDownloadHelper.getDatePrefix();
        const filename = datePrefix + '-' + this.props.exportFilename + '.csv';

        // IE-Weiche
        if (window.navigator.msSaveOrOpenBlob) {
            // Internet Explorer
            window.navigator.msSaveOrOpenBlob(new Blob([parsedResponse.data.csvString], {type: 'text/plain;charset=utf-8'}), filename);
            return;
        }

        const blob = new Blob([parsedResponse.data.csvString], {
            type: "text/plain;charset=utf-8"
        });

        const downloadFilename = datePrefix + '-' + this.props.exportFilename + '.csv';
        BlobDownloadHelper.downloadBlob(parsedResponse.data.csvString, downloadFilename);
    }

    renderSpinner() {
        if (this.state.isBusy) {
            return <span className="spinner-border text-primary float-right csv-export-button-spinner" />;
        }

        return '';
    }

    render() {
        return (
            <>
                <button
                    className="btn btn-secondary buttons-csv buttons-html5"
                    tabIndex="0"
                    aria-controls="datatable-buttons"
                    type="button"
                    name="csvexport"
                    onClick={this.createCsv.bind(this)}
                    value="export"
                    disabled={ this.state.isBusy }
                >
                    <span className="float-left">Export as CSV</span>
                    { this.renderSpinner() }
                </button>
            </>
        );
    }
}

export default CsvExportButton;

import React from 'react';

type FooterProps = {
}

class Footer extends React.Component<FooterProps> {
  render() {
    if (window.location.pathname.indexOf('/admin/credit-notes/detail/') !== -1) {
      return '';
    }

    return (
        <footer className="footer yde-footer">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <p>© Copyright 2023 Yadore GmbH</p></div>
              <div className="col-md-6">
                <div className="text-md-right footer-links">
                  <a href="https://yadore.com/en/imprint/" target="_blank" rel="noopener noreferrer">Imprint</a>
                  <a href="https://yadore.com/en/privacy/" target="_blank" rel="noopener noreferrer">Privacy policy</a></div>
              </div>
            </div>
          </div>
        </footer>
    );
  }
}

export default Footer;

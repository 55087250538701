import React from 'react';
import {AvFeedback, AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";

type CreditNoteLineItemAddProps = {
    creditNoteAction: Function,
    creditNoteId: String,
    creditNoteAggregateStatus: number,
}

type AddLineItemState = {
    description: string,
    amount: string,
}

class CreditNoteLineItemAdd extends React.Component<CreditNoteLineItemAddProps, AddLineItemState> {
    state: AddLineItemState = {
        description: '',
        amount: '',
    };

    isValid = [];

    updateState(key: string, e: Event): void {
        this.setState({
            ...this.state,
            [key]: e.target['value'],
        });
    }

    validateDescriptionInput(value, ctx, input, cb) {
        if (value.length >= 3 && value.length <= 255) {
            if (
                value.match(/^[-A-Za-z0-9äöüÄÖÜ,.:;#+*~!?"ß='&§)($% ]*$/) !== null
            ) {
                this.isValid[input.props.name] = true;
                cb(true);
                return;
            }
        }

        this.isValid[input.props.name] = false;
        cb(false);
    }

    validateAmountInput(value, ctx, input, cb) {
        if (value.length >= 1 && value.length <= 10) {
            if (
                value.match(/^[-0-9]?[0-9]*[.]?[0-9]{0,2}$/) !== null
            ) {
                this.isValid[input.props.name] = true;
                cb(true);
                return;
            }
        }

        this.isValid[input.props.name] = false;
        cb(false);
    }

    saveLineItem() {
        for (let valid in this.isValid) {
            if (!this.isValid.hasOwnProperty(valid)) {
                this.setState({
                    errorCode: 500,
                    success: false,
                    errorMessage: 'Property "' + valid + '" does not exist in array.'
                });
                return;
            }

            if (this.isValid[valid] === false) {
                return;
            }
        }

        this.props.creditNoteAction(
            '/admin/add-credit-note-line-item',
            {
                creditNoteId: this.props.creditNoteId,
                description: this.state.description,
                amount: this.state.amount,
            }

        );
    }

    render() {
        if (this.props.creditNoteAggregateStatus !== 1) {
            return '';
        }
        return <>
            <AvForm>
                <div className="row">
                    <AvGroup>
                        <div className="col-lg-12">
                            <div className="d-flex">
                                <div className="d-flex">
                                    <div className="cn-list-item-input">
                                        <div className="d-flex">
                                            <AvGroup>
                                                <AvInput
                                                    className="cn-input-description"
                                                    name="description"
                                                    id={`description-` + this.props.creditNoteId}
                                                    onChange={this.updateState.bind(this, 'description')}
                                                    validate={{custom: this.validateDescriptionInput.bind(this)}}
                                                    required
                                                />
                                                <AvFeedback className="float-left">Invalid field</AvFeedback>
                                            </AvGroup>
                                        </div>
                                    </div>
                                    <div className="cn-list-item-input">
                                        <div className="cn-input-amount-padding-left">
                                            <AvGroup>
                                                <AvInput
                                                    className="cn-input-amount"
                                                    name="amount"
                                                    id={`amount-` + this.props.creditNoteId}
                                                    onChange={this.updateState.bind(this, 'amount')}
                                                    validate={{custom: this.validateAmountInput.bind(this)}}
                                                    required
                                                />
                                                <AvFeedback className="float-right">Invalid field</AvFeedback>
                                            </AvGroup>
                                        </div>
                                    </div>
                                </div>
                                <div className="margin-left-button">
                                    <button
                                        className="btn btn-xs btn-outline-success color-white"
                                        type="submit"
                                        name="add"
                                        onClick={() => this.saveLineItem()}
                                        title={'add'}
                                    >
                                        <i className="mdi mdi-plus"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </AvGroup>
                </div>
            </AvForm>
        </>
    }
}

export default CreditNoteLineItemAdd;

import React from 'react';
import ArrayDropDown from '../ArrayDropDown';
import ObjectDropDown from '../ObjectDropDown';
import Flatpickr from 'react-flatpickr'
import Search from '../Search';
import DatePickerHelper from '../../../../Helper/DatePickerHelper';

type MerchantFilterProps = {
    urlProps: {
        market: string,
        project: string,
        dateFrom: string,
        dateTo: string,
        merchantName: string
    },
    markets: [],
    projects: {},
}

class MerchantFilter extends React.Component <MerchantFilterProps> {
    state = {
        market: this.props.urlProps.market || 'all',
        project: this.props.urlProps.project || 'all',
        startDate: DatePickerHelper.initDate(this.props.urlProps.dateFrom),
        endDate: DatePickerHelper.initDate(this.props.urlProps.dateTo),
        merchantName: this.props.urlProps.merchantName || 'all',
    };

    static getYMDFromDateString(dateString: String | Date): Array {
        if (typeof dateString === 'object') {
            return [dateString.getUTCFullYear(), dateString.getUTCMonth() + 1, dateString.getUTCDate()];
        }
        const ymd = dateString.split('-');
        ymd[1]--;
        return ymd;
    }

    updateList(filterName: string, value: string): void {
        this.setState({
            [filterName]: value,
            statisticLoaded: false
        }, () => {
            this.props.loadFromApi(this.state);
        });
    }

    updateSearchValue(merchantName: string): void {
        if(!merchantName) {
            merchantName = 'all'
        }

        this.setState({
            merchantName: encodeURIComponent(merchantName),
        }, () => {
            this.props.loadFromApi(this.state);
        });
    }

    updateState(name: string, value: string): void {
        this.setState({
            [name]: value
        }, () => {
            this.props.loadFromApi(this.state);
        });
    }

    render() {
        const startDate = this.state.startDate;
        const endDate   = this.state.endDate;
        const monthFrom = startDate.month - 1;
        const monthTo   = endDate.month - 1;

        const today       = DatePickerHelper.getYesterday();
        const minDateFrom = new Date(2017, 8, 1, 0, 0, 0, 0);
        const maxDateFrom = new Date(endDate.year, (endDate.month - 1), endDate.day, 0,  0, 0, 0);
        const dateFrom    = new Date(startDate.year, monthFrom, startDate.day, 0, 0, 0, 0);

        const minDateTo = new Date(startDate.year, monthFrom, startDate.day, 0, 0, 0, 0);
        const maxDateTo = new Date(today.year, (today.month - 1), today.day, 0, 0, 0, 0);
        const dateTo    = new Date(endDate.year, monthTo, endDate.day, 0, 0, 0, 0);

        return (
            <>
                <div className="col-sm-2 col-md-2">
                    <div id="datatable-buttons_filter" className="dataTables_filter">
                        <label>Date from:</label>
                        <Flatpickr
                            className={'form-control'}
                            dateformat={'YYYY-MM-dd'}
                            value={ dateFrom }
                            onChange={DatePickerHelper.handleChangeStartDate.bind(
                                this,
                                this.state.endDate,
                                this.updateState.bind(this),
                                'startDate'
                            )}
                            options={
                                {
                                    minDate: minDateFrom,
                                    maxDate: maxDateFrom,
                                    locale: {
                                        'firstDayOfWeek': 1,
                                    },
                                    monthSelectorType: 'static',
                                    defaultHour: 0,
                                    disableMobile:true
                                }
                            }
                        />
                    </div>
                </div>
                <div className="col-sm-2 col-md-2">
                    <div id="datatable-buttons_filter" className="dataTables_filter">
                        <label>Date to:</label>
                        <Flatpickr
                            className={'form-control'}
                            dateformat={'YYYY-MM-dd'}
                            value={ dateTo }
                            onChange={DatePickerHelper.handleChangeEndDate.bind(
                                this,
                                this.state.startDate,
                                this.updateState.bind(this),
                                'endDate'
                            )}
                            options={
                                {
                                    minDate: minDateTo,
                                    maxDate: maxDateTo,
                                    locale: {
                                        'firstDayOfWeek': 1,
                                    },
                                    monthSelectorType: 'static',
                                    defaultHour: 0,
                                    disableMobile:true

                                }
                            }
                        />
                    </div>
                </div>
                <div className="col-sm-2 col-md-2">
                    <div id="datatable-buttons_filter" className="dataTables_filter">

                        <label>Filter market:</label>
                        <ArrayDropDown
                            options={this.props.markets}
                            name={"market"}
                            defaultValue={"all"}
                            defaultName={"all"}
                            selectedValue={this.state.market}
                            updateList={this.updateList.bind(this)}
                            disabled={false}
                        />
                    </div>
                </div>
                <div className="col-sm-2 col-md-2">
                    <div id="datatable-buttons_filter" className="dataTables_filter">

                        <label>Filter project:</label>
                        <ObjectDropDown
                            options={this.props.projects}
                            name={"project"}
                            defaultValue={"all"}
                            defaultName={"all"}
                            selectedValue={this.state.project}
                            updateList={this.updateList.bind(this)}
                        />
                    </div></div>
                <div className="col-sm-2 col-md-2">
                    <div id="datatable-buttons_filter" className="dataTables_filter">

                        <label>Search for Merchant:</label>
                        <Search
                            updateSearchValue={this.updateSearchValue.bind(this)}
                            minLength={0}
                            initialSearchTerm={this.props.urlProps.merchantName}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default MerchantFilter;

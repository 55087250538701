// @flow
/* eslint-disable flowtype/require-return-type */
import React from 'react';

type ErrorProperties = {
  myTarget: string,
  givenTarget: string,
  isErrorCritical: boolean,
  errorMessage: string,
}

class ErrorWithTarget extends React.Component<ErrorProperties> {
  render() {
    if (this.props.myTarget !== this.props.givenTarget) {
      return (
        <React.Fragment>
          <div>
          </div>
        </React.Fragment>
      );
    }

    let errorMessage = '';
    if (this.props.errorMessage) {
      errorMessage = this.props.errorMessage.toString();
    }

    if (errorMessage === '') {
      return '';
    }

    let alertClass = 'alert alert-warning';
    if (this.props.isErrorCritical) {
      alertClass = 'alert alert-danger';
    }

    return (
      <React.Fragment>
        <br/>&nbsp;<br/>
        <div className={alertClass} role="alert">
          {errorMessage}
        </div>
      </React.Fragment>
    );
  }
}

export default ErrorWithTarget;

import React from 'react';
import {AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import {Button, FormGroup, Label} from 'reactstrap';
import Success from '../../../App/components/Messages/Success';
import Error from '../../../App/components/Messages/Error';
import Ajax from '../../../../Helper/Ajax';

type SetEmailProps = {
  publisherId: string,
}

type SetEmailState = {
  success: boolean,
  successMessage: string,
  isErrorCritical: boolean,
  errorMessage: string,
  email: string,
}

class SetEmail extends React.Component<SetEmailProps, SetEmailState> {
  state: SetEmailState = {
    success: false,
    successMessage: '',
    isErrorCritical: false,
    errorMessage: '',
    email: '',
  };

  emailRef = React.createRef();

  componentDidMount(): void {
    const promise = Ajax.createGetXhr(
      process.env.REACT_APP_API_URL + '/admin/account-settings/get-email?publisherId=' + this.props.publisherId
    );

    promise.then(response => {
      const parsedResponse = JSON.parse(response);

      if (parsedResponse.errorMessage || parsedResponse.warningMessage) {
        this.setState({
          success: false,
          isErrorCritical: !parsedResponse.success,
          errorMessage: parsedResponse.errorMessage || parsedResponse.warningMessage,
        });
        return;
      }

      this.setState({
        email: parsedResponse.data.emailAddress,
      });
    }).catch(error => {
      console.error(error);
      this.setState({
        ...this.state,
        success: false,
        isErrorCritical: true,
        errorMessage: 'An unexpected error occured. Please try again later.',
      });
    });
  }

  submit(): void {
    const promise = Ajax.createPostXhr(process.env.REACT_APP_API_URL + '/admin/account-settings/set-email', {
      publisherId: this.props.publisherId,
      emailAddress: this.emailRef.current.value,
    });

    promise.then(response => {
      const parsedResponse = JSON.parse(response);

      if (parsedResponse.errorMessage || parsedResponse.warningMessage) {
        this.setState({
          success: false,
          isErrorCritical: !parsedResponse.success,
          errorMessage: parsedResponse.errorMessage || parsedResponse.warningMessage,
        });
        return;
      }

      this.setState({
        errorMessage: '',
        success: true,
        successMessage: 'E-mail address successfully set.',
      });
    }).catch(error => {
      console.error(error);
      this.setState({
        ...this.state,
        success: false,
        isErrorCritical: true,
        errorMessage: 'An unexpected error occured. Please try again later.',
      });
    });
  }

  render() {
    return <>
      <div className="row">

        <div className="col-xl-6">
          <div className="card-box">
            <AvForm onValidSubmit={this.submit.bind(this)}>

              <AvGroup className="mb-3">
                <Label for="email-input">E-mail</Label>
                <AvInput
                  ref={this.emailRef}
                  type="email"
                  id="email-input"
                  name="email-input"
                  placeholder="Enter an e-mail address..."
                  value={this.state.email}
                />
              </AvGroup>

              <FormGroup>
                <Button color="primary" className="btn-block">Set e-mail</Button>
              </FormGroup>

              <Success
                success={this.state.success}
                successMessage={this.state.successMessage}
              />
              <Error
                isErrorCritical={this.state.isErrorCritical}
                errorMessage={this.state.errorMessage}
              />

            </AvForm>
          </div>
        </div>

      </div>
    </>;
  }
}

export default SetEmail;

import React from 'react';
import type { Project } from './DayStatistics';

type DropDownProps = {
  options: Array<string|Project>,
  name: string,
  updateList: Function,
  defaultValue: string,
  defaultName: string,
  selectedValue: string,
  disabled: boolean,
}

class ArrayDropDown extends React.Component<DropDownProps> {
  updateParentState(inputRef) {
    this.props.updateList(this.props.name, inputRef.current.value);
  }

  render() {
    const inputRef = React.createRef();

    const optionElements = [];

    optionElements.push(<option key={'default'} value={this.props.defaultValue}>{this.props.defaultName}</option>);

    if (this.props.options.constructor !== Array) {
      console.error('The ArrayDropDown component can only handle an array.');
      return;
    }

    for (let option of this.props.options) {
      if (typeof option === 'object') {
        optionElements.push(<option key={option.id || option.market} value={option.id || option.market}>{option.name || option.market}</option>);
        continue;
      }
      optionElements.push(<option key={option} value={option}>{option}</option>);
    }

    return (
        <select
          value={this.props.selectedValue}
          ref={inputRef}
          name={this.props.name}
          onChange={this.updateParentState.bind(this, inputRef)}
          className={'form-control'}
          disabled={this.props.disabled}
        >
          {optionElements}
        </select>
    );
  }
}

export default ArrayDropDown;

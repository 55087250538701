import React from "react";
import PageTitle from "../../Layout/PageTitle";
import Ajax from "../../../Helper/Ajax";
import ReactJson from "react-json-view";
import {Card, CardBody} from "reactstrap";
import Restricted from "../../../Helper/PermissionProvider/Restricted";

type AdminDashboardState = {
    isErrorCritical: boolean,
    errorMessage: string,
    opCacheStatsLoaded: boolean,
    opCacheStats: [],
}

class AdminDashboard extends React.Component<{}, AdminDashboardState> {
    state: AdminDashboardState = {
        isErrorCritical: true,
        errorMessage: '',
        opCacheStatsLoaded: false,
        opCacheStats: [],
    };

    loadOpCacheStats(): void {
        if (this.state.opCacheStatsLoaded || this.state.errorMessage !== '') {
            return;
        }

        const promise: Promise<string> = Ajax.createGetXhr(process.env.REACT_APP_API_URL + '/admin/get-op-cache-stats');

        this.setState({ opCacheStatsLoaded: true });

        promise.then(response => {
            const parsedResponse = JSON.parse(response);

            if (parsedResponse.errorMessage || parsedResponse.warningMessage) {
                this.setState({
                    isErrorCritical: !parsedResponse.success,
                    errorMessage: parsedResponse.errorMessage || parsedResponse.warningMessage,
                });

                return;
            }

            this.setState({
                opCacheStats: parsedResponse.data,
                errorMessage: '',
            });
        }).catch(error => {
            console.error(error);
            this.setState({
                errorMessage: 'An unexpected error occured. Please try again later.',
            });
        });
    }

    componentDidMount(): void {
        const fetchPermission = Ajax.fetchPermission('system_info.read');
        fetchPermission.then((isAllowedTo) => {
            if (isAllowedTo) {
                this.loadOpCacheStats();
            }
        });
    }

    renderOpCacheStats() {
        if (process.env.REACT_APP_ENV === 'production') {
            return null;
        }

        return <Card>
            <Restricted to={'system_info.read'}>
                <CardBody>
                    <label>OPcache stats:</label>
                    <ReactJson
                        src={this.state.opCacheStats}
                        name={false}
                        iconStyle={'square'}
                        indentWidth={4}
                        displayDataTypes={false}
                    />
                </CardBody>
            </Restricted>
        </Card>;
    }

    render() {
        return (
            <>
                <PageTitle
                    title="Dashboard"
                    breadcrumbs={[
                        {
                          name: 'Dashboard',
                        },
                    ]}
                />
                {this.renderOpCacheStats()}
            </>
        );
    }
}

export default AdminDashboard;

import React from 'react';
import PageTitle from '../../../Layout/PageTitle';
import PageRow from '../../../Layout/PageRow';
import ChangePassword from './ChangePassword';

class MyAccount extends React.Component<{}> {
    render() {
        return (
            <>
                <PageTitle
                    title="Change password"
                    breadcrumbs={[
                        {
                            link: '/dashboard',
                            name: 'Dashboard',
                        },
                        {
                            name: 'Change password',
                        },
                    ]}
                />

                <PageRow>
                    <ChangePassword />
                </PageRow>
            </>
        );
    }
}

export default MyAccount;

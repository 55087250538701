import React from "react";
import {Permission} from "./PermissionProvider";
import usePermission from "./usePermisson";

type Props = {
  to: Permission;
  fallback?: JSX.Element | string;
  loadingComponent?: JSX.Element | string;
}

const Restricted: React.FC<Props> = ({to, fallback, loadingComponent, children}) => {
  const [loading, isAllowed] = usePermission(to);
  return (
    <React.Fragment>
      {
        (() => {
          if (loading) {
            return loadingComponent;
          }
          if (isAllowed) {
            return children;
          }
          return fallback ;
        })()
      }
    </React.Fragment>
  );
}

export default Restricted;

import React from 'react';
import Ajax from '../../../../Helper/Ajax';
import Error from '../../../App/components/Messages/Error';
import ClicksStatisticsFilters, { Filters } from './ClicksStatisticsFilters';
import DataTable from 'react-data-table-component';
import CsvClickStatisticsExportButton from '../CsvClickStatisticsExportButton';
import PageTitle from '../../../Layout/PageTitle';
import PageRow from '../../../Layout/PageRow';
import DatePickerHelper from "../../../../Helper/DatePickerHelper";
import HelpFunctions from "../../../../Helper/HelpFunctions";
import PublisherStatisticInfo from "../PublisherStatisticInfo";
import { BrowserView } from "react-device-detect";

type ClicksStatisticsRouteMatch = {
  isExact: true,
  params: Filters,
}

type ClicksStatisticsProps = {
  match: ClicksStatisticsRouteMatch
}

type ClicksStatisticsState = {
  isErrorCritical: boolean,
  errorMessage: String,
  clicks: Array,
  maxCount: Number,
  dataTableProgressPending: boolean,
  filters: Filters,
}

const dataTableColumns = [
  {
    name: 'Merchant',
    selector: 'domain',
    grow: 1.5,
    ignoreRowClick: true,
  },
  {
    name: 'Market',
    selector: 'market',
    grow: 0.1,
    minWidth:'70px',
    ignoreRowClick: true,
  },
  {
    name: 'CPC (€)',
    selector: 'revenue',
    grow: 0.2,
    minWidth:'80px',
    cell: row => <span className={'float-right'}>{HelpFunctions.addRevenueSymbolAndNull(HelpFunctions.addCommas(row.revenue))}</span>,
    right: true,
    ignoreRowClick: true,
  },
  {
    name: 'Placement ID',
    selector: 'placementId',
    ignoreRowClick: true,
  },
  {
    name: 'Click date',
    selector: 'clickDate',
    width: 80,
    ignoreRowClick: true,
  },
];

class ClicksStatistics extends React.Component<ClicksStatisticsProps, ClicksStatisticsState> {
  state: ClicksStatisticsState = {
    isErrorCritical: true,
    errorMessage: '',
    clicks: [],
    maxCount: 0,
    dataTableProgressPending: false,
    filters: {
      dateFrom: DatePickerHelper.initDate(this.props.match.params.dateFrom),
      dateTo: DatePickerHelper.initDate(this.props.match.params.dateTo),
      page: parseInt(this.props.match.params.page) || 1,
      resultsPerPage: parseInt(this.props.match.params.resultsPerPage) || 500,
    },
  };

  static updateUrl(filters: Filters): void {
    let newUrl = window.location.protocol + '//' + window.location.host + '/clicks-statistics';

    const dateFrom = DatePickerHelper.getStringFromYmd(filters.dateFrom);
    const dateTo   = DatePickerHelper.getStringFromYmd(filters.dateTo);

    newUrl += '/' + dateFrom + '/' + dateTo + '/' + filters.page + '/' + filters.resultsPerPage;

    // eslint-disable-next-line
    history.pushState({url: newUrl}, 'Statistic Page', newUrl);
  }

  validateFilters(filters: Filters): boolean {
    if (filters.page < 1) {
      this.setState({
        errorMessage: 'Page must be a positive integer.',
      });

      return false;
    }

    if (filters.resultsPerPage !== 500 && filters.resultsPerPage !== 1000 && filters.resultsPerPage !== 2000) {
      this.setState({
        errorMessage: 'Results per page must be 500, 1000 or 2000.',
      });

      return false;
    }

    return true;
  }

  makeApiRequest(filters: Filters): void {
    if (this.validateFilters(filters) === false) {
      return;
    }

    const oldState = this.state;
    oldState.dataTableProgressPending = true;
    oldState.clicks = [];
    this.setState(oldState);

    const { dateFrom, dateTo, resultsPerPage } = filters;
    let page = filters.page;

    ClicksStatistics.updateUrl(filters);

    this.updateFiltersState(filters);

    const promise: Promise<String> = Ajax.createGetXhr(
      process.env.REACT_APP_API_URL + '/clicks-statistics'
        + '?dateFrom=' + DatePickerHelper.getStringFromYmd(dateFrom)
        + '&dateTo=' + DatePickerHelper.getStringFromYmd(dateTo)
        + '&page=' + page
        + '&resultsPerPage=' + resultsPerPage
    );

    promise.then(response => {
      const parsedResponse = JSON.parse(response);

      if (parsedResponse.errorMessage || parsedResponse.warningMessage) {
        this.setState({
          isErrorCritical: !parsedResponse.success,
          errorMessage: parsedResponse.errorMessage || parsedResponse.warningMessage,
          dataTableProgressPending: false,
        });
        return;
      }

      if (page > parsedResponse.data.maxPage) {
        page = parsedResponse.data.maxPage;
      }

      ClicksStatistics.updateUrl({...filters, page});

      this.setState({
        clicks: parsedResponse.data.clicks,
        maxCount: parsedResponse.data.maxCount,
        dataTableProgressPending: false,
        filters: {
          ...filters,
          page
        },
        errorMessage: '',
      });
    }).catch(error => {
      console.error(error);
      this.setState({
        errorMessage: 'An unexpected error occured. Please try again later.',
      });
    });
  }

  onChangeRowsPerPage(currentRowsPerPage: Number): void {
    const filters = {
      dateFrom: this.state.filters.dateFrom,
      dateTo: this.state.filters.dateTo,
      page: this.state.filters.page,
      resultsPerPage: currentRowsPerPage,
    };

    this.makeApiRequest(filters);
  }

  onChangePage(page: Number): void {
    const filters = {
      dateFrom: this.state.filters.dateFrom,
      dateTo: this.state.filters.dateTo,
      page,
      resultsPerPage: this.state.filters.resultsPerPage,
    };

    this.makeApiRequest(filters);
  }

  updateFiltersState(filters: Filters): void {
    const oldState = this.state;

    oldState.dateFrom       = filters.dateFrom;
    oldState.dateTo         = filters.dateTo;
    oldState.page           = filters.page;
    oldState.resultsPerPage = filters.resultsPerPage;

    this.setState(oldState);
  }

  componentDidMount(): void {
    this.makeApiRequest(this.state.filters);
  }

  getCurrentFilterState() {
    return {
      dateFrom:       DatePickerHelper.getStringFromYmd(this.state.filters.dateFrom),
      dateTo:         DatePickerHelper.getStringFromYmd(this.state.filters.dateTo),
      page:           this.state.filters.page,
      resultsPerPage: this.state.filters.resultsPerPage,
    };
  }

  changeState(paritalState: Object) {
    this.setState({...paritalState})
  }

  render() {
    return (
        <>
          <PageTitle
              title="Clicks statistics"
              breadcrumbs={[
                {
                  link: '/dashboard',
                  name: 'Dashboard',
                },
                {
                  link: '/clicks-statistics',
                  name: 'Clicks statistics',
                },
              ]}
          />

          <PageRow>
            <div className="card">
              <div className="card-body">
                <div id="datatable-buttons_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                  <div className="row">
                    <div className="col-md-12">
                      <div id="datatable-buttons_filter" className="dataTables_filter">
                        <div className="row">
                          <ClicksStatisticsFilters
                              onSubmit={this.makeApiRequest.bind(this)}
                              // updateFiltersState={this.updateFiltersState.bind(this)}
                              filters={this.state.filters}
                          />
                          <div className="col-sm-2 col-md-2 filter_button">
                            <div id="datatable-buttons_filter" className="dataTables_filter">
                              <BrowserView>
                                <CsvClickStatisticsExportButton
                                    getParametersFunction={this.getCurrentFilterState.bind(this)}
                                    csvControllerUrl={process.env.REACT_APP_API_URL + '/clicksStatisticsCsvExport'}
                                    changeState={this.changeState.bind(this)}
                                    warningMessage={
                                      'Exporting the statistics as CSV could take some time. Do you want to continue?'
                                    }
                                    dateFrom={this.state.filters.dateFrom}
                                    dateTo={this.state.filters.dateTo}
                                />
                              </BrowserView>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="table-responsive">
                      <DataTable
                          columns={dataTableColumns}
                          data={this.state.clicks}
                          paginationDefaultPage={Math.max(this.state.filters.page, 1)}
                          pagination={true}
                          paginationServer={true}
                          paginationTotalRows={this.state.maxCount}
                          paginationPerPage={this.state.filters.resultsPerPage}
                          paginationRowsPerPageOptions={[500, 1000, 2000]}
                          onChangePage={this.onChangePage.bind(this)}
                          onChangeRowsPerPage={this.onChangeRowsPerPage.bind(this)}
                          progressPending={this.state.dataTableProgressPending}
                          striped={true}
                          highlightOnHover={true}
                          noHeader={true}
                          defaultSortField={'clickDate'}
                          defaultSortAsc={false}
                      />

                    </div>
                  </div>
                </div>
                <Error
                    isErrorCritical={this.state.isErrorCritical}
                    errorMessage={this.state.errorMessage}
                />
              </div>
            </div>
            <PublisherStatisticInfo/>
          </PageRow>
        </>
    );
  }
}

export default ClicksStatistics;

// @flow
/* eslint-disable flowtype/require-return-type */
import React from 'react';

type SuccessProperties = {
  success: boolean,
  successMessage: string,
}

class Success extends React.Component<SuccessProperties> {
  render() {
    if (this.props.success === false) {
      return '';
    }

    return (
      <React.Fragment>
        <br/>
        <div className="alert alert-success" role="alert">
          {this.props.successMessage}
        </div>
      </React.Fragment>
    );
  }
}

export default Success;

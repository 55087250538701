import React from 'react';
import type {Ymd} from '../../../Helper/DatePickerHelper';
import Ajax from "../../../Helper/Ajax";
import BlobDownloadHelper from "../../../Helper/BlobDownloadHelper";

type CsvStreamExportButtonProps = {
    getParametersFunction: Function,
    changeState: Function,
    csvControllerUrl: string,
    warningMessage: string,
    dateFrom: Ymd,
    dateTo: Ymd,
}


type CsvClickStatisticsExportButtonState = {
    isBusy: boolean,
}

class CsvClickStatisticsExportButton extends React.Component<CsvStreamExportButtonProps> {
    state: CsvClickStatisticsExportButtonState = {
        isBusy: false,
    };

    download() {
        if (this.props.warningMessage !== '') {
            // eslint-disable-next-line no-restricted-globals
            if (confirm(this.props.warningMessage)) {
                const dateFrom = new Date(this.props.dateFrom.year, this.props.dateFrom.month - 1, this.props.dateFrom.day);
                const dateTo   = new Date(this.props.dateTo.year, this.props.dateTo.month - 1, this.props.dateTo.day);

                // Sekunden
                const diff = ((dateTo.getTime() + 86400) - dateFrom.getTime()) / 1000;

                // 2678400 Sekunden = 744 Stunden = 31 Tage
                if (diff > 2678400) {
                    alert(
                        'The date range for the export of the click statistics is limited to ' +
                        '31 days. Please pick a smaller date range.'
                    );
                    return;
                }
                this.setState({ isBusy: true });

                const parametersArray     = this.props.getParametersFunction();
                const parametersFormatted = [];

                for (const key in parametersArray) {
                    parametersFormatted.push(key + '=' + parametersArray[key]);
                }

                const params = '?' + parametersFormatted.join('&');

                const url = `${this.props.csvControllerUrl}${params}`;

                const promise: Promise<string> = Ajax.createGetXhr(url);
                promise.then((response) => {
                    const date =  BlobDownloadHelper.getDatePrefix()
                    const filename = `${date}-click_statistics.csv`;
                    BlobDownloadHelper.downloadBlob(response, filename);
                    this.setState({ isBusy: false });
                });
            }
        }
    }
    renderSpinner() {
        if (this.state.isBusy) {
            return <span className="spinner-border text-primary float-right csv-export-button-spinner" />;
        }

        return '';
    }

    render() {
        return (
            <>
                <button
                    className="btn btn-secondary buttons-csv buttons-html5"
                    tabIndex="0"
                    aria-controls="datatable-buttons"
                    type="button"
                    name="csvexport"
                    onClick={this.download.bind(this)}
                    value="export"
                >
                    <span className="float-left">Export as CSV</span>
                    { this.renderSpinner() }
                </button>
            </>
        );
    }
}

export default CsvClickStatisticsExportButton;

import React from 'react';
import Flatpickr from 'react-flatpickr';
import DatePickerHelper, { Ymd } from '../../../../Helper/DatePickerHelper';

type Filters = {
    dateFrom: Ymd,
    dateTo: Ymd,
    page: Number,
    resultsPerPage: Number,
}

type ClicksStatisticsFiltersProps = {
    onSubmit: Function<Filters>,
    // updateFiltersState: Function<Filters>,
    filters: Filters,
}

type ClicksStatisticsFiltersState = {
    filters: Filters,
}

class ClicksStatisticsFilters extends React.Component<ClicksStatisticsFiltersProps, ClicksStatisticsFiltersState> {
    state: ClicksStatisticsFiltersState = {
        filters: {
            dateFrom: this.props.filters.dateFrom,
            dateTo: this.props.filters.dateTo,
            page: this.props.filters.page,
            resultsPerPage: this.props.filters.resultsPerPage,
        },
    };

    buttonOnClick(): void {
        this.updateFiltersState('page', 1);
        this.updateFiltersState('resultsPerPage', 500);

        this.props.onSubmit(this.state.filters);
    }

    updateFiltersState(name: string, value: Ymd): void {
        const oldState = this.state;

        oldState.filters[name] = value;

        this.setState(oldState);
    }

    render() {
        const startDate = this.state.filters.dateFrom;
        const endDate   = this.state.filters.dateTo;
        const monthFrom = startDate.month - 1;
        const monthTo   = endDate.month - 1;

        const today       = DatePickerHelper.getYesterday();
        const minDateFrom = new Date(2017, 8, 1, 0, 0, 0, 0);
        const maxDateFrom = new Date(endDate.year, (endDate.month - 1), endDate.day, 0, 0, 0, 0);
        const dateFrom    = new Date(startDate.year, monthFrom, startDate.day, 0, 0, 0, 0);

        const minDateTo = new Date(startDate.year, monthFrom, startDate.day, 0, 0, 0, 0);
        const maxDateTo = new Date(today.year, (today.month - 1), today.day, 0, 0, 0, 0);
        const dateTo    = new Date(endDate.year, monthTo, endDate.day, 0, 0, 0, 0);

        return (
            <>
                <div className="col-sm-2 col-md-2">
                    <div id="datatable-buttons_filter" className="dataTables_filter">
                        <label>Date from:</label>
                        <Flatpickr
                            id={'dateFrom'}
                            className={'form-control'}
                            dateformat={'YYYY-MM-dd'}
                            value={ dateFrom }
                            onChange={DatePickerHelper.handleChangeStartDate.bind(
                                this,
                                this.state.filters.dateTo,
                                this.updateFiltersState.bind(this),
                                'dateFrom'
                            )}
                            options={
                                {
                                    minDate: minDateFrom,
                                    maxDate: maxDateFrom,
                                    locale: {
                                        'firstDayOfWeek': 1,
                                    },
                                    monthSelectorType: 'static',
                                    defaultHour: 0,
                                    disableMobile:true,
                                }
                            }
                        />
                    </div>
                </div>
                <div className="col-sm-2 col-md-2 ">
                    <div id="datatable-buttons_filter" className="dataTables_filter">
                        <label>Date to:</label>
                        <Flatpickr
                            id={'dateTo'}
                            className={'form-control'}
                            dateformat={'YYYY-MM-dd'}
                            value={ dateTo }
                            onChange={DatePickerHelper.handleChangeEndDate.bind(
                                this,
                                this.state.filters.dateFrom,
                                this.updateFiltersState.bind(this),
                                'dateTo'
                            )}
                            options={
                                {
                                    minDate: minDateTo,
                                    maxDate: maxDateTo,
                                    locale: {
                                        'firstDayOfWeek': 1,
                                    },
                                    monthSelectorType: 'static',
                                    defaultHour: 0,
                                    disableMobile:true,
                                }
                            }
                        />
                    </div>
                </div>
                <div className="col-sm-2 col-md-6 filter_button">
                    <div id="datatable-buttons_filter" className="dataTables_filter">
                        <button
                            onClick={this.buttonOnClick.bind(this)}
                            className={'btn btn-primary'}
                        >Filter</button>
                    </div>
                </div>
            </>
        );
    }
}

export default ClicksStatisticsFilters;
export type { Filters };

// @flow

import React from 'react';
import Error from '../../App/components/Messages/Error';
import Success from '../../App/components/Messages/Success';
import Ajax from '../../../Helper/Ajax';
import EmailValidator from '../../App/Validator/EmailValidator';
import {Button, Card, CardBody, Container, FormGroup, Label, Row} from 'reactstrap';
import {AvFeedback, AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import PageTitle from "../../Layout/PageTitle";

type AccountFormProps = {
  apiEndpoint: string,
}

type AccountFormState = {
  publisherId: string,
  emailAddress: string,
  isErrorCritical: boolean,
  errorMessage: string,
  success: boolean,
  successMessage: string,
  loading: boolean,
}

class AccountForm extends React.Component<AccountFormProps, AccountFormState> {
  state: AccountFormState = {
    publisherId: '',
    emailAddress: '',
    isErrorCritical: true,
    errorMessage: '',
    success: false,
    successMessage: '',
    loading: false,
  };

  submit(e: Event) {
    e.preventDefault();

    this.setState({
        loading: true
    });

    if (EmailValidator.validate(this.state.emailAddress) === false) {
      this.setErrors('E-mail: ' + EmailValidator.getErrorMessages().join(','));
      return;
    }

    const formData = {
      publisherId: this.state.publisherId,
      emailAddress: this.state.emailAddress,
    };

    const promise: Promise<string> = Ajax.createPostXhr(process.env.REACT_APP_API_URL + '/account/create', formData);

    promise.then(response => {
      const parsedResponse = JSON.parse(response);

      if (parsedResponse.errorMessage || parsedResponse.warningMessage) {
        this.setState({
          isErrorCritical: !parsedResponse.success,
          errorMessage: parsedResponse.errorMessage || parsedResponse.warningMessage,
          loading: false,
        });
        return;
      }

      this.setSuccess('The account was saved.');
      this.setState({
        loading: false,
        errorMessage: '',
      })
      // window.location.href = '/admin/dashboard';
      // wieder auf die liste gehen ?
    }).catch(error => {
      console.error(error);
      this.setErrors('An unexpected error occured. Please try again later.');
    });
  }

  setErrors(message: string) {
    this.setState({
      ...this.state,
      errorMessage: message,
      success: false,
      successMessage: '',
    });
  }

  setSuccess(message: string) {
    this.setState({
      ...this.state,
      errorMessage: '',
      success: true,
      successMessage: message,
    });
  }

  componentDidMount() {
    const url = new URL(window.location);
    const publisherId = url.searchParams.get('pid');

    if (publisherId === null || publisherId === '') {
      alert('There must be a publisher ID to create an account.');
      window.location.href = '/admin/accounts';
      // this.setErrors(400, 'There must be a publisher ID to create an account.');
    }

    this.setState({
      ...this.state,
      publisherId: publisherId || '',
    });
  }

  renderButton() {
    if (this.state.success) {
      return <Button color="primary" className="btn-block" disabled={true}>Create account</Button>
    }

    if (this.state.loading) {
      return <div className="spinner-center">
        <Button color="primary" className="btn-block" disabled={true}>Create account</Button>
        <div className="spinner-border text-primary m-2" role="status"/>
      </div>
    }

    return <Button color="primary" className="btn-block">Create account</Button>
  }

  render() {
    return (
        <>
          <PageTitle
              title="Create account"
              breadcrumbs={[
                {
                  link: '/admin/dashboard',
                  name: 'Dashboard',
                },
                {
                  link: '/admin/accounts',
                  name: 'Account list',
                },
                {
                  name: 'Create account',
                },
              ]}
          />
          <div className="account-pages mt-5 mb-5">
            <Container>
              <Row className="justify-content-center">
                <div className="col-md-8 col-lg-6 col-xl-5">
                  <Card className="bg-pattern">
                    <CardBody className="p-4 position-relative">
                      <div className="text-center w-200 m-auto">
                        <h4 className="header-title">
                          Create account for publisher ID <i>{ this.state.publisherId }</i>
                        </h4>
                      </div>
                      <br />
                      <AvForm onValidSubmit={this.submit.bind(this)}>
                        <AvGroup className="mb-3">
                          <Label for="emailAddress">Account e-mail</Label>
                          <AvInput
                              type="email"
                              name="emailAddress"
                              placeholder="Account e-mail address"
                              value={this.state.emailAddress}
                              required
                              onChange={ e => this.setState({ emailAddress: e.target.value }) }
                          />
                          <AvFeedback>This field is invalid</AvFeedback>
                        </AvGroup>
                        <FormGroup>
                          {this.renderButton()}
                        </FormGroup>
                        <Success success={this.state.success} successMessage={this.state.successMessage} />
                        <Error
                            isErrorCritical={this.state.isErrorCritical}
                            errorMessage={this.state.errorMessage}
                        />
                      </AvForm>
                    </CardBody>
                  </Card>
                  <div className="row mt-3">
                    <div className="col-12 text-center">
                      <p className="text-black-50">
                        Back to <a href="/admin/accounts" className="text-black ml-1"><b>Back to account list</b></a>
                      </p>
                    </div>
                  </div>

                </div>
              </Row>
            </Container>
          </div>
        </>
    );
  }
}

export default AccountForm;

// @flow

class DateValue {
  _date: Date;
  _year: number;
  _month: number;
  _day: number;

  constructor(now: string = '') {
    this._date  = new Date();

    if (now === 'now') {
      this._date = new Date(Date.now());
    }

    this._year  = this._date.getFullYear();
    this._month = this._date.getMonth() + 1;
    this._day   = this._date.getDate();
  }

  static now(part: string = ''): string {
    const date: DateValue   = new this('now');
    let returnValue: string = `${date.getYear()}-${date.getMonth()}-${date.getDay()}`;

    if (part === 'year') {
      returnValue = date.getYear();
    }

    if (part === 'month') {
      returnValue = date.getMonth();
    }

    if (part === 'day') {
      returnValue = date.getDay();
    }

    return returnValue;
  }

  getDate(): Date {
    return this._date;
  }

  getYear(): string {
    return this._year.toString();
  }

  getMonth(): string {
    return this._month.toString();
  }

  getDay(): string {
    return this._day.toString();
  }
}

export default DateValue;

import React from 'react';
import PageTitle from '../../../Layout/PageTitle';
import Search from '../Search';
import Ajax from '../../../../Helper/Ajax';

type Merchant = {
    id: string,
    domain: string,
    market: string,
    importDate: string,
    logoExists: boolean,
    logoUrl: string,
}

type MerchantListState = {
    availableMarkets: {},
    selectedMarket: string,
    merchantsForMarket: Merchant[],
    searchTerm: string,
    chunkSize: number,
    merchantsLoadedCount: number,
    allMerchantsLoaded: boolean,
}

class MerchantList extends React.Component<{}, MerchantListState> {
    state: MerchantListState = {
        availableMarkets: {},
        selectedMarket: 'de',
        merchantsForMarket: [],
        searchTerm: '',
        chunkSize: 36,
        merchantsLoadedCount: 0,
        allMerchantsLoaded: false,
    };

    loadMerchantsFromApi(market: string, searchTerm: string = '') {
        const promise = Ajax.createGetXhr(
          process.env.REACT_APP_API_URL + '/merchant-list' +
          '?market=' + market +
          '&searchTerm=' + searchTerm +
          '&chunkSize=' + this.state.chunkSize +
          '&merchantsLoadedCount=' + this.state.merchantsLoadedCount
        );

        promise.then((response) => {
            const parsedResponse = JSON.parse(response);

            const merchantsLoadedCount = parsedResponse.data.merchants.length;
            const maxCount             = parsedResponse.data.maxCount;
            const allMerchantsLoaded   = maxCount === merchantsLoadedCount;

            this.setState({
                availableMarkets: parsedResponse.data.availableMarkets,
                merchantsForMarket: parsedResponse.data.merchants,
                merchantsLoadedCount,
                allMerchantsLoaded,
            });
        }).catch((error) => {
            console.error(error);
        });
    };

    marketOnClickHandler(market: string, event) {
        event.preventDefault();
        this.setState({
            selectedMarket: market,
            merchantsLoadedCount: 0,
        }, () => {
            this.loadMerchantsFromApi(market, this.state.searchTerm)
        });
    };

    updateSearchValue(selectedMarket: string, searchTerm: string): void {
        let merchantsLoadedCount = this.state.merchantsLoadedCount - this.state.chunkSize;

        if (merchantsLoadedCount < this.state.merchantsLoadedCount) {
            merchantsLoadedCount = 0;
        }

        this.setState({
            searchTerm: searchTerm,
            merchantsLoadedCount,
        }, () => {
            this.loadMerchantsFromApi(selectedMarket, searchTerm);
        });
    }

    componentDidMount(): void {
        this.loadMerchantsFromApi('de');
    };

    renderNavTabs(key: string) {
        let active = '';

        if (this.state.selectedMarket === key) {
            active = 'active';
        }

        return (
            <li className="nav-item" key={key}>
                <a onClick={this.marketOnClickHandler.bind(this, key)}
                   href={'#' + key}
                   data-toggle="tab"
                   aria-expanded="false"
                   className={'nav-link ' + active}
                >
                    {this.state.availableMarkets[key]}
                </a>
            </li>
        );
    }

    static renderTabContents(merchant: Merchant, i: number) {
        return (
            <div className="col-md-2 col-lg-2" key={i}>
                <div className="card-box">
                    <div className="tab-pane text-center">
                        <a href={'http://' + merchant.domain} target="_blank" rel="noopener noreferrer">
                            <img src={merchant.logoUrl} alt={merchant.domain} width="120px"/>
                            <h5 className="word-break">{merchant.domain}</h5>
                        </a>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <>
                <PageTitle
                    title="Merchant list"
                    breadcrumbs={[
                        {
                            link: '/dashboard',
                            name: 'Dashboard',
                        },
                        {
                            name: 'Merchant list',
                        },
                    ]}
                />

                <div className="card">
                    <div className="card-body">
                        <div id="datatable-buttons_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                            <div className="row">
                                <div className="col-sm-2 col-md-2">
                                    <div id="datatable-buttons_filter" className="dataTables_filter">
                                        <label>Search:</label>
                                        <Search
                                            updateSearchValue={this.updateSearchValue.bind(this, this.state.selectedMarket)}
                                            minLength={0}
                                            initialSearchTerm={''}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-box">
                    <ul className="nav nav-tabs">
                        {Object.keys(this.state.availableMarkets).map(this.renderNavTabs.bind(this))}
                    </ul>
                    <div className="tab-content">
                        <div className="row">
                            {Object.values(this.state.merchantsForMarket).map(MerchantList.renderTabContents.bind(this))}
                        </div>
                    </div>
                    <div className="row">
                        {
                            !this.state.allMerchantsLoaded && <a href="/" className="margin-center" onClick={ event => {
                                event.preventDefault();

                                this.loadMerchantsFromApi(
                                  this.state.selectedMarket,
                                  this.state.searchTerm
                                );
                            } }>load more...</a>
                        }
                    </div>
                </div>
            </>
        );
    };
}

export default MerchantList;

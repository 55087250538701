import React from 'react';
import Chart from 'chart.js';
import Ajax from '../../../Helper/Ajax';
import Error from '../../App/components/Messages/Error';
import { isMobile} from "react-device-detect";

type StatisticsState = {
    isErrorCritical: boolean,
    errorMessage: string,
}

type XDaysStatistic = {
    date: string,
    clicks: number,
    revenue: number,
    currency: string,
}

type xDaysStatisticsSum = {
    maxClicks: number,
    maxRevenue: number,
    currency: string,
    xDaysStatistics: XDaysStatistic[]
}

class Statistics extends React.Component<{}, StatisticsState> {
    state = {
        isErrorCritical: true,
        errorMessage: '',
    };

    dates = [];
    clicks = [];
    revenues = [];
    maxClicks;
    maxRevenue;
    currency;

    defineChart() {
        if (this.maxClicks < 1) {
            return (
                <div className="sad-tear">
                    <div className="text-align-center">
                        <i className="far fa-sad-tear" />
                        <p>No data to display</p>
                    </div>
                </div>
            )
        }

        if (isMobile) {
            return (
                <div className="mt-12 chartjs-chart">

                    <div
                        className="chartjs-size-monitor"
                        style={{
                            position: 'absolute',
                            left: 0 + 'px',
                            top: 0 + 'px',
                            right: 0 + 'px',
                            bottom: 0 + 'px',
                            overflow: 'hidden',
                            pointerEvents: 'none',
                            visibility: 'hidden',
                            zIndex: -1,
                        }}
                    >
                        <div
                            className="chartjs-size-monitor-expand"
                            style={{
                                position: 'absolute',
                                left: 0,
                                top: 0,
                                right: 0,
                                bottom: 0,
                                overflow: 'hidden',
                                pointerEvents: 'none',
                                visibility: 'hidden',
                                zIndex: -1,
                            }}
                        >
                            <div style={{
                                position: 'absolute',
                                width: 1000000 + 'px',
                                height: 1000000 + 'px',
                                left: 0,
                                top: 0,
                            }} />
                        </div>
                        <div
                            className="chartjs-size-monitor-shrink"
                            style={{
                                position: 'absolute',
                                left: 0 + 'px',
                                top: 0 + 'px',
                                right: 0 + 'px',
                                bottom: 0 + 'px',
                                overflow: 'hidden',
                                pointerEvents: 'none',
                                visibility: 'hidden',
                                zIndex: -1,
                            }}
                        >
                            <div style={{
                                position: 'absolute',
                                width: 200 + '%',
                                height: 200 + '%',
                                left: 0,
                                top: 0,
                            }} />
                        </div>
                    </div>

                    <canvas
                        id="chart"
                        className="chartjs-render-monitor"
                        style={{
                            display: 'block',
                            width: 100 + '%',
                            height: 300 + 'px',
                        }}
                    />
                </div>
            )
        }

        return (
            <div className="mt-3 chartjs-chart">

                <div
                    className="chartjs-size-monitor"
                    style={{
                        position: 'absolute',
                        left: 0 + 'px',
                        top: 0 + 'px',
                        right: 0 + 'px',
                        bottom: 0 + 'px',
                        overflow: 'hidden',
                        pointerEvents: 'none',
                        visibility: 'hidden',
                        zIndex: -1,
                    }}
                >
                    <div
                        className="chartjs-size-monitor-expand"
                        style={{
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            right: 0,
                            bottom: 0,
                            overflow: 'hidden',
                            pointerEvents: 'none',
                            visibility: 'hidden',
                            zIndex: -1,
                        }}
                    >
                        <div style={{
                            position: 'absolute',
                            width: 1000000 + 'px',
                            height: 1000000 + 'px',
                            left: 0,
                            top: 0,
                        }} />
                    </div>
                    <div
                        className="chartjs-size-monitor-shrink"
                        style={{
                            position: 'absolute',
                            left: 0 + 'px',
                            top: 0 + 'px',
                            right: 0 + 'px',
                            bottom: 0 + 'px',
                            overflow: 'hidden',
                            pointerEvents: 'none',
                            visibility: 'hidden',
                            zIndex: -1,
                        }}
                    >
                        <div style={{
                            position: 'absolute',
                            width: 200 + '%',
                            height: 200 + '%',
                            left: 0,
                            top: 0,
                        }} />
                    </div>
                </div>

                <canvas
                    id="chart"
                    className="chartjs-render-monitor"
                    style={{
                        display: 'block',
                        width: 800 + 'px',
                        height: 400 + 'px',
                    }}
                />
            </div>
        )
    }

    render() {
        return (
            <>
                <div className="card">
                    <div className="card-body">

                        <h4 className="header-title">7 days statistics</h4>

                        {this.defineChart()}

                        <Error
                            isErrorCritical={this.state.isErrorCritical}
                            errorMessage={this.state.errorMessage}
                        />

                    </div>
                </div>
            </>
        );
    }

    getReportData() {
        const promise: Promise<string> = Ajax.createGetXhr(process.env.REACT_APP_API_URL + '/x-days-statistics');

        promise.then(response => {
            const parsedResponse = JSON.parse(response);

            if (parsedResponse.errorMessage || parsedResponse.warningMessage) {
                this.setState({
                    isErrorCritical: !parsedResponse.success,
                    errorMessage: parsedResponse.errorMessage || parsedResponse.warningMessage,
                });
                return;
            }

            const data: xDaysStatisticsSum = parsedResponse.data;

            this.maxClicks  = data.maxClicks;
            this.maxRevenue = Math.round(data.maxRevenue);
            this.currency   = data.currency;

            for (let i = Object.keys(data.xDaysStatistics).length; i > 0; i--) {
                this.dates.push(data.xDaysStatistics[i].date);
                this.clicks.push(data.xDaysStatistics[i].clicks);
                this.revenues.push(data.xDaysStatistics[i].revenue);
            }

            this.setState({
              errorMessage: '',
            });

            if (this.maxClicks > 0) {
                this.renderChart();
            }
        }).catch(error => {
            console.error(error);
            this.setState({
                errorMessage: 'An unexpected error occured. Please try again later.',
            });
        });
    }

    renderChart() {
        let fontSize = 12;

        if (isMobile) {
            fontSize = 9;
        }

        if (this.maxClicks >= 0) {
            const ctx = document.getElementById('chart').getContext('2d');
            const maxValueForClicks = this.roundMaxValueForClicks(this.maxClicks);

            console.log(this.clicks);

            return new Chart(ctx, {
                type: 'line',
                data: {
                    labels: this.dates,
                    datasets: [
                        {
                            label: 'Clicks',
                            yAxisID: 'clicks',
                            data: this.clicks,
                            backgroundColor: [
                                'rgba(255,255,255,0)'
                            ],
                            // backgroundColor: [
                            //   'rgba(51,153,255,0.2)'
                            // ],
                            // backgroundColor: [
                            //   'rgba(255,255,255,0)'
                            // ],
                            borderColor: [
                                'rgba(51,102,255,1)'
                            ],
                            borderWidth: 2
                        },
                        {
                            label: 'Revenue (' + this.currency + ')',
                            yAxisID: 'revenue',
                            data: this.revenues,
                            backgroundColor: [
                                'rgba(255,255,255,0)'
                            ],
                            // backgroundColor: [
                            //   'rgba(51,255,0,0.2)'
                            // ],
                            // backgroundColor: [
                            //   'rgba(255,255,255,0)'
                            // ],
                            borderColor: [
                                'rgba(51,204,0,1)'
                            ],
                            borderWidth: 2
                        },
                    ]
                },
                options: {
                    legend: {
                        display: true,
                        labels: {
                            fontSize: fontSize,
                        }
                    },
                    scales: {
                        yAxes: [
                            {
                                id: 'clicks',
                                ticks: {
                                    fontSize: fontSize,
                                    beginAtZero: true,
                                    stepSize: (maxValueForClicks < 10) ? 1 : null,
                                    min: 0,
                                    max: maxValueForClicks,
                                    callback: (value, index, values) => {
                                        return value + ' Clicks';
                                    }
                                },
                                position: 'left',
                                gridLines: {
                                    // color: 'rgba(51,153,255,0.2)'
                                    color: 'rgba(51,153,255,0)'
                                },
                            },
                            {
                                id: 'revenue',
                                ticks: {
                                    fontSize: fontSize,
                                    beginAtZero: true,
                                    min: 0.00,
                                    max: this.roundMaxValueForRevenue(this.maxRevenue),
                                    callback: (value, index, values) => {
                                        return value + ' ' + this.currency;
                                    }
                                },
                                position: 'right',
                                gridLines: {
                                    // color: 'rgba(51,255,0,0.3)'
                                    color: 'rgba(51,255,0,0)'
                                }
                            }
                        ]
                    }
                }
            });
        }
    }

    roundMaxValueForClicks(value: number) {
        const log  = Math.floor(Math.round(100 * Math.log(value) / Math.log(10)) / 100);

        const inc  = Math.pow(10, log);
        const ceil = Math.ceil(value / inc) * inc;

        return ceil + inc;
    }

    roundMaxValueForRevenue(value: number) {
        if (value < 1) {
            return 1;
        }

        return Math.ceil(value * 1.2);
    }


    componentDidMount() {
        this.getReportData();
    }
}

export default Statistics;

// @flow
import React from 'react';

class LogoutPage extends React.Component<{}> {
    componentDidMount() {
        localStorage.clear();
    }

    render() {
        return (
            <div className="container">
                <div className="login-bg">
                    <img src="/images/login.jpg" className="fixed-image" alt="Laptop" type="Login"/>
                    <div className="center">
                        <div className="text-center w-200 m-auto">
                                <span><img className="loginImg" src="/images/yadore.svg" alt="yadore.svg" /></span>
                        </div>
                        <div className="text-center">
                            <div className="mt-4">
                                <div className="logout-checkmark">
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 130.2 130.2">
                                        <circle className="path circle" fill="none" stroke="#FFEC40"
                                                strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1"
                                                r="62.1" />
                                        <polyline className="path check" fill="none" stroke="#FFEC40"
                                                  strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10"
                                                  points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                                    </svg>
                                </div>
                            </div>
                            <h1 className="headline-one bottom">See you again!</h1>
                            <p className="text-muted font-13">You are now successfully logged out.</p>
                        </div>
                    </div>
                    <p>
                        <a className="forgot text-link" href="/">Back to Login</a>
                    </p>
                </div>
            </div>
        );
    }
}

export default LogoutPage;

import React from 'react';
import Ajax from '../../../../Helper/Ajax';
import DownloadCreditNotePdfButton from "../../../Admin/components/CreditNotes/DownloadCreditNotePdfButton";

type Props = {
    creditNoteId: string,
    changeState: Function,
    openPdf: boolean,
}
type State = {
    isBusy: boolean,
}

class DownloadPublisherCreditNotePdfButton extends React.Component<Props, State> {
    state: State = {
        isBusy: false,
    };

    downloadCreditNotePdf(): void {
        const promise: Promise<string> = Ajax.createGetXhr(
            process.env.REACT_APP_API_URL + '/get-credit-notes-list-pdf?creditNoteId=' +
            this.props.creditNoteId
        );

        this.setState({isBusy: true});

        promise.then(response => {
            const parsedResponse = JSON.parse(response);

            if (parsedResponse.errorMessage || parsedResponse.warningMessage) {
                this.props.changeState({
                    isErrorCritical: !parsedResponse.success,
                    errorMessage: parsedResponse.errorMessage || parsedResponse.warningMessage,
                });
                return;
            }

            this.createFileDownload(
              DownloadCreditNotePdfButton.base64ToArrayBuffer(parsedResponse.data.creditNotePdf),
                parsedResponse.data.creditNotePdfFileName
            );

            this.props.changeState({errorCode: 0});
            this.setState({isBusy: false});
        }).catch(error => {
            console.error(error);
            this.props.changeState({
                errorCode: 500,
                errorMessage: 'An unexpected error occured. Please try again later.',
            });
        });
    }

    static base64ToArrayBuffer(data) {
        const binaryString = window.atob(data);
        const binaryLen    = binaryString.length;
        const bytes        = new Uint8Array(binaryLen);

        for (let i = 0; i < binaryLen; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        return bytes;
    }

    createFileDownload(creditNotePdf: string, creditNotePdfFileName: string): void {
        const blob = new Blob([creditNotePdf], {type: 'application/pdf'});

        let a     = document.createElement('a');
        const url = window.URL.createObjectURL(blob);

        // EDGE-Weiche
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, creditNotePdfFileName);
            return;
        }

        if (this.props.openPdf) {
            window.open(url);
            return;
        }

        a.href      = url;
        a.download  = creditNotePdfFileName;
        a.innerHTML = 'download ' + creditNotePdfFileName;

        document.body.append(a);

        a.remove();
        a.click();

        window.URL.revokeObjectURL(url);
    }

    render() {
        let iconClass   = 'mdi mdi-large mdi-file-download-outline';
        let buttonClass = 'btn btn-xs btn-outline-secondary margin-right';
        let buttonTitle = 'Download credit note as PDF';

        if (this.props.openPdf) {
            iconClass   = 'mdi mdi-large mdi-eye-outline';
            buttonClass = 'btn btn-xs btn-outline-danger margin-right';
            buttonTitle = 'View credit note as PDF';
        }

        return (
            <button
                onClick={this.downloadCreditNotePdf.bind(this)}
                type="button"
                className={buttonClass}
                title={buttonTitle}
                disabled={this.state.isBusy}
            >
                <i className={iconClass} />
                {
                    this.state.isBusy && <span className="spinner-border text-primary float-right download-pdf-button-spinner" />
                }
            </button>
        );
    }
}

export default DownloadPublisherCreditNotePdfButton;

import React from 'react';

type PageRowProps = {
}

class PageRow extends React.Component<PageRowProps> {
  render() {
    return (
        <div className="row">
          <div className="col-12">
            {this.props.children}
          </div>
        </div>
    );
  }
}

export default PageRow;

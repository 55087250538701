// @flow

import type { ValidatorInterface } from './ValidatorInterface';
import type { ValidatorOptions } from './IsFloat';

class StringLength implements ValidatorInterface {
  stringLength: number = 0;

  mode: string = 'greater';

  constructor(mode: 'less' | 'equal' | 'greater', stringLength: number = 0): void {
    this.stringLength = stringLength;
    this.mode         = mode;
  }

  setOptions(options: ValidatorOptions) {
    return this;
  }

  isValid(value: string | number): boolean {
    if (typeof value !== 'string') {
      return false;
    }

    switch (this.mode) {
      case 'less':
        return value.length < this.stringLength;
      case 'equal':
        return value.length === this.stringLength;
      case 'greater':
        return value.length > this.stringLength;
      default:
        return false;
    }
  }

  setLength(newLength: number): StringLength {
    this.stringLength = newLength;

    return this;
  }

  setMode(newMode: 'less' | 'equal' | 'greater'): StringLength {
    this.mode = newMode;

    return this;
  }
}

export default StringLength;

import React from 'react';
import PageTitle from '../../../Layout/PageTitle';
import SetPassword from './SetPassword';
import SetEmail from './SetEmail';
import Ajax from '../../../../Helper/Ajax';

type AccountSettingsState = {
  success: boolean,
  successMessage: string,
  isErrorCritical: boolean,
  errorMessage: string,
  publisherName: string,
}

class AccountSettings extends React.Component<{}, AccountSettingsState> {
  state: AccountSettingsState = {
    success: false,
    successMessage: '',
    isErrorCritical: false,
    errorMessage: '',
    publisherName: '',
  };

  queryParams = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    }
  )

  publisherId = this.queryParams['pid'];
  componentDidMount(): void {
    const promise = Ajax.createGetXhr(
      process.env.REACT_APP_API_URL + '/admin/account-settings/get-publisher-name?publisherId=' + this.publisherId
    );

    promise.then(response => {
      const parsedResponse = JSON.parse(response);

      if (parsedResponse.errorMessage || parsedResponse.warningMessage) {
        this.setState({
          success: false,
          isErrorCritical: !parsedResponse.success,
          errorMessage: parsedResponse.errorMessage || parsedResponse.warningMessage,
        });
        return;
      }

      this.setState({
        publisherName: parsedResponse.data.publisherName,
      });
    }).catch(error => {
      console.error(error);
      this.setState({
        ...this.state,
        success: false,
        isErrorCritical: true,
        errorMessage: 'An unexpected error occured. Please try again later.',
      });
    });
  }

  render() {
        return <>
            <PageTitle
                title={ 'Account settings for ' + this.state.publisherName}
                breadcrumbs={[
                    {
                        link: '/admin/dashboard',
                        name: 'Dashboard',
                    },
                    {
                        link: '/admin/accounts',
                        name: 'Account list',
                    },
                    {
                        name: 'Account settings'
                    },
                ]}
            />

            <SetEmail publisherId={ this.publisherId } />

            <SetPassword publisherId={ this.publisherId } />
        </>;
    }
}

export default AccountSettings;

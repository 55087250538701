import React from 'react';
import PageRow from "../Layout/PageRow";
import PageTitle from "../Layout/PageTitle";

class DataProtection extends React.Component {
    render() {
        return (
            <>
                <PageTitle
                    title="Data protection"
                    breadcrumbs={[]}
                />
                <PageRow>
                    <div className="card">
                        <div className="card-body">
                            <section className="privacy">
                                <h3>Privacy Policy at a glance</h3>
                                <p>We take the security of your personal data very seriously. The Yadore GmbH. We treat your
                                    personal data
                                    as strictly confidential and accordingly to the legal privacy policy law.</p>

                                <p>Many data processing operations are only possible with your express consent. You may revoke your
                                    consent
                                    at any time with future effect. An informal email to the Yadore GmbH making this request is
                                    sufficient.
                                    The data processed before we receive your request may still be legally processed.</p>

                                <p>The user has the right to have data which we process based on his consent or in fulfillment of a
                                    contract
                                    automatically delivered to himself or to a third party in a standard, machine-readable format.
                                    If the
                                    user requires the direct transfer of data to another responsible party, this will only be done
                                    to the
                                    extent technically feasible.</p>

                                <p>With the following overview we explain, how the Yadore GmbH handles your personal information
                                    when you
                                    visit our website yadore.com or advertisement we may place on other websites (own sites and
                                    third party
                                    sites). Personal information is any data with which you – also called “user” from now on – could
                                    be
                                    personally identified. More detailed information on the subject of data protection can be found
                                    in our
                                    privacy policy below.</p>

                                <p>The use of the data in the imprint for the purpose of sending non-requested advertisement or any
                                    kind of
                                    non-requested information is explicitly vetoed. We will take legal steps if we get non-requested
                                    information, advertisement, or any other kind of spam, via email or postal.</p>

                                <div className="flexbox">
                                    <div className="flex-item">
                                        <p><b className="violet">Responsible:</b><br/>
                                            Company: Yadore GmbH<br/>
                                            Street and number: Bavariafilmplatz 7<br/>
                                            Zip code, City, Country: 82031, Grünwald, Germany<br/>
                                            Phone: +49 89 96058289</p>
                                        <p>Commercial register number: HRB 235343<br/>
                                            Trade Register: Amtsgericht Munich<br/>
                                            CEO: Fabian Gärtner<br/>
                                            E-Mail address: mail@yadore.com</p>
                                    </div>
                                    <div className="flex-item">
                                        <p><b className="violet">Data security officer:</b><br/>
                                            Name: Fabian Gärtner<br/>
                                            Street and number: Bavariafilmplatz 7<br/>
                                            Zip code, City, Country: 82031, Grünwald, Germany<br/>
                                            E-Mail address: mail@yadore.com</p>
                                        <p>Last updated: 05.06.2018</p>
                                    </div>
                                </div>


                                <h4>Legal basis for our services and basic information on the use and disclosure of data.</h4>

                                <p>The information in this Privacy Policy is intended to inform you of the purpose, scope and manner
                                    in
                                    which your personal information is processed within our entire online offering and all related
                                    websites,
                                    including their features and content (collectively referred to herein as "Website" or "Online
                                    Content").
                                    This statement applies to all platforms and devices (such as mobile devices or desktop PCs) on
                                    which
                                    our online offering is used or executed, regardless of the domain or system used.&nbsp;This
                                    information is
                                    provided in accordance with Art. 13 GDPR.<br/>
                                    The terms used, such as: "personal data" or their "processing" are explained in the
                                    definitions in
                                    Article 4 of the General Data Protection Regulation (GDPR).</p>

                                <p>Within this online offer processed personal user data are personal data (such as customer name
                                    and
                                    address), contract data (contract numbers, clerks, services used, information on payment), as
                                    well as
                                    data on use and customer input within our online offering (eg interest in certain products or
                                    content or
                                    input in the contact form).
                                    Persons affected by data processing include all visitors to our online offer, including business
                                    partners, interested parties and customers, hereinafter referred to as "users".
                                    The terms used, such as "User", "Customer" or "Service Provider" are to be understood as
                                    gender-neutral.
                                    All personal user data will be processed in compliance with the relevant data protection
                                    regulations.
                                    The basis for this is the existence of a legal permit and the consent of the user. The data
                                    processing
                                    is for the performance of our contractual services (eg order processing) or the online service
                                    (eg to
                                    ensure and comply with legal regulations), or due to our legitimate interest (eg for the
                                    security of our
                                    online offer within the meaning of Art. 6 (1) GDPR, analysis to optimize the safety and
                                    efficiency of
                                    our business, including profiling for advertising and marketing purposes, collection of reach
                                    and access
                                    data and third-party services), we will use the data in accordance with the legal permission
                                    framework.
                                    Art. 6 (1) a. and Art. 7 GDPR form the legal basis for the consent, Art. 6 (1) b. GDPR serves as
                                    the
                                    legal basis for the processing of contracts and services. The legal basis for the processing of
                                    the data
                                    to fulfill our legal obligations is Art. 6 (1) c. GDPR, and as a basis for the processing of the
                                    data is
                                    tosafeguard our legitimate interests, according to Art. 6 (1) f. GDPR.</p>

                                <h4>Disclosure of data to third parties and third-party providers</h4>

                                <p>A transfer of data to third parties takes place exclusively in accordance with legal
                                    requirements. It
                                    only takes place if this is necessary for the purpose of the contract (in accordance with
                                    Article 6 (1)
                                    (b) GDPR) or because of legitimate interests in our economic and effective business operations
                                    (pursuant
                                    to Article 6 (1) GDPR).
                                    In order to comply with the legal requirements and for the protection of personal data, we also
                                    take
                                    appropriate legal, technical and organizational measures when using subcontractors.
                                    If third-party services, tools or other means are used and the named seat of this provider is
                                    located in
                                    a third country, data transfer to that country is also likely. The GDPR is an EU regulation and
                                    applies
                                    to all member states. Transmission to countries outside the EU or the European Economic Area is
                                    only
                                    permitted with legal permission, consent of the users, or at an adequate level of data
                                    protection in the
                                    respective third country.</p>

                                <h4>If the Yadore GmbH puts advertisement on the website yadore.com, other websites of the Yadore
                                    GmbH or
                                    third-party websites, the following applies</h4>

                                <p>The Yadore GmbH only records data if a user actually clicks on an advertisement from the Yadore
                                    GmbH. We
                                    anonymously record the time of the click, the website the user clicked on the advertisement,
                                    which
                                    advertisement was clicked and the information according to this advertisement (the merchant
                                    clicked, the
                                    category the advertisement was from). Also, we record the referrer, so the website the user
                                    came, the IP
                                    of the user, the user’s browser and the country the user came from. We also set a user ident
                                    number for
                                    the click from that website the advertisement was placed on and an ident number for the whole
                                    click
                                    process. Besides that, we don’t collect or record any personal data from the user.</p>

                                <h4>The following data of the user will just be forwarded to the advertising partner for the purpose
                                    of
                                    provision invoicing</h4>

                                <p>The Yadore GmbH will send a anonymous ident number to the advertising partner for the purpose of
                                    making
                                    sure we count the same amount of clicks as the advertising partner and to have a correct payment
                                    at the
                                    end of each month. We don’t forward any personal data from the user, just the click ident number
                                    especially created for the click event.</p>

                                <h4>We send the following anonymous data to the provider of the third-party websites
                                    (=”publisher”)</h4>

                                <p>In the process of the click we create a “click ident number”. But there is no chance for the
                                    publisher to
                                    see which user is behind a click ident number or he cant access any personal data from the user.
                                    The
                                    publisher has the chance to create his own click ident number. With this we will give him the
                                    time of
                                    the click, the country of the user and the merchant the user actually clicked on his website.
                                    The named
                                    data is saved for 90 days for the purpose of correct payments to the publisher. This is
                                    regulated in
                                    Art. 6 (1) lit b. GDPR, which allows the handling of such data to fulfill our contractual
                                    obligations.</p>

                                <h4>The Yadore GmbH automatically collects and saves data in so called server logs, which the
                                    browser of the
                                    user automatically sends to the Yadore GmbH</h4>

                                <p>These are:</p>
                                <ul>
                                    <li>Anonymized IP address</li>
                                    <li>Browser type and version</li>
                                    <li>Operating system</li>
                                    <li>Referrer URL</li>
                                    <li>Hostname of the accessing computer</li>
                                    <li>Time of the server request</li>
                                </ul>


                                <p>Combination of this data with any other data sources is not happening at any time.</p>

                                <p>This is regulated in Art. 6 (1) lit b. GDPR, which allows the handling of such data to fulfill
                                    our
                                    contractual obligations.</p>

                                <p>If a user sends a request to the Yadore GmbH via the contact form, we will record and save the
                                    information given in the contact form and the data the user provided to contact him until the
                                    request is
                                    solved. We do not give this data to anyone until the user requests it.</p>

                                <p>The handling of the data given in the contact form is exclusively based on the consent of the
                                    user
                                    according to Art. 6 (1) lit a. GDPR. The user can withdraw this consent at any time. The user
                                    only has
                                    to send us a informal message via e-mail to the Yadore GmbH. The legality of the data processing
                                    operations carried out until the revocation remains unaffected by the revocation.</p>

                                <p>The data entered by the user in the contact form remain stored at Yadore GmbH until the user
                                    requests
                                    deletion, revokes consent for storage or the purpose for data storage is dropped. Mandatory
                                    legal
                                    regulations such as retention periods remain untouched.</p>

                                <h4>Measures for protection and safety</h4>

                                <p>In order to protect the data processed by us from accidental or intentional manipulation,
                                    destruction,
                                    loss or access by unauthorized persons, and to comply with the provisions of data protection
                                    laws, we
                                    make technical, organizational and contractual security arrangements according to the state of
                                    the art.
                                    The encrypted transmission of data between our server and your browser is one of the security
                                    measures
                                    used.</p>

                                <h4>Fulfillment of contractual services</h4>

                                <p>In order to fulfill our contractual and service obligations, we process stock data (for example
                                    name and
                                    address as well as contact data of the users) and data on concluded contracts (for example used
                                    services, information on payment and shipping) acc. Art. 6 (1) lit b. GDPR.
                                    The required mandatory information for the creation of a user account will be communicated to
                                    the users
                                    during the registration. Indexing of user accounts by search engines is not possible because
                                    they are
                                    not public. Data from terminated user accounts will be deleted unless storage is necessary for
                                    commercial or tax law reasons (according to Art. 6 (1) (c) GDPR). Upon termination, it is up to
                                    the
                                    users to secure their data before the end of the contract. We are entitled to the irretrievable
                                    deletion
                                    of all data stored by the user during the term of the contract.
                                    To protect against misuse or unauthorized use and to protect our legitimate interests, we store
                                    the IP
                                    address and time when registering, re-registering and using our online services. Basically, this
                                    data is
                                    not passed on to third parties, exceptions are the pursuit of our claims or a legal obligation
                                    under
                                    Art. 6 (1) c GDPR.
                                    For advertising purposes, we create a user profile based on the usage data (eg visits to our web
                                    pages
                                    or specific product interests) as well as content data (entries in forms or information in the
                                    customer
                                    account) in order to be able to show users interesting product hints and offers.
                                    &nbsp;</p>

                                <h4>Contact by the user</h4>

                                <p>To process user requests (via email or contact form), the information of the user in accordance
                                    with.
                                    Art. 6 (1) b GDPR processed.</p>

                                <h4>Online presence in social media</h4>

                                <p>Based on our legitimate interests within the meaning of Art. 6 (1) f. GDPR we maintain online
                                    presence on
                                    social networks and platforms. There it tries to communicate with customers, interested parties
                                    and
                                    users, also there is informed about our services. When calling the respective networks and
                                    platforms,
                                    the terms and conditions and the data processing guidelines apply to their respective operators.
                                    Insofar as this privacy statement does not specify any further processing of the data, the data
                                    of the
                                    users who communicate with us or interact with our content will be processed.</p>

                                <h4>Measuring the range and use of cookies</h4>

                                <p>Cookies are small files that are stored on the users computer.
                                    We mainly use cookies (session cookies), which are deleted from the respective storage medium at
                                    the end
                                    of the browser session. Session cookies are needed for example, to allow shopping cart functions
                                    or to
                                    store your entries across multiple pages. However, we also use cookies that remain on the user's
                                    hard
                                    drive. This allows for automatic detection of the user when re-visiting and the preferred inputs
                                    and
                                    settings. These cookies are stored for a period of one month to 10 years on the hard disk and
                                    delete
                                    themselves after the given time. These cookies are primarily intended to make the online
                                    offering more
                                    user-friendly, secure and effective.
                                    We also inform users within this Privacy Policy about the use of cookies in the context of
                                    pseudonymous
                                    range measurement.
                                    If users want to avoid storing cookies, this option can be disabled in the browser settings
                                    themselves.
                                    Already stored cookies can also be deleted there, however, the exclusion of cookies can lead to
                                    functional restrictions of our online offer.
                                    An objection to the use of cookies for the purpose of measuring reach and advertising purposes
                                    can be
                                    obtained through the deactivation page of the Network Advertising Initiative (<a
                                        href="http://optout.networkadvertising.org/">http://optout.networkadvertising.org/</a>), the
                                    European website (<a target="_blank" rel="noopener noreferrer"
                                                         href="http://www.youronlinechoices.com/uk/your-ad-choices/">http://www.youronlinechoices.com/uk/your-ad-choices/</a>
                                    and additionally the US-American website (<a target="_blank" rel="noopener noreferrer"
                                                                                 href="http://www.aboutads.info/choices">http://www.aboutads.info/choices</a>).
                                </p>

                                <h4>Collection of access data and records (logfiles)</h4>

                                <p>Any access to our servers is subject to our legitimate interest within the meaning of Art. 6 (1)
                                    f. GDPR,
                                    corresponding data (so-called server log files), including date and time, amount of data, name
                                    of the
                                    accessed website, success report on the call, the operating system including browser type and
                                    version,
                                    the previously visited website, the IP address and the provider.
                                    For the purpose of fraud or misuse the logfile information is stored for security reasons for a
                                    maximum
                                    of seven days and then deleted. If certain data is necessary for evidence purposes, the deletion
                                    will be
                                    postponed until the final clarification of the incident.</p>

                                {/*<h4>Use and use of Google Analytics</h4>*/}

                                {/*<p>For the optimization, analysis and economic operation of our online offer, we rely on our own*/}
                                {/*    interest*/}
                                {/*    within the meaning of Art. 6 (1) f. GDPR, Google Analytics. This is a web analytics service*/}
                                {/*    provided by*/}
                                {/*    Google LLC Google uses cookies for the purpose of analysis with information about the use of the*/}
                                {/*    online*/}
                                {/*    offer by the users, which are usually transmitted to and stored on a Google server in the USA.*/}
                                {/*    &nbsp;Google provides a guarantee for compliance with European data protection law and is*/}
                                {/*    certified under the*/}
                                {/*    Privacy Shield Agreement (<a target="_blank" rel="noopener noreferrer"*/}
                                {/*                                 href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAIstatus=Active">https://www.privacyshield.gov/participant</a>).*/}
                                {/*    Google produces reports on the use of our online offer on our behalf. For this and for further*/}
                                {/*    services*/}
                                {/*    on our behalf, information about the activities of the users are collected within our offer.*/}
                                {/*    This*/}
                                {/*    information can also be used to create pseudonymous usage profiles.</p>*/}

                                {/*<p>Google Analytics is used by us to show ads placed by Google (and its affiliates) only to those*/}
                                {/*    users who*/}
                                {/*    have certain characteristics (interest in specific topics or products) (so-called remarketing).*/}
                                {/*    By using*/}
                                {/*    Remarketing Audiences, we ensure that the ads are not annoying and that they are in the interest*/}
                                {/*    of the*/}
                                {/*    user.<br/>*/}
                                {/*    In general, Google shortens the user's IP address within the EU or the EEA (IP anonymization*/}
                                {/*    enabled).*/}
                                {/*    In exceptional cases, however, the full IP address can be transferred to a Google server in*/}
                                {/*    the US and*/}
                                {/*    shortened there.<br/>*/}
                                {/*    A combination of the IP address of the user and other Google data does not take place. Users*/}
                                {/*    can prevent*/}
                                {/*    the collection and processing of user data by downloading and installing the browser plug-in*/}
                                {/*    available*/}
                                {/*    at this link: <a target="_blank" rel="noopener noreferrer"*/}
                                {/*                     href="http://tools.google.com/dlpage/gaoptout?hl=en">http://tools.google.com/dlpage/gaoptout?hl=en</a>*/}
                                {/*    The storage of cookies can also be done by Settings in the respective browsers are avoided.*/}
                                {/*    In addition to the browser settings and Google's additional software, we offer another*/}
                                {/*    function to*/}
                                {/*    prevent data collection. Here you can see if the collection of your views via Google*/}
                                {/*    Analytics is*/}
                                {/*    activated on our website. You can also prevent capture by clicking "Disable Now". If you*/}
                                {/*    click the*/}
                                {/*    button, an HTML5 storage object is saved on your computer, which then ensures that no script*/}
                                {/*    is loaded*/}
                                {/*    by Google Analytics. If the site data is deleted in this browser, the link must be clicked*/}
                                {/*    again.*/}
                                {/*    Furthermore, the opt-out applies only within the browser you use and only within our*/}
                                {/*    respective*/}
                                {/*    webdomain, on which the link was clicked.</p>*/}

                                {/*<script>*/}
                                {/*    if (localStorage.getItem('optout_GA')) {*/}
                                {/*    document.write('<span> Google Analytics ist deaktiviert. </span><button onclick="deleteOptOutGA()">Jetzt aktivieren</button>');*/}
                                {/*} else {*/}
                                {/*    document.write('<span> Google Analytics ist aktiviert. </span><button onclick="setOptOutGA()">Jetzt deaktivieren</button>');*/}
                                {/*}*/}

                                {/*    function setOptOutGA() {*/}
                                {/*    localStorage.setItem('optout_GA', 'true');*/}
                                {/*    window.location.reload(false);*/}
                                {/*}*/}

                                {/*    function deleteOptOutGA() {*/}
                                {/*    localStorage.removeItem('optout_GA');*/}
                                {/*    window.location.reload(false);*/}
                                {/*}*/}
                                {/*</script>*/}
                                {/*<span> Google Analytics ist aktiviert. </span>*/}
                                {/*<button onClick="setOptOutGA()">Jetzt deaktivieren</button>*/}

                                <p>For further information on settings and possibilities of appeal as well as for data collection by
                                    Google,
                                    please contact Google directly: <a target="_blank" rel="noopener noreferrer"
                                                                       href="https://www.google.com/intl/de/policies/privacy/partners">https://www.google.com/intl/de/policies/privacy/partners</a>,
                                    <a target="_blank" rel="noopener noreferrer"
                                       href="http://www.google.com/policies/technologies/ads">http://www.google.com/policies/technologies/ads</a>
                                    and <a target="_blank" rel="noopener noreferrer"
                                           href="http://www.google.com/settings/ads">http://www.google.com/settings/ads</a>
                                    You can also view and edit your ad settings here, <a target="_blank" rel="noopener noreferrer"
                                                                                         href="https://adssettings.google.com/">https://adssettings.google.com/</a>.
                                </p>

                                <h4>Integration of services and content of third parties</h4>

                                <p>Our online offer also includes offers from third-party providers. This is also based on our
                                    legitimate
                                    interest within the meaning of Art. 6 (1) f. GDPR. Content and its presentation (such as videos
                                    or
                                    fonts) require that third parties recognize the user's IP address. For the transmission of the
                                    contents
                                    to the browser this is unavoidable. When selecting third-party vendors, we take care to only use
                                    those
                                    vendors who use the IP address only for delivery of the content. In addition, third parties may
                                    use web
                                    beacons or pixel tags to collect data for statistics and marketing. As a result, for example
                                    information
                                    about the visitors of the website will be evaluated. All data may be stored in cookies on the
                                    device
                                    used by the user, pseudonymised. These data include technical information about the operating
                                    system and
                                    browser, as well as data on the use of the offer. This data can also be linked to data from
                                    other
                                    sources.<br/>
                                    Below you will find an overview of the third-party providers we include, including links to
                                    the
                                    corresponding data protection statements. These also contain further information on
                                    possibilities of
                                    objection, as well as opt-out options, if these are possible.</p>
                                <ul>
                                    <li>For the integration and administration of the various analysis and marketing services we can
                                        use the
                                        "Google Tag Manager".
                                    </li>
                                    <li>If we use external Google fonts
                                        (<a target="_blank" rel="noopener noreferrer" href="https://www.google.com/fonts">https://www.google.com/fonts</a>)
                                        on our online offering, we do so by calling Google at Google. You can find the privacy
                                        policy here:
                                        <a target="_blank" rel="noopener noreferrer"
                                           href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>,
                                        Again, there is an opt-out method: <a target="_blank" rel="noopener noreferrer"
                                                                              href="https://www.google.com/settings/ads/">https://www.google.com/settings/ads/</a>.
                                    </li>
                                    <li>External code of the JavaScript framework "jQuery" provided by the third-party jQuery
                                        Foundation, <a target="_blank" rel="noopener noreferrer" href="https://jquery.org">https://jquery.org</a>.
                                    </li>
                                </ul>


                                <h4>Further information on third-party cookies</h4>

                                <p>The Yadore GmbH cooperates with a number of affiliate networks that serve as an intermediate
                                    between the
                                    Yadore GmbH website partner and the advertiser. These networks use cookies to record clicks and
                                    subsequent purchases to be able to pay a commission to the Yadore GmbH. The affiliate networks’
                                    cookies
                                    are placed when a link to an advertiser or an advertiser’s product is clicked within a partner
                                    website.
                                    The Yadore GmbH does not have any influence on the affiliate networks’ cookies. In the
                                    following, you
                                    can find an excerpt of the affiliate networks with which the Yadore GmbH works:</p>

                                <div className="table">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <th>Partner</th>
                                            <td>Website</td>
                                            <td>Privacy Policy</td>
                                            <td>Cookies</td>
                                        </tr>
                                        <tr>
                                            <th>Connexity</th>
                                            <td><a href="http://connexity.com/" target="_blank" rel="noopener noreferrer">Link</a></td>
                                            <td><a href="http://connexity.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Link</a></td>
                                            <td><a href="http://connexity.com/cookie-policy/" target="_blank" rel="noopener noreferrer">Link</a></td>
                                        </tr>
                                        <tr>
                                            <th>Guenstiger</th>
                                            <td><a href="https://www.guenstiger.de/" target="_blank" rel="noopener noreferrer">Link</a></td>
                                            <td><a href="https://www.guenstiger.de/Text/Datenschutz.html" target="_blank" rel="noopener noreferrer">Link</a>
                                            </td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th>Kelkoo</th>
                                            <td><a href="http://www.kelkoo.co.uk/" target="_blank" rel="noopener noreferrer">Link</a></td>
                                            <td><a href="http://www.kelkoo.co.uk/company-pages/privacy-policy"
                                                   target="_blank" rel="noopener noreferrer">Link</a></td>
                                            <td><a href="http://www.kelkoo.co.uk/company-pages/cookie-policy"
                                                   target="_blank" rel="noopener noreferrer">Link</a></td>
                                        </tr>
                                        <tr>
                                            <th>PriceRunner</th>
                                            <td><a href="https://www.pricerunner.com/" target="_blank" rel="noopener noreferrer">Link</a></td>
                                            <td><a href="https://www.pricerunner.com/c/privacy-policy" target="_blank" rel="noopener noreferrer">Link</a></td>
                                            <td><a href="https://www.pricerunner.com/c/cookie-policy" target="_blank" rel="noopener noreferrer">Link</a></td>
                                        </tr>
                                        <tr>
                                            <th>Shopping24</th>
                                            <td><a href="https://www.s24.com/" target="_blank" rel="noopener noreferrer">Link</a></td>
                                            <td><a href="https://www.s24.com/datenschutzbestimmungen/" target="_blank" rel="noopener noreferrer">Link</a></td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <th>Solute</th>
                                            <td><a href="https://www.soluten.com" target="_blank" rel="noopener noreferrer">Link</a></td>
                                            <td><a
                                                href="https://www.soluten.com/intl/legal/#anchor_c0ce6c5f_Accordion-Privacy-policy"
                                                target="_blank" rel="noopener noreferrer">Link</a></td>
                                            <td><a
                                                href="https://www.soluten.com/intl/legal/#anchor_44b7d37d_Accordion-Cookie-policy"
                                                target="_blank" rel="noopener noreferrer">Link</a></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <h4>Personal rights of users</h4>

                                <p>Upon request, every user can obtain information about their personal data stored by us.
                                    In addition, users have the right to have incorrect data rectified and to limit the processing
                                    and
                                    deletion of their personal data. In addition, the right to data portability can be invoked. A
                                    complaint
                                    to the competent supervisory authority is possible at any time.
                                    Any consent given by the user can always be revoked at any time, only with future effect.</p>

                                <h4>Data deletion</h4>

                                <p>Data that is not subject to a statutory retention period will be deleted as soon as it is no
                                    longer
                                    necessary for your purpose. If the deletion is not possible due to its purpose or other
                                    provisions, its
                                    processing will be restricted. Blocking the data thus prevents processing for other purposes.
                                    The storage takes place in accordance with § 257 exp. 1 HGB (for trading books, inventories,
                                    opening
                                    balance sheets, annual accounts, trade letters, accounting documents, etc.) for 6 years, as well
                                    as §
                                    147 (1) AO (for books, records, management reports, accounting documents, trading and business
                                    letters,
                                    documents relevant to taxation, etc.) for 10 years.</p>

                                <h4>Right to object</h4>

                                <p>Users may object to the processing of their personal data in accordance with legal requirements
                                    at any
                                    time. The objection may in particular be made against processing for direct marketing
                                    purposes.</p>

                                <h4>Updating the privacy policy</h4>

                                <p>We reserve the right to change this privacy policy if the legal situation changes, as well as
                                    changes in
                                    our services or data processing. However, this applies exclusively with regard to declarations
                                    of data
                                    processing. If users' consent is required, or if elements of the privacy policy contain
                                    provisions of
                                    the contractual relationship with the users, changes are only possible with the consent of the
                                    users.
                                    We ask users to regularly obtain independent information about the content of the privacy
                                    policy.</p>
                            </section>
                        </div>
                    </div>
                </PageRow>
            </>
        )
    }
}

export default DataProtection;
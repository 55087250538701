import React from 'react';
import {Card, CardBody, Container, FormGroup, Row} from "reactstrap";
import {AvFeedback, AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import Success from "../../../App/components/Messages/Success";
import Error from "../../../App/components/Messages/Error";
import Ajax from "../../../../Helper/Ajax";

type ResetPasswordRouteMatch = {
    isExact: true,
    passwordResetJwt: String,
}

type ResetPasswordProps = {
    match: ResetPasswordRouteMatch,
}

type ResetPasswordState = {
    isErrorCritical: boolean,
    errorMessage: String,
    dataTableProgressPending: boolean,
}

class InitialPassword extends React.Component<ResetPasswordProps, ResetPasswordState> {
    state = {
        password: '',
        passwordRepitition: '',
        passwordResetJwt: this.props.match.params.passwordResetJwt || '',
        success: false,
        successMessage: '',
        isErrorCritical: true,
        errorMessage: '',
    };

    submit() {
        const postData = {
            password: this.state.password,
            passwordRepitition: this.state.passwordRepitition,
            passwordResetJwt: this.state.passwordResetJwt,
        };

        const promise = Ajax.createPostXhr(process.env.REACT_APP_API_URL + '/reset-password', postData);

        promise.then(response => {
            const parsedResponse = JSON.parse(response);

            if (parsedResponse.errorMessage || parsedResponse.warningMessage) {
                this.setState({
                    success: false,
                    isErrorCritical: !parsedResponse.success,
                    errorMessage: parsedResponse.errorMessage || parsedResponse.warningMessage,
                });
                return;
            }

            this.setState({
                ...this.state,
                errorMessage: '',
                success: true,
                successMessage: 'Your password was set successfully.'
            });
        }).catch(error => {
            console.error(error);
            this.setState({
                ...this.state,
                isErrorCritical: true,
                errorMessage: 'An unexpected error occured. Please try again later.',
            });
        });
    }

    render() {
        let  homeLink = '/dashboard';

        if (localStorage['userGroup'] === undefined) {
            homeLink = '/';
        }

        return (
            <>
                <div className="account-pages mt-5 mb-5">
                    <Container>
                        <Row className="justify-content-center">
                            <div className="col-md-8 col-lg-6 col-xl-5">
                                <Card className="bg-pattern">
                                    <CardBody className="p-4 position-relative">

                                        <div className="text-center w-200 m-auto">
                                            <a href="/">
                                                <span><img className="loginImg" src="/images/yadore.svg" alt="yadore.svg" height="80" /></span>
                                            </a>
                                            <p className="text-muted mb-4 mt-3">
                                                To set your password, type in your password and repeat it in the field below.
                                            </p>
                                        </div>

                                        <AvForm onValidSubmit={this.submit.bind(this)}>

                                            <AvGroup className="mb-3">
                                                <AvInput
                                                    className={'form-control'}
                                                    placeholder={ 'Type in your password...' }
                                                    type={ 'password' }
                                                    value={ this.state.password }
                                                    onChange={ e => this.setState({ password: e.target.value }) }
                                                    name={"password"}
                                                />
                                                <AvFeedback>This field is invalid</AvFeedback>
                                            </AvGroup>
                                            <AvGroup className="mb-3">
                                                <AvInput
                                                    className={'form-control'}
                                                    placeholder={ 'Repeat your password...' }
                                                    type={ 'password' }
                                                    value={ this.state.passwordRepitition }
                                                    onChange={ e => this.setState({ passwordRepitition: e.target.value }) }
                                                    name={"password-repitition"}
                                                />
                                                <AvFeedback>This field is invalid</AvFeedback>
                                            </AvGroup>

                                            <FormGroup>
                                                <button color="primary" onClick={this.submit.bind(this)} className={'btn btn-primary btn-block'}>Set password</button>
                                            </FormGroup>

                                            <Success
                                                success={this.state.success}
                                                successMessage={this.state.successMessage}
                                            />
                                            <Error
                                                isErrorCritical={this.state.isErrorCritical}
                                                errorMessage={this.state.errorMessage}
                                            />

                                        </AvForm>

                                    </CardBody>
                                </Card>

                                <div className="row mt-3">
                                    <div className="col-12 text-center">
                                        <p className="text-white-50">
                                            Back to <a href={ homeLink } className="text-white ml-1"><b>Home</b></a>
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </Row>
                    </Container>
                </div>
            </>
        )
    }
}


export default InitialPassword;
import React from 'react';
import ReactMarkdown from "react-markdown";

type FaqProps = {
    id: string,
    position: number,
    title: string,
    text: string,
    deleteFaq: Function,
}

class AdminFaq extends React.Component<FaqProps> {
    render() {
        return (
            <div>
                <div className="badge badge-primary faq-pos" style={{float: 'right', paddingLeft: '10px', }}>Position: {this.props.position}</div>
                <div className="faq-question-q-box">Q.</div>
                <h4 className="faq-question" data-wow-delay=".1s">
                    {this.props.title}
                    &nbsp;
                </h4>
                <button
                    onClick={() => { this.props.deleteFaq(this.props.id, this.props.title) }}
                    type="button"
                    className="btn btn-xs red-btn float-right margin-right faq-btn-icon"
                >
                    <i className="mdi mdi-trash-can-outline" />
                </button>
                <button
                    onClick={() => { window.location.href = '/admin/edit-faq?faqId=' + this.props.id}}
                    type="button"
                    className="btn btn-xs btn-outline-warning float-right margin-right faq-btn-icon"
                >
                    <i className="mdi mdi-square-edit-outline" />
                </button>
                <div className="faq-answer mb-4 faq-margin-right">
                    <ReactMarkdown source={ this.props.text } className="markdown"/>
                </div>
            </div>
        )
    }
}

export default AdminFaq;
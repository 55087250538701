import React from 'react';
import Error from "../../../App/components/Messages/Error";
import Ajax from "../../../../Helper/Ajax";
import MyProjectsEmptyState from "./MyProjectsEmptyState";

type Project = {
    projectName: string,
    projectApiKey: string
}

type MyProjectsListState = {
    isErrorCritical: boolean,
    errorMessage: string,
    projects: Project[]
}

class MyProjectsList extends React.Component {
    state: MyProjectsListState = {
        isErrorCritical: false,
        errorMessage: '',
        projects: []
    };

    componentDidMount(): void {
        const apiUrl = process.env.REACT_APP_API_URL + '/my-projects/list';

        const promise: Promise<string> = Ajax.createGetXhr(apiUrl);

        promise.then(response => {
            const parsedResponse = JSON.parse(response);

            if (parsedResponse.errorMessage || parsedResponse.warningMessage) {
                this.setState({
                    isErrorCritical: !parsedResponse.success,
                    errorMessage: parsedResponse.errorMessage || parsedResponse.warningMessage,
                });
                return;
            }

            this.setState({
                projects: parsedResponse.data || [],
                errorMessage: '',
            });
        }).catch(error => {
            console.error(error);
            this.setState({
                errorMessage: 'An unexpected error occured. Please try again later.',
            });
        });
    }

    render() {
        return (
            <div>
                {
                    Object.values(this.state.projects).map((currentProject, i) => {
                        return (
                            <div className="card-box" key={i}>
                                <div className="media-body">
                                    <h4 className="mt-0 mb-2 font-16">{currentProject.projectName}</h4>
                                    <p className="mb-1 apiKey"><b>ApiKey:</b> { currentProject.projectApiKey}</p>
                                </div>
                            </div>
                        )
                    })
                }
                {
                    this.state.projects.length === 0 && <MyProjectsEmptyState />
                }
                <Error
                    isErrorCritical={ this.state.isErrorCritical }
                    errorMessage={ this.state.errorMessage }
                />
            </div>
        )
    }
}

export default MyProjectsList;
import React from 'react';
import ReactMarkdown from "react-markdown";

type FaqProps = {
    title: string,
    text: string,
}

class Faq extends React.Component<FaqProps> {
    render() {
        return (
            <div>
                <div className="faq-question-q-box">Q.</div>
                <h4 className="faq-question" data-wow-delay=".1s">
                    {this.props.title}
                </h4>
                <div className="faq-answer mb-4 faq-margin-right">
                    <ReactMarkdown source={ this.props.text } className="markdown"/>
                </div>
            </div>
        )
    }
}

export default Faq;
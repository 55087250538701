import React from 'react';

class PublisherStatisticInfo extends React.Component {
    render() {
        return (
            <div>
                <p>
                    The numbers and revenue are just approximately, final numbers will be shown on the credit note.
                    Statistics can be delayed by up to two days. So please be patient if the numbers don´t
                    match with your tracking the next day.
                </p>
            </div>
        )
    }
}


export default PublisherStatisticInfo;
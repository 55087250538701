// @flow
/* eslint-disable flowtype/require-return-type */
import React from 'react';

type ErrorProperties = {
  isErrorCritical: boolean,
  errorMessage: string,
}

class Error extends React.Component<ErrorProperties> {
  render() {
    let errorMessage = '';
    if (this.props.errorMessage) {
      errorMessage = this.props.errorMessage.toString();
    }

    if (errorMessage === '') {
      return '';
    }

    let alertClass = 'alert alert-warning';
    if (this.props.isErrorCritical) {
      alertClass = 'alert alert-danger';
    }

    return (
      <React.Fragment>
        <br/>
        <div className={alertClass} role="alert">
          {errorMessage}
        </div>
      </React.Fragment>
    );
  }
}

export default Error;

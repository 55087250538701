import React from 'react';

type BreadcrumbType = {
    link: string,
    name: string,
}

type BreadcrumbProps = {
    breadcrumbs: BreadcrumbType[],
}

class Breadcrumb extends React.Component<BreadcrumbProps> {
    renderBreadcrumbElements() {
        let html = [];

        for (let i = 0; i < this.props.breadcrumbs.length; i++) {
            const { link, name } = this.props.breadcrumbs[i];

            let active   = '';
            let linkHtml = <a href={ link }>{ name }</a>;

            if (i === this.props.breadcrumbs.length - 1) {
                active   = 'active';
                linkHtml = name;
            }

            html.push(<li id={'bc-item-' + (i + 1)} key={i} className={ 'breadcrumb-item ' + active }>{ linkHtml }</li>);
        }

        return html;
    }

    render() {
        return (
            <div className="page-title-right">
                <ol className="breadcrumb m-0">
                    { this.renderBreadcrumbElements().map(element => element) }
                </ol>
            </div>
        );
    }
}

export default Breadcrumb;
export type { BreadcrumbType };

/* eslint-disable no-console */
// @flow

import Env from './Env';

class LoggingHelper {
  static log(value: any): void {
    const devEnv = Env.getDevEnv(Env.getEnv);
    if (devEnv === 'dev') {
      console.log(`[${devEnv}]: ${value}`);
    }
  }

  static error(value: any): void {
    if (Env.getDevEnv(Env.getEnv)) {
      if (value instanceof Error) {
        value = value.message;
      }
      console.error(value);
    }
  }

  static die(reason: string | number = ''): void {
    if (Env.getDevEnv(Env.getEnv)) {
      this.error(reason);
    }
    throw new Error(reason);
  }
}

export default LoggingHelper;

import {useContext, useEffect, useState} from 'react';
import PermissionContext from "./PermissionContext";
import type {Permission} from "./PermissionProvider";

const usePermission = (permission: Permission) => {
  const [loading, setLoading] = useState(true);
  const [allowed: boolean, setAllowed] = useState();

  const {isAllowedTo} = useContext(PermissionContext);

  useEffect(() => {
    isAllowedTo(permission).then((allowed: boolean) => {
      setLoading(false);
      setAllowed(allowed);
    })
  }, [permission, isAllowedTo]);

  return [loading, allowed];
}

export default usePermission;

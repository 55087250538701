import React from 'react';
import Ajax from '../../../../Helper/Ajax';
import Error from '../../../App/components/Messages/Error';
import Success from '../../../App/components/Messages/Success';
import {Button, FormGroup, Label} from "reactstrap";
import {AvFeedback, AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";

class ForgotPassword extends React.Component<> {
    state = {
        email: '',
        success: false,
        successMessage: '',
        isErrorCritical: true,
        errorMessage: '',
    };

    submit() {
        const postData = {
            emailAddress: this.state.email,
        };

        const promise = Ajax.createPostXhr(process.env.REACT_APP_API_URL + '/forgot-password', postData);

        promise.then(response => {
            const parsedResponse = JSON.parse(response);

            if (parsedResponse.errorMessage || parsedResponse.warningMessage) {
                this.setState({
                    success: false,
                    isErrorCritical: !parsedResponse.success,
                    errorMessage: parsedResponse.errorMessage || parsedResponse.warningMessage,
                });
                return;
            }

            this.setState({
                ...this.state,
                errorMessage: '',
                success: true,
                successMessage: 'We sent you an e-mail with a link to reset your password.'
            });
        }).catch(error => {
            console.error(error);
            this.setState({
                ...this.state,
                isErrorCritical: true,
                errorMessage: 'An unexpected error occured. Please try again later.',
            });
        });
    }

    render() {
        let  homeLink = '/dashboard';

        if (localStorage['userGroup'] === undefined) {
            homeLink = '/';
        }

        return (
            <div className="login-bg">
                <img src="/images/login.jpg" className="fixed-image" alt="Laptop" type="Login"/>
                <div className="center">
                    <img className="logo" src="/images/yadore.svg" alt="Logo der Yadore GmbH"
                         title="Yadore – We love conversions."/>
                    <h1 className="headline-one">Forgot password</h1>
                    <p>Forgot your password?
                        Enter your e-mail address and we'll send you an e-mail with instructions to reset your
                        password.
                    </p>
                    <AvForm className="text-danger form-group" onValidSubmit={this.submit.bind(this)}>
                        <AvGroup>
                            <Label className="own-label" for="emailAddress">E-mail</Label>
                            <AvInput
                                className="is-touched is-dirty av-invalid is-invalid form-control yde-input"
                                type="email"
                                name="emailAddress"
                                label="E-mail"
                                placeholder="Enter your e-mail"
                                required
                                value={ this.state.email }
                                onChange={ e => this.setState({ email: e.target.value }) }
                            />
                            <AvFeedback
                            >
                                This field is invalid
                            </AvFeedback>
                        </AvGroup>
                        <FormGroup>
                            <Button className="yde-button yde-button--dark btn btn-secondary">Send</Button>
                        </FormGroup>
                        <Success
                            success={this.state.success}
                            successMessage={this.state.successMessage}
                        />
                        <Error
                            isErrorCritical={this.state.isErrorCritical}
                            errorMessage={this.state.errorMessage}
                        />
                    </AvForm>
                </div>
                <p><a className="forgot text-link" href={homeLink}>Back to Home</a></p>
            </div>
        );
    }
}

export default ForgotPassword;

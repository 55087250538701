import React from 'react';
import DataTable from 'react-data-table-component';
import Ajax from '../../../../Helper/Ajax';
import MerchantFilter from './MerchantFilter';
import Error from '../../../App/components/Messages/Error';
import CsvExportButton from "../CsvExportButton";
import PageTitle from "../../../Layout/PageTitle";
import PageRow from "../../../Layout/PageRow";
import DatePickerHelper from "../../../../Helper/DatePickerHelper";
import HelpFunctions from "../../../../Helper/HelpFunctions";
import PublisherStatisticInfo from "../PublisherStatisticInfo";
import { BrowserView } from "react-device-detect";

const columns = [
    {
        name: 'Merchant Name',
        selector: 'merchantName',
        sortable: true,
        ignoreRowClick: true,
    },
    {
        name: 'Market',
        selector: 'merchantMarket',
        ignoreRowClick: true,
    },
    {
        name: 'Clicks',
        selector: 'clicks',
        sortable: true,
        cell: row => <span className={'float-right'}>{HelpFunctions.addCommas(row.clicks)}</span>,
        right: true,
        ignoreRowClick: true,
    },
    {
        name: 'Revenue',
        selector: 'revenue',
        sortable: true,
        cell: row => <span className={'float-right'}>{HelpFunctions.addRevenueSymbolAndNull(HelpFunctions.addCommas(row.revenue))}</span>,
        right: true,
        ignoreRowClick: true,
    },
];

type MerchantRouteMatch = {
    isExact: true,
    params: {
        market: string,
        project: string,
        dateFrom: string,
        dateTo: string,
        merchantName: string,
        page: string,
        rowsPerPage: number,
        sortColumn: string,
        sortDirection: string,
    },
}

type MerchantProps = {
    match:MerchantRouteMatch,
    setPageTitle: Function
}

class MerchantStatistics extends React.Component<MerchantProps> {
    state = {
        isErrorCritical: true,
        errorMessage: '',
        paginationTotalRows: 1000,
        paginationPerPage: parseInt(this.props.match.params.rowsPerPage) || 100,
        sortColumn: this.props.match.params.sortColumn || 'clicks',
        sortDirection: this.props.match.params.sortDirection || 'desc',
        markets: ['all'],
        projects: ['all'],
        page: this.props.match.params.page || 1,
        data: [],
        loadingFromApi: false
    };

    inputRef     = React.createRef();

    loadFromApi(): void {
        const market        = this.inputRef.current.state.market;
        const project       = this.inputRef.current.state.project;
        const startDate     = DatePickerHelper.getStringFromYmd(this.inputRef.current.state.startDate);
        const endDate       = DatePickerHelper.getStringFromYmd(this.inputRef.current.state.endDate);
        const merchantName  = this.inputRef.current.state.merchantName;
        const page          = this.state.page;
        const perPage       = this.state.paginationPerPage;
        const sortColumn    = this.state.sortColumn;
        const sortDirection = this.state.sortDirection;

        this.updateUrl();

        this.setState({
            loadingFromApi: true,
            data : [],
        },() => {
            const parameters = '?market=' + market + '&project=' + project + '&dateFrom=' + startDate + '&dateTo=' +
                endDate + '&merchantName=' + merchantName + '&page=' + page + '&perPage=' + perPage + '&sortColumn=' +
                sortColumn + '&sortDirection=' + sortDirection;

            const url = process.env.REACT_APP_API_URL + `/merchants${parameters}`;
            const promise: Promise<string> = Ajax.createGetXhr(url);

            promise.then(response => {
                const parsedResponse = JSON.parse(response);
                if (parsedResponse.errorMessage || parsedResponse.warningMessage) {
                    this.setState({
                        isErrorCritical: !parsedResponse.success,
                        errorMessage: parsedResponse.errorMessage || parsedResponse.warningMessage,
                    });
                    return;
                }

                this.setState({
                    paginationTotalRows: parsedResponse.data.resultsTotal,
                    page: parsedResponse.data.page,
                    markets: parsedResponse.data.filter.markets,
                    projects: parsedResponse.data.filter.projects,
                    data: parsedResponse.data.statisticData,
                    loadingFromApi:false,
                    errorMessage: '',
                });
            }).catch(error => {
                console.error(error);
                this.setState({
                    errorMessage: 'An unexpected error occured. Please try again later.',
                });
            });
        });
    }

    updateUrl(): void {
        let newUrl = window.location.protocol + '//' + window.location.host + '/merchant';

        const market        = this.inputRef.current.state.market;
        const project       = this.inputRef.current.state.project;
        const startDate     = DatePickerHelper.getStringFromYmd(this.inputRef.current.state.startDate);
        const endDate       = DatePickerHelper.getStringFromYmd(this.inputRef.current.state.endDate);
        const merchantName  = this.inputRef.current.state.merchantName;
        const page          = this.state.page;
        const perPage       = this.state.paginationPerPage;
        const sortColumn    = this.state.sortColumn;
        const sortDirection = this.state.sortDirection;

        newUrl += '/' + market + '/' + project + '/' + startDate + '/' + endDate + '/' + merchantName;
        newUrl += '/' + page + '/' + perPage + '/' + sortColumn + '/' + sortDirection;

        // eslint-disable-next-line
        history.pushState({url: newUrl}, 'Merchant Page', newUrl);
    }

    onChangeRowsPerPage(currentRowsPerPage: Number) {
        this.setState({
            paginationPerPage:currentRowsPerPage
        }, () => {
            this.loadFromApi();
        });
    }

    onChangePage(page: Number): void {
        this.setState({
            page: page
        }, () => {
            this.loadFromApi();
        });
    }

    onSort(column: Object, sortDirection: String): void {
        this.setState({
            sortColumn: column.selector,
            sortDirection: sortDirection
        }, () => {
            this.loadFromApi();
        });
    }

    componentDidMount(): void {
        this.loadFromApi();
    }

    getCsvExportParameters() {
        return {
            market:        this.inputRef.current.state.market,
            project:       this.inputRef.current.state.project,
            dateFrom:      DatePickerHelper.getStringFromYmd(this.inputRef.current.state.startDate),
            dateTo:        DatePickerHelper.getStringFromYmd(this.inputRef.current.state.endDate),
            merchantName:  this.inputRef.current.state.merchantName,
            page:          this.state.page,
            perPage:       this.state.paginationPerPage,
            sortColumn:    this.state.sortColumn,
            sortDirection: this.state.sortDirection,
        }
    }

    changeState(paritalState: Object) {
        this.setState({...paritalState})
    }

    render() {
        return (
            <>
                <PageTitle
                    title="Merchant statistics"
                    breadcrumbs={[
                        {
                            link: '/dashboard',
                            name: 'Dashboard',
                        },
                        {
                            link: '/merchant-statistics',
                            name: 'Merchant statistics',
                        },
                    ]}
                />

                <PageRow>
                    <div className="card">
                        <div className="card-body">
                            <div id="datatable-buttons_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                <div className="row">
                                    <MerchantFilter
                                        ref={this.inputRef}
                                        loadFromApi={this.loadFromApi.bind(this)}
                                        markets={this.state.markets}
                                        projects={this.state.projects}
                                        urlProps={this.props.match.params}
                                    />
                                    <div className="col-sm-2 col-md-2 filter_button">
                                        <div className="dt-buttons btn-group">
                                            <BrowserView>
                                                <CsvExportButton
                                                    getParametersFunction={this.getCsvExportParameters.bind(this)}
                                                    csvControllerUrl={ process.env.REACT_APP_API_URL + '/merchantDayStatisticsCsvExport' }
                                                    exportFilename="merchant-statistics-export"
                                                    changeState={this.changeState.bind(this)}
                                                    warningMessage={''}
                                                />
                                            </BrowserView>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="table-responsive">
                                        <DataTable
                                            columns={columns}
                                            data={this.state.data}
                                            pagination={true}
                                            paginationServer={true}
                                            paginationTotalRows={this.state.paginationTotalRows}
                                            paginationPerPage={this.state.paginationPerPage}
                                            paginationRowsPerPageOptions={[100, 200, 500]}
                                            // Aktuelle Seite setzen
                                            paginationDefaultPage={parseInt(this.state.page)}
                                            onChangePage={this.onChangePage.bind(this)}
                                            onChangeRowsPerPage={this.onChangeRowsPerPage.bind(this)}
                                            sortServer={true}
                                            defaultSortField={this.state.sortColumn}
                                            defaultSortAsc={this.state.sortDirection === 'asc'}
                                            onSort={this.onSort.bind(this)}
                                            progressPending={this.state.loadingFromApi}
                                            highlightOnHover={true}
                                            responsive={true}
                                            noHeader={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <Error
                                isErrorCritical={this.state.isErrorCritical}
                                errorMessage={this.state.errorMessage}
                            />
                        </div>
                    </div>
                </PageRow>
                <PublisherStatisticInfo/>
            </>
        );
    }
}

export default MerchantStatistics;

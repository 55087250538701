import React from 'react';
import {FormGroup, Label} from 'reactstrap';
import type {CreditNoteType} from './CreditNoteCollection';
import CreditNoteLineItem from "./CreditNoteLineItem";
import CreditNoteLineItemDelete from "./CreditNoteLineItemDelete";
import Ajax from "../../../../Helper/Ajax";
import CreditNoteLineItemAdd from "./CreditNoteLineItemAdd";
import HelpFunctions from "../../../../Helper/HelpFunctions";
import DownloadCreditNotePdfButton from "./DownloadCreditNotePdfButton";
import ErrorWithTarget from "../../../App/components/Messages/ErrorWithTarget";
import PendingCreditNotesView from "./PendingCreditNotesView";
import RecreateCreditNoteButton from './RecreateCreditNoteButton';
import Restricted from "../../../../Helper/PermissionProvider/Restricted";
import dayjs from 'dayjs';

type CreditNotesProps = {
    creditNote: CreditNoteType,
}

type CreditNoteState = {
    success: boolean,
    isErrorCritical: boolean,
    errorCode: number,
    errorMessage: string,
}

class CreditNote extends React.Component<CreditNotesProps, CreditNoteState> {
    state: CreditNoteState = {
        success: false,
        isErrorCritical: true,
        errorCode: 0,
        errorMessage: '',
    };

    creditNoteAction(
        route: string,
        params: {},
    ) {
        if (route === '/admin/release-credit-note') {
            // eslint-disable-next-line no-restricted-globals
            if (!confirm('Do you want to release this credit note?')) {
                return;
            }
        }
        if (route === '/admin/put-credit-note-on-hold') {
            // eslint-disable-next-line no-restricted-globals
            if (!confirm('Do you want to mark this credit note for later release?')) {
                return;
            }
        }
        if (route === '/admin/force-release-credit-note') {
            // eslint-disable-next-line no-restricted-globals
            if (!confirm('Do you want to force release this credit note?')) {
                return;
            }
        }
        if (route === '/admin/cancel-credit-note') {
            // eslint-disable-next-line no-restricted-globals
            if (!confirm('Do you want to cancel this credit note? A new credit note will be created.')) {
                return;
            }
        }

        const promise = Ajax.createPostXhr(process.env.REACT_APP_API_URL + route, params);

        promise.then(response => {
            const parsedResponse = JSON.parse(response);

            if (parsedResponse.errorMessage || parsedResponse.warningMessage ) {
                this.setState({
                    success: false,
                    isErrorCritical: !parsedResponse.success,
                    errorMessage: parsedResponse.errorMessage || parsedResponse.warningMessage,
                });
                return;
            }

            this.setState({
                errorCode: 0,
                success: true,
            });

            this.props.loadCreditNotesFromApi();
        }).catch(error => {
            console.error(error);
            this.setState({
                ...this.state,
                success: false,
                isErrorCritical: true,
                errorCode: 500,
                errorMessage: 'An unexpected error occured. Please try again later.',
            });
        });
    }

    renderReleaseDate() {
        if (this.props.creditNote.creditNoteAggregateStatus === 2) {
            return this.props.creditNote.releaseDate;
        }
        if (this.props.creditNote.creditNoteAggregateStatus === 4) {
            return 'This credit note has been aborted.';
        }
        if (this.props.creditNote.creditNoteAggregateStatus === 6) {
            return 'This credit note has been canceled.';
        }
        if (this.props.creditNote.creditNoteAggregateStatus === 7) {
            return 'The account has been deleted. Please contact your favorite developers to remove the credit note(s) manually.';
        }

        return 'This credit note has not yet been released.';
    }

    renderLineItems() {
        let creditNoteLineItems = [];

        for (let key in this.props.creditNote.creditNoteLineItems) {
            if (this.props.creditNote.creditNoteLineItems[key].type === 'generated') {
                creditNoteLineItems[key] = <CreditNoteLineItem
                    key={this.props.creditNote.creditNoteLineItems[key].id}
                    description={this.props.creditNote.creditNoteLineItems[key].description}
                    clicks={HelpFunctions.addCommas(this.props.creditNote.creditNoteLineItems[key].clicks)}
                    cpc={this.props.creditNote.creditNoteLineItems[key].cpc +  ' €'}
                    amount={HelpFunctions.addCommas(this.props.creditNote.creditNoteLineItems[key].amount) + ' €'}
                    bold={false}
                />
            }
        }

        return creditNoteLineItems;
    }

    renderLineItemsManually() {
        let creditNoteLineItemsManually = [];

        for (let key in this.props.creditNote.creditNoteLineItems) {
            if (this.props.creditNote.creditNoteLineItems[key].type === 'manually') {
                creditNoteLineItemsManually[key] = <CreditNoteLineItemDelete
                    key={this.props.creditNote.creditNoteLineItems[key].id}
                    description={this.props.creditNote.creditNoteLineItems[key].description}
                    clicks={HelpFunctions.addCommas(this.props.creditNote.creditNoteLineItems[key].clicks)}
                    cpc={this.props.creditNote.creditNoteLineItems[key].cpc}
                    amount={HelpFunctions.addCommas(this.props.creditNote.creditNoteLineItems[key].amount) + ' €'}
                    lineItemId={this.props.creditNote.creditNoteLineItems[key].id}
                    creditNoteAction={this.creditNoteAction.bind(this)}
                    bold={false}
                    creditNoteAggregateStatus={this.props.creditNote.creditNoteAggregateStatus}
                />
            }
        }

        return creditNoteLineItemsManually;
    }

    renderAbortButton() {
        if (this.props.creditNote.creditNoteAggregateStatus === 1 || this.props.creditNote.creditNoteAggregateStatus === 3) {
            return <button
                className="btn btn-xs btn red-btn margin-right"
                type="submit"
                name="abort"
                onClick= {() => this.creditNoteAction(
                    '/admin/abort-credit-note',
                    {creditNoteId: this.props.creditNote.id}
                )}
                title={'Abort credit note'}
            >
                <i className="mdi mdi-large mdi-cancel"/>
            </button>
        }
    }

    renderCancelButton() {
        if (this.props.creditNote.creditNoteAggregateStatus === 2 || this.props.creditNote.creditNoteAggregateStatus === 5) {
            return <button
                className="btn btn-xs btn red-btn margin-right"
                type="submit"
                name="cancel"
                onClick= {() => this.creditNoteAction(
                    '/admin/cancel-credit-note',
                    {creditNoteId: this.props.creditNote.id}
                )}
                title={'Cancel credit note'}
            >
                <i className="mdi mdi-large mdi-sim-off"/>
            </button>
        }
    }

    renderRecreateButton() {
        if (this.props.creditNote.creditNoteAggregateStatus === 1 || this.props.creditNote.creditNoteAggregateStatus === 4) {
            return <RecreateCreditNoteButton
                creditNoteId={this.props.creditNote.id}
                creditNoteNumber={this.props.creditNote.creditNoteNumber}
            />;
        }
    }

    renderReleaseButton() {
        if (this.props.creditNote.creditNoteAggregateStatus === 7) {
            return;
        }

        let splitErrorTarget = '';
        if (this.state.errorMessage.length > 0) {
            const errorMessageParts = this.state.errorMessage.split('TargetId: ');
            splitErrorTarget = errorMessageParts[1];
        }

        if (this.props.creditNote.id === splitErrorTarget) {
            return <button
              className="btn btn-xs btn btn-outline-danger margin-right"
              type="submit"
              name="Complete customer bank information"
              onClick= {() => {window.location = '/admin/customer-information?pid=' + this.props.creditNote.publisherId}}
              title={'Complete customer bank information'}
            >
                <i className="mdi mdi-large mdi-exclamation"/>
            </button>
        }

        const sumOfAllPendingCreditNotes = parseFloat(this.props.creditNote.subTotal) + parseFloat(this.props.creditNote.pendingCreditNoteSum);

        // https://synatix.atlassian.net/browse/YAD-6063
        const dateTo = dayjs(this.props.creditNote.dateTo, 'YYYY-MM-DD HH:mm:ss');
        const dateOfChange = dayjs('2023-12-31 23:59:59', 'YYYY-MM-DD HH:mm:ss');
        const payOutLimit = dateTo.isAfter(dateOfChange) ? 50.00 : 10.00;

        if (
          sumOfAllPendingCreditNotes < payOutLimit &&
          this.props.creditNote.creditNoteAggregateStatus !== 2 &&
          this.props.creditNote.creditNoteAggregateStatus !== 4 &&
          this.props.creditNote.creditNoteAggregateStatus !== 5 &&
          this.props.creditNote.creditNoteAggregateStatus !== 6
        ) {
            return <button
              className="btn btn-xs btn btn-warning margin-right"
              type="submit"
              name="Confirm credit note for later release"
              onClick= {() => this.creditNoteAction(
                '/admin/put-credit-note-on-hold',
                {creditNoteId: this.props.creditNote.id}
              )}
              title={'Confirm credit note for later release'}
            >
                <i className="mdi mdi-large mdi-calendar-check"/>
            </button>
        }

        if (
          this.props.creditNote.creditNoteAggregateStatus !== 2 &&
          this.props.creditNote.creditNoteAggregateStatus !== 4 &&
          this.props.creditNote.creditNoteAggregateStatus !== 5 &&
          this.props.creditNote.creditNoteAggregateStatus !== 6
        ) {
            return <button
                className="btn btn-xs btn-outline-success margin-right"
                type="submit"
                name="release"
                onClick= {() => this.creditNoteAction(
                    '/admin/release-credit-note',
                    {creditNoteId: this.props.creditNote.id}
                    )}
                title={'Release credit note'}
            >
                <i className="mdi mdi-large mdi-arrow-up-bold-outline"/>
            </button>
        }
    }

    renderForceReleaseButton() {
        if (this.props.creditNote.creditNoteAggregateStatus === 7) {
            return;
        }

        let splitErrorTarget = '';
        if (this.state.errorMessage.length > 0) {
            const errorMessageParts = this.state.errorMessage.split('TargetId: ');
            splitErrorTarget = errorMessageParts[1];
        }

        if (this.props.creditNote.id === splitErrorTarget) {
            return <button
              className="btn btn-xs btn btn-outline-danger margin-right"
              type="submit"
              name="Complete customer bank information"
              onClick= {() => {window.location = '/admin/customer-information?pid=' + this.props.creditNote.publisherId}}
              title={'Complete customer bank information'}
            >
                <i className="mdi mdi-large mdi-exclamation"/>
            </button>
        }

        if (
          this.props.creditNote.creditNoteAggregateStatus !== 2 &&
          this.props.creditNote.creditNoteAggregateStatus !== 4 &&
          this.props.creditNote.creditNoteAggregateStatus !== 5 &&
          this.props.creditNote.creditNoteAggregateStatus !== 6
        ) {
            return <button
                className="btn btn-xs btn-outline-info margin-right"
                type="submit"
                name="force-release-credit-note"
                onClick= {() => this.creditNoteAction(
                    '/admin/force-release-credit-note',
                    {creditNoteId: this.props.creditNote.id}
                    )}
                title={'Force release credit note'}
            >
                <i className="mdi mdi-large mdi-trophy-outline"/>
            </button>
        }
    }

    changeState(paritalState: Object) {
        this.setState({...paritalState})
    }

    renderStatusPadding() {
        if (this.props.creditNote.creditNoteAggregateStatus === 5) {
            return 'card card-padding-green-stripes';
        }

        if (this.props.creditNote.creditNoteAggregateStatus === 2) {
            return 'card card-padding-green';
        }

        if (this.props.creditNote.creditNoteAggregateStatus === 4) {
            return 'card card-padding-red';
        }

        if (this.props.creditNote.creditNoteAggregateStatus === 6) {
            return 'card card-padding-grey';
        }

        if (this.props.creditNote.creditNoteAggregateStatus === 7) {
            return 'card card-padding-black';
        }

        return 'card card-padding-yellow';
    }

    renderPendingCreditNotesView() {
        if (this.props.creditNote.creditNoteAggregateStatus === 6) {
            return '';
        }
        return <PendingCreditNotesView pendingCreditNotes={this.props.creditNote.pendingCreditNotes}/>;
    }

    renderPublisherId() {
        if (this.props.creditNote.creditNoteAggregateStatus === 7) {
            return <s>{this.props.creditNote.publisherId}</s>;
        }
        return this.props.creditNote.publisherId;
    }

    render() {
        let splitErrorMessage = '';
        let splitErrorTarget = '';
        if (this.state.errorMessage.length > 0) {
            const errorMessageParts = this.state.errorMessage.split('TargetId: ');
            splitErrorMessage = errorMessageParts[0];
            splitErrorTarget = errorMessageParts[1];
        }

        return <>
            <div className={this.renderStatusPadding()}>
                <div className="card-body background-white">
                    <div className="row">
                        <div className="col-lg-2">
                            <div className="d-flex justify-content-between">
                                <div className="flex-direction-column">
                                    <Label for="title" className="font-20">{this.props.creditNote.publisherName}</Label>
                                    <p>Publisher ID: {this.renderPublisherId()}</p>
                                    <p>Credit note number: {this.props.creditNote.creditNoteNumber}</p>
                                    <br/>
                                    {this.renderPendingCreditNotesView()}
                                </div>
                            </div>
                        </div>
                            <div className="col-lg-8">
                                <CreditNoteLineItem
                                    key={'header'}
                                    description={'Description'}
                                    clicks={'Clicks'}
                                    cpc={'CPC €/Click'}
                                    amount={'Amount'}
                                    bold={true}
                                />
                                <hr className="hr"/>
                                {this.renderLineItems()}
                                {this.renderLineItemsManually()}
                                <Restricted to={'credit_note.write'}>
                                    <CreditNoteLineItemAdd
                                    creditNoteAction={this.creditNoteAction.bind(this)}
                                    creditNoteId={this.props.creditNote.id}
                                    creditNoteAggregateStatus={this.props.creditNote.creditNoteAggregateStatus}
                                />
                                </Restricted>
                            </div>
                        <div className="col-lg-2">
                            <FormGroup className="float-right">
                                <Restricted to={'credit_note.write'}>
                                    {this.renderReleaseButton()}
                                    {this.renderCancelButton()}
                                    {this.renderAbortButton()}
                                    {this.renderRecreateButton()}
                                    {this.renderForceReleaseButton()}
                                </Restricted>
                                <Restricted to={'credit_note.read'}>
                                    <DownloadCreditNotePdfButton
                                      creditNoteId={this.props.creditNote.id}
                                      changeState={this.changeState.bind(this)}
                                      openPdf={true}
                                    />
                                    <DownloadCreditNotePdfButton
                                      creditNoteId={this.props.creditNote.id}
                                      changeState={this.changeState.bind(this)}
                                      openPdf={false}
                                    />
                                </Restricted>
                                <ErrorWithTarget
                                  myTarget={this.props.creditNote.id}
                                  givenTarget={splitErrorTarget}
                                  isErrorCritical={this.state.isErrorCritical}
                                  errorMessage={splitErrorMessage}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-2">
                        </div>
                        <div className="col-lg-7">
                            <hr className="hr"/>
                            <CreditNoteLineItem
                                key={'totalWithoutVat'}
                                description={'Total without VAT'}
                                clicks={''}
                                cpc={''}
                                amount={HelpFunctions.addCommas(this.props.creditNote.subTotal) + ' €'}
                                bold={false}
                            />
                            <CreditNoteLineItem
                                key={'vat'}
                                description={'VAT ' + this.props.creditNote.taxRate + ' %'}
                                clicks={''}
                                cpc={''}
                                amount={HelpFunctions.addCommas(this.props.creditNote.taxAmount) + ' €'}
                                bold={false}
                            />
                            <CreditNoteLineItem
                                key={'total'}
                                description={'Total'}
                                clicks={''}
                                cpc={''}
                                amount={HelpFunctions.addCommas(this.props.creditNote.total) + ' €'}
                                bold={true}
                            />
                        </div>
                        <div className="col-lg-3 margin-top-auto">
                            <p className="float-right">Released at: {this.renderReleaseDate()}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}

export default CreditNote;

/* eslint-disable guard-for-in */
// @flow
import LoggingHelper from './LoggingHelper';
import '@babel/polyfill';
import {Permission} from "./PermissionProvider/PermissionProvider";

const XHR_DONE = 4;

class Ajax {
  /**
   * @param url
   * @return {Promise<any>}
   */
  static createGetXhr(url: string): Promise<string> {
    return new Promise((resolve: Function, reject: Function): void => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();

      // let delimiter = '?';
      // if (url.includes('?')) {
      //   delimiter = '&';
      // }
      // url += delimiter + 't=' + Date.now();

      xhr.open('GET', url);

      const apiKey = localStorage['apiKey'];
      if (apiKey) {
        xhr.setRequestHeader('Api-Key', apiKey);
      }

      xhr.onreadystatechange = (): void => {
        this.processResponse(xhr, url, resolve, reject);
      };

      xhr.send();
    });
  }

  /**
   * @param {string} url
   * @param {string} urlParamsObject
   * @return {Promise<Function>}
   */
  static createPostXhr(url: string, urlParamsObject: Object): Promise<string> {
    return new Promise((resolve: Function, reject: Function): void => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();

      xhr.open('POST', url);

      const apiKey = localStorage['apiKey'];
      if (apiKey) {
        xhr.setRequestHeader('Api-Key', apiKey);
      }

      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

      xhr.onreadystatechange = (): void => {
        Ajax.processResponse(xhr, url, resolve, reject);
      };

      const searchParams = new URLSearchParams(urlParamsObject);
      // let delimiter = '?';
      // if (paramString.includes('?') || paramString.includes('&')) {
      //   delimiter = '';
      // }
      //
      // paramString += delimiter + 't=' + Date.now();

      xhr.send(searchParams.toString());
    });
  }

  /**
   * @param {string} url
   * @param {FormData} formData
   * @return {Promise<Function>}
   */
  static createPostXhrWithFormData(url: string, formData: FormData): Promise<string> {
    return new Promise((resolve: Function, reject: Function): void => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();

      xhr.open('POST', url, false);

      const apiKey = localStorage['apiKey'];
      if (apiKey) {
        xhr.setRequestHeader('Api-Key', apiKey);
      }

      xhr.onreadystatechange = (): void => {
        Ajax.processResponse(xhr, url, resolve, reject);
      };

      xhr.send(formData);
    });
  }

  static async fetchPermission(permission: Permission): Promise<boolean> {
    return new Promise((resolve: Function, reject: Function): void => {
      const xhr: XMLHttpRequest = new XMLHttpRequest();

      xhr.open('GET', process.env.REACT_APP_API_URL + '/check-permission?permission=' + permission);

      const apiKey = localStorage['apiKey'];
      if (apiKey) {
        xhr.setRequestHeader('Api-Key', apiKey);
      }

      xhr.onreadystatechange = (): void => {
        if (xhr.readyState === XHR_DONE && xhr.status === 200) {
          const response = xhr.responseText;
          const parsedResponse = JSON.parse(response);
          resolve(parsedResponse.data.hasPermission);
        }
      };

      xhr.send();
    });
  }

  /**
   * @param {XMLHttpRequest} xhr
   * @param {string} url
   * @param {Function} resolve
   * @param {Function} reject
   */
  static processResponse(xhr: XMLHttpRequest, url: string, resolve: Function, reject: Function): void {
    /**
     * Es muss immer eine Prüfung durchgeführt werden, von welcher Seite dieses AjaxObjekt benutz wird, da es sonst
     * zu Fehlern kommt, bei Requests die von dem ClientJs ausgeführt werden.
     */
    if (xhr.readyState === XHR_DONE && xhr.status === 200) {
      const response = xhr.responseText;

      resolve(response);
      return;
    }

    if (xhr.readyState === XHR_DONE && (xhr.status === 403 || xhr.status === 401)) {
      let redirectUrl = localStorage['unauthorizedRedirectUrl'] || '/';
      if (xhr.status === 401) {
        localStorage.removeItem('apiKey');
        localStorage.removeItem('userGroup');
        localStorage.removeItem('impersonating');
        LoggingHelper.error('You are not authorized to access this route.');
        window.location.href = redirectUrl;
        return;
      }
      if (xhr.status === 403) {
        Ajax.createGetXhr(process.env.REACT_APP_API_URL + '/user-group').then((response: string) => {
            const parsedResponse = JSON.parse(response);
            const userGroup = parsedResponse.data.userGroup;
            localStorage['userGroup'] = userGroup;
            window.history.back();
            return;
          }
        )
        return;
      }

      if (xhr.readyState === XHR_DONE && xhr.status !== 200) {
        reject(new Error(`Request for '${url}' failed (XHR-status: ${xhr.status}).`));
      }
    }
  }
}

export default Ajax;

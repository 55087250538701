import React from 'react';
import Ajax from "../../../../Helper/Ajax";
import DataTable from "react-data-table-component";
import Error from "../../../App/components/Messages/Error";

const columns = [
    {
        name: 'Domain',
        selector: 'domain',
    },
    {
        name: 'Market',
        selector: 'market',
        maxWidth: '10%'
    },
];

class NewMerchantsList extends React.Component {
    state = {
        merchantList: [],
        isErrorCritical: true,
        errorMessage: '',
        loadingFromApi: false
    };

    loadFromApi(): void {
        this.setState({
            loadingFromApi: true,
            merchantList : [],
        },() => {
            const url = process.env.REACT_APP_API_URL + `/new-merchants`;
            const promise: Promise<string> = Ajax.createGetXhr(url);

            promise.then(response => {
                const parsedResponse = JSON.parse(response);
                if (parsedResponse.errorMessage || parsedResponse.warningMessage) {
                    this.setState({
                        isErrorCritical: !parsedResponse.success,
                        errorMessage: parsedResponse.errorMessage || parsedResponse.warningMessage,
                    });
                    return;
                }
                this.setState({
                    merchantList: parsedResponse.data.merchantList,
                    loadingFromApi:false,
                    errorMessage: '',
                });
            }).catch(error => {
                console.error(error);
                this.setState({
                    errorMessage: 'An unexpected error occured. Please try again later.',
                });
            });
        });
    }

    componentDidMount(): void {
        this.loadFromApi();
    }

    render() {
        return (
            <>
                <div className="card">
                    <div className="card-body">
                                <div className="table-responsive">
                                    <label>New Merchants:</label>
                                    <DataTable
                                        columns={columns}
                                        data={this.state.merchantList}
                                        responsive={true}
                                        noHeader={true}
                                    />
                                </div>
                        <Error
                            isErrorCritical={this.state.isErrorCritical}
                            errorMessage={this.state.errorMessage}
                        />
                    </div>
                </div>
            </>
        )
    }
}

export default NewMerchantsList;
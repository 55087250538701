import React from 'react';
import PageTitle from "../../../Layout/PageTitle";
import PageRow from "../../../Layout/PageRow";
import Error from "../../../App/components/Messages/Error";
import MyProjectsList from "../../components/MyProjects/MyProjectsList";

type MyProjectsSiteState = {
    isErrorCritical: boolean,
    errorMessage: string,
}

class MyProjectsSite extends React.Component <{}, MyProjectsSiteState>{
    state: MyProjectsSiteState = {
        isErrorCritical: false,
        errorMessage: '',
    };

    render() {
        return (
            <>
                <PageTitle
                    title="My projects"
                    breadcrumbs={[
                        {
                            link: '/dashboard',
                            name: 'Dashboard',
                        },
                        {
                            name: 'My projects',
                        },
                    ]}
                />
                <PageRow>
                    <MyProjectsList/>
                    <Error
                        isErrorCritical={ this.state.isErrorCritical }
                        errorMessage={ this.state.errorMessage }
                    />
                </PageRow>
            </>
        );
    }
}

MyProjectsSite.propTypes = {};

export default MyProjectsSite;
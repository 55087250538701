import React from 'react';
import Topbar from "./Topbar";
import Navbar from "./Navbar";

type HeaderState = {
    isTopMenuOpen: boolean,
    openSubmenues: {
        statistic: boolean,
        myAccount: boolean,
    }
}

class Header extends React.Component<{}, HeaderState> {
    state: HeaderState = {
        isTopMenuOpen: false,
        openSubmenues:  {
            statistic: false,
            myAccount: false,
        }
    };

    toogleMenueOpen() {
        const newValue = !this.state.isTopMenuOpen;
        this.setState({isTopMenuOpen: newValue})
    }

    openSubmenuStatistic() {
        const newValue = !this.state.openSubmenues.statistic;
        const oldState = this.state;
        oldState.openSubmenues.statistic = newValue;
        this.setState(oldState);
    }

    render() {
        if (window.location.pathname.indexOf('/admin/credit-notes/detail/') !== -1) {
            return '';
        }

        return (
            <header id="topnav">
                <Navbar
                    isTopMenuOpen={this.state.isTopMenuOpen}
                    toogleMenuOpen={this.toogleMenueOpen.bind(this)}
                />
                <Topbar
                    isTopMenuOpen={this.state.isTopMenuOpen}
                    openSubmenuStatistic={this.openSubmenuStatistic.bind(this)}
                    openSubmenues={this.state.openSubmenues}
                />
            </header>
        );
    }
}

export default Header;

// @flow

import LoggingHelper from './LoggingHelper';

class Env {
  /** @type {string} */
  static env: string = '';

  /** @param value {string} */
  static set setEnv(value: string): void {
    LoggingHelper.log(`Env->setEnv: ${value}`);

    this.env = value;
  }

  /** @returns {string} */
  static get getEnv(): string {
    return this.env;
  }

  static getDevEnv(env: string): string {
    switch (env) {
      case 'testing':
        return 'dev';
      case 'qa':
        return 'dev';
      case 'dev':
        return 'dev';
      case 'debug':
        return 'dev';
      default:
        return '';
    }
  }
}

export default Env;

// @flow

class HelpFunctions {
  /**
   * @param {HTMLElement|null}elem
   * @param {string}attr
   * @param {string}val
   */
  static setAttribute(elem: ?HTMLElement, attr: string, val: string): void {
    if (elem instanceof HTMLElement) {
      elem.setAttribute(attr, val);
    }
  }

  /**
   * @param {HTMLElement|null}elem
   * @param {string}attr
   */
  static removeAttribute(elem: ?HTMLElement, attr: string): void {
    if (elem instanceof HTMLElement) {
      elem.removeAttribute(attr);
    }
  }

  /**
   * @param {Element|null} elem
   * @param {string} val
   */
  static setValue(elem: ?Element, val: string): void {
    if (elem instanceof HTMLInputElement) {
      elem.value = val;
    }
  }

  /**
   * @param {HTMLElement|null} elem
   * @param {string} val
   */
  static setStyle(elem: ?HTMLElement, val: string): void {
    if (elem instanceof HTMLElement) {
      const cssStyle = new CSSStyleDeclaration();
      cssStyle.cssText = val;
      elem.style = cssStyle;
    }
  }

  /**
   * @param {HTMLElement|null} elem
   * @param {string} attr
   * @param {string} val
   */
  static setStyleAttribute(elem: ?HTMLElement, attr: string, val: string): void {
    if (elem instanceof HTMLElement) {
      if (!elem.hasAttribute('style')) {
        elem.setAttribute('style', '');
      }

      elem.style.setProperty(attr, val);
    }
  }

  /**
   * @param {HTMLElement} elem
   */
  static hide(elem: ?HTMLElement): void {
    this.setStyleAttribute(elem, 'display', 'none');
  }

  /**
   * @param {HTMLElement} elem
   */
  static show(elem: ?HTMLElement): void {
    this.setStyleAttribute(elem, 'display', '');
  }

  /**
   * @param {string} snake
   * @return {string}
   */
  static snakeToCamelCase(snake: string): string {
    const find = /(-\w)/g;
    const convert = (matches: string): string => matches[1].toUpperCase();
    return snake.replace(
      find,
      convert
    );
  }

  /**
   * @param {Element} element
   * @param {string} selector
   * @returns {Element}
   */
  static querySelector(element: HTMLElement | Document, selector: string): HTMLElement {
    let selectedElement = element.querySelector(selector);

    if (!selectedElement) {
      selectedElement = document.createElement('div');
    }

    return selectedElement;
  }

  static isEmpty(object: {}) {
    for(const key in object) {
      if (object.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  static addCommas(nStr: string) {
    nStr += '';
    let x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1,$2');
    }
    return x1 + x2;
  }

  static addRevenueSymbolAndNull(nStr: string) {
    let x = nStr.split('.');

    if (x[1] !== undefined) {
      while (x[1].length < 2) {
        x[1] = x[1] + '0';
      }

      return x[0] + '.' + x[1] + ' €'
    }

    return x[0] + '.00 €'
  }

  static getFullMonthName(date: string) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let month = new Date(date).getMonth();

    return months[month];
  }
}

export default HelpFunctions;

import React from 'react';
import PageRow from './PageRow';
import Breadcrumb from './Breadcrumb';
import { BreadcrumbType } from './Breadcrumb';

type PageTitleProps = {
    title: string,
    breadcrumbs: BreadcrumbType[],
}

class PageTitle extends React.Component<PageTitleProps> {
  render() {
    return (
        <PageRow>
            <div className="page-title-box">
                <Breadcrumb breadcrumbs={ this.props.breadcrumbs }/>
                <h4 className="page-title">{this.props.title}</h4>
            </div>
        </PageRow>
    );
  }
}

export default PageTitle;

import React from 'react';
import {AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import {Button, FormGroup, Label} from 'reactstrap';
import Success from '../../../App/components/Messages/Success';
import Error from '../../../App/components/Messages/Error';
import Ajax from '../../../../Helper/Ajax';

type SetPasswordProps = {
  publisherId: string,
}

type SetPasswordState = {
  success: boolean,
  successMessage: string,
  isErrorCritical: boolean,
  errorMessage: string,
}

class SetPassword extends React.Component<SetPasswordProps, SetPasswordState> {
  state: SetPasswordState = {
    success: false,
    successMessage: '',
    isErrorCritical: false,
    errorMessage: '',
  };

  passwordRef = React.createRef();

  passwordRepititionRef = React.createRef();

  submit(): void {
    const promise = Ajax.createPostXhr(process.env.REACT_APP_API_URL + '/admin/account-settings/set-password', {
      publisherId: this.props.publisherId,
      password: this.passwordRef.current.value,
      passwordRepitition: this.passwordRepititionRef.current.value,
    });

    promise.then(response => {
      const parsedResponse = JSON.parse(response);

      if (parsedResponse.errorMessage || parsedResponse.warningMessage) {
        this.setState({
          success: false,
          isErrorCritical: !parsedResponse.success,
          errorMessage: parsedResponse.errorMessage || parsedResponse.warningMessage,
        });
        return;
      }

      this.setState({
        errorMessage: '',
        success: true,
        successMessage: 'Password successfully set.',
      });
    }).catch(error => {
      console.error(error);
      this.setState({
        ...this.state,
        success: false,
        isErrorCritical: true,
        errorMessage: 'An unexpected error occured. Please try again later.',
      });
    });
  }

  render() {
    return <>
      <div className="row">

        <div className="col-xl-6">
          <div className="card-box">
            <AvForm onValidSubmit={this.submit.bind(this)}>

              <AvGroup className="mb-3">
                <Label for="password-input">Password</Label>
                <AvInput
                  ref={this.passwordRef}
                  type="password"
                  id="password-input"
                  name="password-input"
                  placeholder="Enter a password..."
                />
              </AvGroup>
              <AvGroup className="mb-3">
                <AvInput
                  ref={this.passwordRepititionRef}
                  type="password"
                  id="password-repitition-input"
                  name="password-repitition-input"
                  placeholder="Repeat password..."
                />
              </AvGroup>

              <FormGroup>
                <Button color="primary" className="btn-block">Set password</Button>
              </FormGroup>

              <Success
                success={this.state.success}
                successMessage={this.state.successMessage}
              />
              <Error
                isErrorCritical={this.state.isErrorCritical}
                errorMessage={this.state.errorMessage}
              />

            </AvForm>
          </div>
        </div>

      </div>
    </>;
  }
}

export default SetPassword;

import React from 'react';
import PageTitle from "../../../Layout/PageTitle";
import { Button, Card, CardBody, Label } from "reactstrap";
import PageRow from "../../../Layout/PageRow";
import {AvFeedback, AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import Success from "../../../App/components/Messages/Success";
import Error from "../../../App/components/Messages/Error";
import Ajax from "../../../../Helper/Ajax";
import ArrayDropDown from "../../../Publisher/components/ArrayDropDown";

type CustomerInformationOptions = {
    addressId: number,
    bankAccountOwner: number,
    bankAddressId: number,
    bic: number,
    city: number,
    company: number,
    bankCompany: number,
    countryId: number,
    bankCountryId: number,
    customerPublisherNexusId: number,
    houseNumber: number,
    iban: number,
    id: number,
    postCode: number,
    publisherId: number,
    street: number,
    vat: number
}

type CustomerInformationType = {
    customerPublisherNexusId: string,
    publisherId: string,
    publisherName: string,
    vat: string,
}

type CustomerAddress = {
    id: string,
    company: string,
    street: string,
    houseNumber: string,
    postCode: string,
    city: string,
    countryId: string,
}

type BankInformation = {
    id: string,
    bankCompany: string,
    bankCountryId: string,
    bankCountryName: string,
    iban: string,
    bic: string,
    bankAccountOwner: string,
}

type Country = {
    id: string,
    name: string,
}

type CustomerInformationState = {
    customerInformation: CustomerInformationType,
    customerAddress: CustomerAddress,
    bankInformation: BankInformation,
    customerInformationOptions: CustomerInformationOptions,
    countries: Country[],
    loadingFromApi: boolean,
    isErrorCritical: boolean,
    errorMessage: string,
    success: boolean,
    successMessage: string,
    isNotSaved: boolean,
    isSavePending: boolean,
}

class PublisherCustomerInformation extends React.Component<{}, CustomerInformationState> {
    state: CustomerInformationState = {
        customerInformation: {},
        customerAddress: {},
        bankInformation: {},
        customerInformationOptions: {},
        countries: [],
        loadingFromApi: false,
        isErrorCritical: true,
        errorMessage: '',
        success: false,
        successMessage: '',
        isNotSaved: false,
        isSavePending: false,
    };

    isValid = [];

    buttonOnClickSave(): void {
        this.setState({
            isSavePending: true,
        });

        for (let valid in this.isValid) {
            if (!this.isValid.hasOwnProperty(valid)) {
                continue;
            }

            if (this.isValid[valid] === false) {
                this.setState({
                    success: false,
                    errorMessage: 'Customer information is invalid. Please correct the highlighted fields and try again.',
                    isSavePending: false,
                });

                return;
            }
        }

        const url = process.env.REACT_APP_API_URL + `/publisher/customer-information/edit`;
        const promise: Promise<string> = Ajax.createPostXhr(
            url,
            {
                ...this.state.customerInformation,
                ...this.state.customerAddress,
                ...this.state.bankInformation,
            }
        );

        promise.then(response => {
            const parsedResponse = JSON.parse(response);

            if (parsedResponse.errorMessage || parsedResponse.warningMessage) {
                this.setState({
                    success: false,
                    isErrorCritical: !parsedResponse.success,
                    errorMessage: parsedResponse.errorMessage || parsedResponse.warningMessage,
                    isSavePending: false,
                });
                return;
            }

            this.setState({
                errorMessage: '',
                success: true,
                isNotSaved: false,
                successMessage: 'Customer information for "' + this.state.customerInformation.publisherName +
                    '" was saved successfully.',
                isSavePending: false,
            });
        }).catch(error => {
            console.error(error);
            this.setState({
                ...this.state,
                success: false,
                isErrorCritical: true,
                errorMessage: 'An unexpected error occured. Please try again later.',
                isSavePending: false,
            });
        });
    }

    componentDidMount() {
        const url = process.env.REACT_APP_API_URL + `/publisher/customer-information/get`;
        const promise: Promise<string> = Ajax.createGetXhr(url, {});

        promise.then(response => {
            const parsedResponse = JSON.parse(response);
            if (parsedResponse.errorMessage || parsedResponse.warningMessage) {
                this.setState({
                    isErrorCritical: !parsedResponse.success,
                    errorMessage: parsedResponse.errorMessage || parsedResponse.warningMessage,
                    isSavePending: false,
                });
                return;
            }

            const countries    = parsedResponse.data.countries;
            const countryArray = [];

            for (let country of countries) {
                countryArray.push(JSON.parse(country));
            }

            this.setState({
                ...this.state,
                customerInformation: parsedResponse.data.customerInformation.customerInformation,
                customerAddress: parsedResponse.data.customerInformation.customerAddress,
                bankInformation: parsedResponse.data.customerInformation.bankInformation,
                customerInformationOptions: parsedResponse.data.customerInformationOptions,
                countries: countryArray,
                errorMessage: '',
                isSavePending: false,
            });
        }).catch(error => {
            console.error(error);
            this.setState({
                errorMessage: 'An unexpected error occured. Please try again later.',
                isSavePending: false,
            });
        });

        const handleBeforeunload = event => {
            if (this.state.isNotSaved === true) {
                event.preventDefault();
                event.returnValue = '';
            }
        };
        window.addEventListener('beforeunload', handleBeforeunload);
    }

    updateState(layer: string, key: string, e: Event): void {
        this.setState({
            [layer]: {
                ...this.state[layer],
                [key]: e.target['value'],
            },
            isNotSaved: true
        });
    }

    countrySelectHandler(layer: string, key: string, name: string, value: string): void {
        this.setState({
            [layer]: {
                ...this.state[layer],
                [key]: value,
            },
            isNotSaved: true
        });
    }

    validate(value, ctx, input, cb) {
        if (value.length > this.state.customerInformationOptions[input.props.name]) {
            cb(false);
            this.isValid[input.props.name] = false;
            return;
        }
        this.isValid[input.props.name] = true;
        cb(true);
    }

    renderSaveButton() {
        return <Button
            onClick={this.buttonOnClickSave.bind(this)}
            color="primary"
            className="btn btn-secondary float-right"
            title={'Save account information'}
            disabled={this.state.isSavePending}
        >
            Save account information <i className="mdi mdi-label-l mdi-content-save" />
        </Button>
    }

    render() {
        return (
            <>
                <PageTitle
                    title="Account information"
                    breadcrumbs={[
                        {
                            link: '/dashboard',
                            name: 'Dashboard',
                        },
                        {
                            name: 'Account information'
                        },
                    ]}
                />
                <PageRow>
                    <div className="row">
                        <div className="col-md-8 col-lg-6">
                            <Card>
                                <CardBody className="p-4">
                                    <Label className="customer-label-l" for="customerInformation">
                                        General Information
                                    </Label>
                                    <br/>
                                    <Label for="customerNumber">Customer number: </Label><br/>
                                    <p>{this.state.customerInformation.customerPublisherNexusId}</p>
                                    <Label for="customerNumber">Publisher name: </Label><br/>
                                    <p>{this.state.customerInformation.publisherName}</p>
                                    <AvForm>
                                        <AvGroup className="mb-3">
                                            <Label for="vat">VAT-ID</Label>
                                            <AvInput
                                                name="vat"
                                                placeholder="VAT-ID"
                                                value={this.state.customerInformation.vat}
                                                onChange={ this.updateState.bind(this, 'customerInformation', 'vat') }
                                                validate={{ custom: this.validate.bind(this) }}
                                            />
                                            <AvFeedback>This field is invalid</AvFeedback>
                                        </AvGroup>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </div>
                        </div>
                    <div className="row">
                    <div className="col-md-8 col-lg-6">
                            <Card>
                                <CardBody className="p-4">
                                    <Label className="customer-label-l" for="address">Address</Label>
                                    <AvForm>
                                        <div className="row">
                                            <AvGroup className="mb-3 col-lg-9">
                                                <Label for="street">Company</Label>
                                                <AvInput
                                                    name="company"
                                                    placeholder="Company"
                                                    value={this.state.customerAddress.company}
                                                    onChange={ this.updateState.bind(this, 'customerAddress', 'company') }
                                                    validate={{ custom: this.validate.bind(this) }}
                                                />
                                                <AvFeedback>This field is invalid</AvFeedback>
                                            </AvGroup>
                                            <AvGroup className="mb-3 col-lg-9">
                                                <Label for="street">Street</Label>
                                                <AvInput
                                                    name="street"
                                                    required={true}
                                                    placeholder="Street"
                                                    value={this.state.customerAddress.street}
                                                    onChange={ this.updateState.bind(this, 'customerAddress', 'street') }
                                                    validate={{ custom: this.validate.bind(this) }}
                                                />
                                                <AvFeedback>This field is invalid</AvFeedback>
                                            </AvGroup>
                                            <AvGroup className="mb-3 col-lg-3">
                                                <Label for="houseNumber">House number</Label>
                                                <AvInput
                                                    name="houseNumber"
                                                    placeholder="House number"
                                                    value={this.state.customerAddress.houseNumber}
                                                    onChange={ this.updateState.bind(this, 'customerAddress', 'houseNumber') }
                                                    validate={{ custom: this.validate.bind(this) }}
                                                />
                                                <AvFeedback>This field is invalid</AvFeedback>
                                            </AvGroup>
                                        </div>
                                        <div className="row">
                                            <AvGroup className="mb-3 col-lg-9">
                                                <Label for="city">City</Label>
                                                <AvInput
                                                    name="city"
                                                    placeholder="City"
                                                    value={this.state.customerAddress.city}
                                                    onChange={ this.updateState.bind(this, 'customerAddress', 'city') }
                                                    required
                                                    validate={{ custom: this.validate.bind(this) }}
                                                />
                                                <AvFeedback>This field is invalid</AvFeedback>
                                            </AvGroup>
                                            <AvGroup className="mb-3 col-lg-3">
                                                <Label for="postCode">Postal Code</Label>
                                                <AvInput
                                                    name="postCode"
                                                    placeholder="Postal Code"
                                                    value={this.state.customerAddress.postCode}
                                                    onChange={ this.updateState.bind(this, 'customerAddress', 'postCode') }
                                                    validate={{ custom: this.validate.bind(this) }}
                                                />
                                                <AvFeedback>This field is invalid</AvFeedback>
                                            </AvGroup>
                                        </div>
                                        <div className="row">
                                            <AvGroup className="mb-1 col-lg-6">
                                                <label htmlFor="country-select">Country</label>
                                                <ArrayDropDown
                                                    name="country"
                                                    options={this.state.countries}
                                                    updateList={this.countrySelectHandler.bind(this, 'customerAddress', 'countryId')}
                                                    defaultValue={'64'}
                                                    selectedValue={this.state.customerAddress.countryId}
                                                    defaultName={'Germany'}
                                                    disabled={false}
                                                />
                                            </AvGroup>
                                        </div>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8 col-lg-6">
                            <Card>
                                <CardBody className="p-4">
                                    <Label className="customer-label-l" for="bank-information">Bank Information</Label>
                                    <AvForm>
                                        <AvGroup className="mb-3">
                                            <Label for="company">Bank name</Label>
                                            <AvInput
                                                name="bankCompany"
                                                placeholder="Bank name"
                                                value={this.state.bankInformation.bankCompany}
                                                onChange={ this.updateState.bind(this, 'bankInformation', 'bankCompany') }
                                                validate={{ custom: this.validate.bind(this) }}
                                            />
                                            <AvFeedback>This field is invalid</AvFeedback>
                                        </AvGroup>
                                        <AvGroup className="mb-3">
                                            <Label for="bankAccountOwner">Account owner</Label>
                                            <AvInput
                                                name="bankAccountOwner"
                                                placeholder="Account owner"
                                                value={this.state.bankInformation.bankAccountOwner}
                                                onChange={ this.updateState.bind(this, 'bankInformation', 'bankAccountOwner') }
                                                validate={{ custom: this.validate.bind(this) }}
                                            />
                                            <AvFeedback>This field is invalid</AvFeedback>
                                        </AvGroup>
                                        <AvGroup className="mb-3">
                                            <Label for="iban">IBAN</Label>
                                            <AvInput
                                                name="iban"
                                                placeholder="IBAN"
                                                value={this.state.bankInformation.iban}
                                                onChange={ this.updateState.bind(this, 'bankInformation', 'iban') }
                                                validate={{ custom: this.validate.bind(this) }}
                                            />
                                            <AvFeedback>This field is invalid</AvFeedback>
                                        </AvGroup>
                                        <AvGroup className="mb-3">
                                            <Label for="bic">BIC</Label>
                                            <AvInput
                                                name="bic"
                                                placeholder="BIC"
                                                value={this.state.bankInformation.bic}
                                                onChange={ this.updateState.bind(this, 'bankInformation', 'bic') }
                                                validate={{ custom: this.validate.bind(this) }}
                                            />
                                            <AvFeedback>This field is invalid</AvFeedback>
                                        </AvGroup>
                                        <div className="row">
                                            <AvGroup className="mb-1 col-lg-6">
                                                <div className="form-group mb-3">
                                                    <label htmlFor="country-bank-select">Country</label>
                                                    <ArrayDropDown
                                                        name="bankCountry"
                                                        options={this.state.countries}
                                                        updateList={this.countrySelectHandler.bind(this, 'bankInformation', 'bankCountryId')}
                                                        defaultValue={'64'}
                                                        selectedValue={this.state.bankInformation.bankCountryId}
                                                        defaultName={'Germany'}
                                                        disabled={false}
                                                    />
                                                </div>
                                            </AvGroup>
                                        </div>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8 col-lg-6">
                            <Card>
                                <CardBody>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <Success success={this.state.success} successMessage={this.state.successMessage} />
                                            <Error
                                                isErrorCritical={this.state.isErrorCritical}
                                                errorMessage={this.state.errorMessage}
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                            { this.renderSaveButton() }
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </PageRow>
            </>
        )
    }
}

export default PublisherCustomerInformation;

import React from 'react';

import Ajax from '../../../Helper/Ajax';
import PageTitle from '../../Layout/PageTitle';
import BankViewTable, {BankInformation}  from './BankViewTable';
import PageRow from '../../Layout/PageRow';

type BankInformationListState = {
  bankInformations: BankInformation[],
  isErrorCritical: boolean,
  errorMessage: string,
  accountsLoaded: boolean,
  searchTerm: string,
}

class BankView extends React.Component<{}, BankInformationListState> {
  state: BankInformationListState = {
    bankInformations: [],
    searchTerm: '',
    isErrorCritical: true,
    errorMessage: '',
    bankInformationsLoaded: false,
  };

  loadBankInformationsFromApi(): void {
    if (this.state.bankInformations.length > 0 || this.state.errorMessage !== '') {
      return;
    }

    const promise: Promise<string> = Ajax.createGetXhr(process.env.REACT_APP_API_URL + '/admin/get-bank-view');

    this.setState({ bankInformationsLoaded: true });

    promise.then(response => {
      const parsedResponse = JSON.parse(response);

      if (parsedResponse.errorMessage || parsedResponse.warningMessage) {
        this.setState({
          isErrorCritical: !parsedResponse.success,
          errorMessage: parsedResponse.errorMessage || parsedResponse.warningMessage,
        });
        return;
      }

      this.setState({
        bankInformations: parsedResponse.data.bankInformations,
        errorMessage: '',
      });
    }).catch(error => {
      console.error(error);
      this.setState({
        errorMessage: 'An unexpected error occured. Please try again later.',
      });
    });
  }

  componentDidMount(): void {
    this.loadBankInformationsFromApi();
  }

  render() {
    return(
      <>
        <PageTitle
          title="Bank information"
          breadcrumbs={[
            {
              link: '/admin/dashboard',
              name: 'Dashboard',
            },
            {
              link: '/admin/accounts',
              name: 'Account list',
            },
            {
              name: 'Bank information',
            },
          ]}
        />
        <PageRow>
          <div className="card">
            <div className="card-body">
              <BankViewTable bankInformations={this.state.bankInformations} />
            </div>
          </div>
        </PageRow>
      </>
    );
  }
}

export default BankView;

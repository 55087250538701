import React from 'react';
import PageTitle from "../../../Layout/PageTitle";
import PageRow from "../../../Layout/PageRow";
import DataTable from "react-data-table-component";
import HelpFunctions from "../../../../Helper/HelpFunctions";
import Ajax from "../../../../Helper/Ajax";
import {FormGroup} from "reactstrap";
import DownloadPublisherCreditNotePdfButton from "./DownloadPublisherCreditNotePdfButton";
import { BrowserView, MobileView } from "react-device-detect";

type CreditNote = {
    id: string,
    total: string,
    creditNoteAggregateStatus: number,
    dateFrom: string,
}

type PublisherCreditNoteListState = {
    publisherCreditNoteList: CreditNote[],
    isErrorCritical: boolean,
    errorCode: number,
    errorMessage: string,
}

class PublisherCreditNoteList extends React.Component<{}, PublisherCreditNoteListState> {
    state: PublisherCreditNoteListState = {
        publisherCreditNoteList: [],
        isErrorCritical: true,
        errorCode: 0,
        errorMessage: '',
    };

    columns = [
        {
            name: 'Month',
            selector: 'accountingPeriodCount',
            ignoreRowClick: true,
            cell: (creditNote: CreditNote)=>
                HelpFunctions.getFullMonthName(creditNote.dateFrom)
                + ' ' + new Date(creditNote.dateFrom).getFullYear(),
            grow: 0.33333,
            sortable: true,
            sortFunction: (rowA: CreditNote, rowB: CreditNote) => {
                const timestampA = new Date(rowA.dateFrom).getTime();
                const timestampB = new Date(rowB.dateFrom).getTime();

                return timestampA - timestampB;
            },
        },
        {
            name: 'Total',
            selector: 'total',
            ignoreRowClick: true,
            cell: (creditNote: CreditNote)=>
                HelpFunctions.addCommas(creditNote.total) + ' €',
            grow: 0.33333,
            sortable: true,
            sortFunction: (rowA: CreditNote, rowB: CreditNote) => {
                const totalA = parseFloat(rowA.total);
                const totalB = parseFloat(rowB.total);

                return totalA - totalB;
            },
        },
        {
            name: 'Actions',
            selector: '',
            ignoreRowClick: true,
            cell:(creditNote: CreditNote) => this.actionButtons(creditNote),
            grow: 0.33333,
        },
        {
            selector: '',
            ignoreRowClick: true,
            cell:(creditNote: CreditNote) => this.cancelStatus(creditNote),
            grow: 0.33333,
        },
    ];

    changeState(paritalState: Object) {
        this.setState({...paritalState})
    };

    renderHint(creditNote: CreditNote) {
        if (creditNote.creditNoteAggregateStatus === 5) {
            return <>
                &nbsp;&nbsp;<i className={'fa fa-exclamation-triangle'} /> Payment limit not yet reached.
            </>;
        };
    };

    actionButtons(creditNote: CreditNote) {
        return <FormGroup className="account-action-buttons">
            <BrowserView>
                <DownloadPublisherCreditNotePdfButton
                    creditNoteId={ creditNote.id }
                    changeState={this.changeState.bind(this)}
                    openPdf={false}
                />
                {this.renderHint(creditNote)}
            </BrowserView>
            <MobileView>
                <DownloadPublisherCreditNotePdfButton
                    creditNoteId={ creditNote.id }
                    changeState={this.changeState.bind(this)}
                    openPdf={true}
                />
            </MobileView>
        </FormGroup>;
    };

    cancelStatus(creditNote: CreditNote) {
        if (creditNote.creditNoteAggregateStatus === 6) {
            return <div className="mdi-large badge badge-red">canceled</div>
        }

        return <div> </div>;
    };

    loadPublisherCreditNoteListFromApi() {
        const promise: Promise<string> = Ajax.createGetXhr(process.env.REACT_APP_API_URL + '/credit-notes-list');

        promise.then(response => {
            const parsedResponse = JSON.parse(response);

            if (parsedResponse.errorMessage || parsedResponse.warningMessage) {
                this.setState({
                    isErrorCritical: !parsedResponse.success,
                    errorMessage: parsedResponse.errorMessage || parsedResponse.warningMessage,
                });
                return;
            }

            let list = [];

            if (parsedResponse.data) {
                list = parsedResponse.data;
            }

            this.setState({
                publisherCreditNoteList: list,
                errorCode: 0,
            });

        }).catch(error => {
            console.error(error);
            this.setState({
                errorCode: 500,
                errorMessage: 'An unexpected error occured. Please try again later.',
            });
        });
    };

    componentDidMount(): void {
        this.loadPublisherCreditNoteListFromApi();
    };

    render() {
        return(
            <>
                <PageTitle
                    title="Credit notes"
                    breadcrumbs={[
                        {
                            link: '/admin/dashboard',
                            name: 'Dashboard',
                        },
                        {
                            name: 'Credit notes',
                        },
                    ]}
                />
                <PageRow>
                    <div className="card" id="main">
                        <div className="card-body">
                            <div id="datatable-buttons_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="table-responsive">
                                            <DataTable
                                                columns={this.columns}
                                                data={this.state.publisherCreditNoteList}
                                                paginationPerPage={25}
                                                pagination={true}
                                                paginationRowsPerPageOptions={[25, 50, 100]}
                                                highlightOnHover={true}
                                                responsive={true}
                                                noHeader={true}
                                                defaultSortField={'accountingPeriod'}
                                                defaultSortAsc={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div >
                                <i className={'fa fa-exclamation-triangle'} /> Payment will only be processed if the sum of open payments exceeds 10 euros.
                            </div>
                        </div>
                    </div>
                </PageRow>
            </>
        )
    };
}

export default PublisherCreditNoteList;

import React from "react";
import {Permission} from "./PermissionProvider";

type PermissionContextType = {
  isAllowedTo: (permission: Permission) => Promise<boolean>;
};

const defaultBehaviour: PermissionContextType = {
  isAllowedTo: async () => false,
};

const PermissionContext = React.createContext(defaultBehaviour);

export default PermissionContext;

import React from 'react';
import {BrowserView} from 'react-device-detect';
import Ajax from "../../Helper/Ajax";
import {userGroup} from "../../Helper/UserGroups";
import Restricted from "../../Helper/PermissionProvider/Restricted";

class Navbar extends React.Component<{}> {
    state = {
        burgerMenuOpen: false,
    };

    static async logout(event): void {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        await Ajax.createPostXhr(process.env.REACT_APP_API_URL + '/logout');
        localStorage.clear();
        window.location.href = '/logout';
    }

    static async switchToAdmin(): void {
        localStorage.removeItem('impersonating');
        localStorage.setItem('userGroup', userGroup.ADMIN);
        await Ajax.createPostXhr(process.env.REACT_APP_API_URL + '/admin/un-impersonate')
        window.location.href = '/admin/accounts';
    }

    renderisTopMenuOpenClass() {
        const prefix = 'navbar-toggle nav-link ';
        if (this.props.isTopMenuOpen === true) {
            return prefix + ' open ';
        }
        return prefix;
    }

    renderAdminNavbar() {
        let additionalNavClass = '';
        let additionalClass = 'is-closed';
        if (this.state.burgerMenuOpen === true) {
            additionalClass = 'is-open';
            additionalNavClass = 'show';
        }

        return (
            <div className="menu">
                <div className="flex align nav-container">
                    <a href="/" className="logo">
                        <img className="image" src="/images/yadore.svg" alt="Logo der Yadore GmbH"
                             title="Yadore – We love conversions."/>
                    </a>
                    <div className={"burger " + additionalClass} onClick={this.toogleBurgerNav.bind(this)}>
                        <div className="line-top"></div>
                        <div className="line-middle"></div>
                        <div className="line-bottom"></div>
                    </div>

                    <nav className={"navigation " + additionalNavClass}>
                        <a className="nav--link is-hidden-on-desktop" href="/admin/dashboard">Dashboard</a>
                        <a className="nav--link is-hidden-on-desktop" href="/admin/accounts">Account list</a>
                        <a className="nav--link is-hidden-on-desktop" href="/admin/faq">FAQ</a>
                        <Restricted to={'credit_note.read'}>
                            <a className="nav--link is-hidden-on-desktop" href="/admin/credit-notes">Credit notes</a>
                        </Restricted>
                        <button className="yde-button yde-button--border" onClick={event => Navbar.logout(event)}>
                            Logout
                        </button>
                    </nav>
                </div>
            </div>
        );
    }

    renderPublisherNavbar() {
        let additionalNavClass = '';
        let additionalClass = 'is-closed';
        if (this.state.burgerMenuOpen === true) {
            additionalClass = 'is-open';
            additionalNavClass = 'show';
        }

        let impersonating = <BrowserView renderWithFragment={true}>
            <span className="nav--link--no--decoration">
                Currently impersonating {localStorage['impersonating'] || ''}
            </span>
        </BrowserView>;

        if (!localStorage['impersonating'] || localStorage['impersonating'] === '') {
            impersonating = '';
        }

        let switchToAdmin = <button className="nav--link yde-button yde-button--border" onClick={Navbar.switchToAdmin}>
            Switch to Admin
        </button>


        if (localStorage['userGroup'] === userGroup.PUBLISHER && impersonating === '') {
            switchToAdmin = '';
        }

        return (
            <div className="menu">
                <div className="flex align nav-container">
                    <a href="/" className="logo">
                        <img className="image" src="/images/yadore.svg" alt="Logo der Yadore GmbH"
                             title="Yadore – We love conversions."/>
                    </a>
                    <div className={"burger " + additionalClass} onClick={this.toogleBurgerNav.bind(this)}>
                        <div className="line-top"></div>
                        <div className="line-middle"></div>
                        <div className="line-bottom"></div>
                    </div>
                    <nav className={"navigation " + additionalNavClass}>
                        { impersonating }
                        { switchToAdmin }
                        <a className="nav--link is-hidden-on-desktop" href="/dashboard">Dashboard</a>
                        <a className="nav--link is-hidden-on-desktop" href="/statistics">Statistics</a>
                        <a className="nav--link is-hidden-on-desktop" href="/clicks-statistics">Clicks statistics</a>
                        <a className="nav--link is-hidden-on-desktop" href="/merchant">Merchant statistics</a>
                        <Restricted to={'credit_note.read'}>
                            <a className="nav--link is-hidden-on-desktop" href="/credit-notes">Credit Notes</a>
                        </Restricted>
                        <a className="nav--link is-hidden-on-desktop" href="/my-projects">My Projects</a>
                        <a className="nav--link is-hidden-on-desktop" href="/merchant-list">Merchant list</a>
                        <a className="nav--link is-hidden-on-desktop" href="/change-password">Change password</a>
                        <a className="nav--link is-hidden-on-desktop" href="/account-information">Account information</a>

                        <button className="nav--link yde-button yde-button--border" onClick={event => Navbar.logout(event)}>
                            Logout
                        </button>
                    </nav>
                </div>
            </div>
        );
    }

    toogleBurgerNav() {
        this.setState({burgerMenuOpen: !this.state.burgerMenuOpen});
    }


    renderEmptyNavbar() {
        let additionalNavClass = '';
        let additionalClass = 'is-closed';
        if (this.state.burgerMenuOpen === true) {
            additionalClass = 'is-open';
            additionalNavClass = 'show';
        }

        return (
            <div className="menu">
                <div className="flex align nav-container">
                    <a href="/" className="logo">
                        <img className="image" src="/images/yadore.svg" alt="Logo der Yadore GmbH"
                             title="Yadore – We love conversions."/>
                    </a>
                    <div className={"burger " + additionalClass} onClick={this.toogleBurgerNav.bind(this)}>
                        <div className="line-top"></div>
                        <div className="line-middle"></div>
                        <div className="line-bottom"></div>
                    </div>

                    <nav className={"navigation " + additionalNavClass}>
                        <a className="nav--link" href="https://yadore.com/en/publisher/">Publisher</a>
                        <a className="nav--link" href="https://yadore.com/en/advertiser/">Advertiser</a>
                        <a className="nav--link" href="https://yadore.com/en/implementation/">Implementation</a>
                        <a className="nav--link" href="https://yadore.com/en/mission/">Mission</a>
                        <a className="nav--link yde-button yde-button--border" href="https://yadore.com/en/register/">Register</a>
                        <a className="nav--link yde-button yde-button--dark active" href="https://publisher.yadore.com/"
                           target="_blank" rel="noopener noreferrer" >Login</a>
                    </nav>
                </div>
            </div>
        );
    }

    render() {
        if (localStorage['userGroup'] === userGroup.ADMIN && localStorage['impersonating'] === undefined) {
            return this.renderAdminNavbar();
        }

        if (localStorage['userGroup'] === userGroup.ADMIN || localStorage['impersonating'] !== undefined) {
            return this.renderPublisherNavbar();
        }

        if (localStorage['userGroup'] === userGroup.PUBLISHER) {
            return this.renderPublisherNavbar();
        }

        if (!localStorage['userGroup']) {
            return this.renderEmptyNavbar();
        }

        return this.renderEmptyNavbar();
    }
}

export default Navbar;

import React from 'react';
import PageTitle from "../../../Layout/PageTitle";
import PageRow from "../../../Layout/PageRow";
import {Button, Card, CardBody, Label} from "reactstrap";
import Success from "../../../App/components/Messages/Success";
import Error from "../../../App/components/Messages/Error";
import {AvFeedback, AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import ReactMarkdown from "react-markdown";
import Ajax from "../../../../Helper/Ajax";

type Faq = {
    id: string,
    position: number,
    title: string,
    germanTitle: string,
    germanText: string,
    englishTitle: string,
    englishText: string,
}

type AdminFaqAddState = {
    faq: Faq,
    isErrorCritical: boolean,
    errorMessage: string,
    success: boolean,
    successMessage: string,
}

class AdminFaqAdd extends React.Component {
    state: AdminFaqAddState = {
        faq: {},
        isErrorCritical: true,
        errorMessage: '',
        success: false,
        successMessage: '',
    };

    submit(): void {
        const fields = ['germanTitle', 'germanText', 'englishTitle', 'englishText'];
        for (let field of fields)
        {
            if (typeof this.state.faq[field] === 'undefined') {
                const newState = {...this.state};
                newState.errorMessage = 'The field ' + field + ' can not be empty';
                this.setState(newState);
                return;
            }
        }

        const promise = Ajax.createPostXhr(process.env.REACT_APP_API_URL + '/admin/faq/add', this.state.faq);

        promise.then(response => {
            const parsedResponse = JSON.parse(response);

            if (parsedResponse.errorMessage || parsedResponse.warningMessage) {
                this.setState({
                    success: false,
                    isErrorCritical: !parsedResponse.success,
                    errorMessage: parsedResponse.errorMessage || parsedResponse.warningMessage,
                });
                return;
            }

            let title = this.state.faq.englishTitle;
            if (typeof this.state.faq.englishTitle === 'undefined') {
                title = this.state.faq.germanTitle;
            }
            this.setState({
                errorMessage: '',
                success: true,
                successMessage: 'FAQ "' + title + '" successfully added.',
            });
        }).catch(error => {
            console.error(error);
            this.setState({
                ...this.state,
                success: false,
                isErrorCritical: true,
                errorMessage: 'An unexpected error occured. Please try again later.',
            });
        });
    }

    updateState(layer: string, key: string, e: Event): void {
        this.setState({
            [layer]: {
                ...this.state[layer],
                [key]: e.target['value'],
            }
        });
    }

    render() {
        return (
            <>
                <PageTitle
                    title="Add FAQ"
                    breadcrumbs={[
                        {
                            link: '/admin/dashboard',
                            name: 'Dashboard',
                        },
                        {
                            link: '/admin/faq',
                            name: 'FAQ',
                        },
                        {
                            name: 'Add FAQ'
                        },
                    ]}
                />
                <PageRow>
                    <div className="row">
                        <div className="col-md-8 col-lg-6">
                            <Card>
                                <CardBody className="p-4">
                                    <Label className="customer-label-l" for="customerInformation">
                                        FAQ
                                    </Label>
                                    <AvForm>
                                        <AvGroup className="mb-3 col-lg-2">
                                            <Label for="position">Position</Label>
                                            <AvInput
                                                type="number"
                                                name="position"
                                                placeholder="position"
                                                value={ this.state.faq.position }
                                                onChange={this.updateState.bind(this,'faq', 'position')}
                                            />
                                            <AvFeedback>This field is invalid</AvFeedback>
                                        </AvGroup>
                                    </AvForm>
                                    <AvForm>
                                        <AvGroup className="mb-3 col-lg-12">
                                            <Label for="germantitle">German title</Label>
                                            <AvInput
                                                name="germantitle"
                                                placeholder="germantitle"
                                                value={ this.state.faq.germanTitle }
                                                onChange={this.updateState.bind(this,'faq', 'germanTitle')}
                                            />
                                        </AvGroup>
                                    </AvForm>
                                    <AvForm>
                                        <AvGroup className="mb-3 col-lg-12">
                                            <Label for="germanText">German text</Label>
                                            <AvInput
                                                type="textarea"
                                                rows="10"
                                                name="germanText"
                                                placeholder="germanText"
                                                value={ this.state.faq.germanText }
                                                onChange={this.updateState.bind(this,'faq', 'germanText')}
                                            />
                                        </AvGroup>
                                    </AvForm>
                                    <AvForm>
                                        <AvGroup className="mb-3 col-lg-12">
                                            <Label for="englishTitle">English title</Label>
                                            <AvInput
                                                name="englishTitle"
                                                placeholder="englishTitle"
                                                value={ this.state.faq.englishTitle }
                                                onChange={this.updateState.bind(this,'faq', 'englishTitle')}
                                            />
                                        </AvGroup>
                                    </AvForm>
                                    <AvForm>
                                        <AvGroup className="mb-3 col-lg-12">
                                            <Label for="englishText">English text</Label>
                                            <AvInput
                                                type="textarea"
                                                rows="10"
                                                name="englishText"
                                                placeholder="English text"
                                                value={ this.state.faq.englishText }
                                                onChange={this.updateState.bind(this,'faq', 'englishText')}
                                            />
                                        </AvGroup>
                                    </AvForm>
                                    <span className="help-block">
                                      <a
                                          className="a-underline"
                                          href="https://github.github.com/gfm/"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                      >Markdown specification</a>
                                  </span>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="col-md-8 col-lg-6">
                            <Card>
                                <CardBody className="p-4">
                                    <div className="card-box">
                                        <h4 className="header-title mb-3">Markdown preview for german Text:</h4>
                                        <ReactMarkdown
                                            source={ this.state.faq.germanText }
                                            className="markdown"
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody className="p-4">
                                    <div className="card-box">
                                        <h4 className="header-title mb-3">Markdown preview for english Text:</h4>
                                        <ReactMarkdown
                                            source={ this.state.faq.englishText }
                                            className="markdown"
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <Card>
                                <CardBody>
                                    <Success success={this.state.success} successMessage={this.state.successMessage} />
                                    <Error
                                        isErrorCritical={this.state.isErrorCritical}
                                        errorMessage={this.state.errorMessage}
                                    />
                                    <div className="row">
                                        <div className="col-lg-6"/>
                                        <div className="col-lg-6">
                                            <Button
                                                onClick={() => {this.submit()}}
                                                color="primary"
                                                className="btn btn-success float-right">
                                                <i className="mdi mdi-label-l mdi-content-save" />
                                            </Button>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </PageRow>
            </>
        )
    }
}


export default AdminFaqAdd;
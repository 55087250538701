import React from 'react';

type CreditNoteLineItemProps = {
    description: string,
    clicks: number,
    cpc: string,
    amount: string,
    bold: boolean,
}

class CreditNoteLineItem extends React.Component<CreditNoteLineItemProps> {
    fontFormat(type:string) {
        if (this.props.bold === true) {
            if (type !== 'description') {
                return 'font-13 font-weight-bold d-flex justify-content-end'
            }
            return 'font-13 font-weight-bold '
        }

        if (type !== 'description') {
            return 'd-flex justify-content-end'
        }

        return 'font-13'
    }

    render() {
        return <>
            <div className="row">
                <div className="col-lg-12">
                    <div className="d-flex">
                        <div className="flex-direction-column cn-list-item-description">
                            <p className={this.fontFormat('description')}>{this.props.description}</p>
                        </div>
                        <div className="flex-direction-column cn-list-item-small">
                            <p className={this.fontFormat('clicks')}>{this.props.clicks}</p>
                        </div>
                        <div className="flex-direction-column cn-list-item">
                            <p className={this.fontFormat('cpc')}>{this.props.cpc}</p>
                        </div>
                        <div className="flex-direction-column cn-list-item">
                            <p className={this.fontFormat('amount')}>{this.props.amount}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}

export default CreditNoteLineItem;

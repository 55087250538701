import React from 'react';
import type { Project } from './DayStatistics';

type DropDownProps = {
  options: Array<string|Project>,
  name: string,
  updateList: Function,
  defaultValue: string,
  defaultName: string,
  selectedValue: string
}

class ObjectDropDown extends React.Component<DropDownProps> {
  updateParentState(inputRef) {
    this.props.updateList(this.props.name, inputRef.current.value);
  }

  render() {
    const inputRef = React.createRef();

    const optionElements = [];

    optionElements.push(<option key={'default'} value={this.props.defaultValue}>{this.props.defaultName}</option>);

    for (const optionKey in this.props.options) {
      if (!this.props.options.hasOwnProperty(optionKey)) {
        continue;
      }
      const optionValue = this.props.options[optionKey];
      optionElements.push(<option key={optionKey} value={optionKey}>{optionValue}</option>);
    }

    return (
        <select
          value={this.props.selectedValue}
          ref={inputRef}
          name={this.props.name}
          onChange={this.updateParentState.bind(this, inputRef)}
          className={'form-control'}
        >
          {optionElements}
        </select>
    );
  }
}

export default ObjectDropDown;

// @flow

import React from 'react';
import Ajax from '../../../Helper/Ajax';
import Error from '../../App/components/Messages/Error';
import ArrayDropDown from './ArrayDropDown';
import DateValue from '../../../Helper/DateValue';
import CsvExportButton from "./CsvExportButton";
import PageTitle from "../../Layout/PageTitle";
import PageRow from "../../Layout/PageRow";
import DataTable from 'react-data-table-component';
import HelpFunctions from "../../../Helper/HelpFunctions";
import PublisherStatisticInfo from "./PublisherStatisticInfo";
import {BrowserView} from "react-device-detect";

type Statistic = {
  accountId: string,
  publisherId: string,
  projectId: string[],
  year: number,
  month: number,
  day: number,
  market: string,
  clicks: number,
  revenue: string,
}

export type StatisticEntry = {
  date: string,
  clicks: string,
  revenue: string,
}

export type DayStatisticsStateRouteMatch = {
  isExact: true,
  params: {
    market: string,
    month: string,
    projectId: string,
    year: string,
  },
}

type DayStatisticsProps = {
  match: DayStatisticsStateRouteMatch
}

export type Project = {
  id: string,
  name: string,
}

export type DayStatisticsState = {
  statisticData: Array<StatisticEntry>,
  statisticsSum: StatisticEntry,
  markets: Array<string>,
  projects: Array<Project>,
  statistic: Statistic,
  isErrorCritical: boolean,
  errorMessage: string,
  statisticLoaded: boolean,
  market: string,
  projectId: string,
  year: string,
  month: string,
}

const columns = [
  {
    name: 'Date',
    selector: 'date',
    ignoreRowClick: true,
  },
  {
    name: 'Clicks',
    selector: 'clicks',
    cell: row => <span className={'float-right'}>{HelpFunctions.addCommas(row.clicks)}</span>,
    right: true,
    ignoreRowClick: true,
  },
  {
    name: 'Revenue',
    selector: 'revenue',
    cell: row => <span className={'float-right'}>{HelpFunctions.addRevenueSymbolAndNull(HelpFunctions.addCommas(row.revenue))}</span>,
    right: true,
    ignoreRowClick: true,
  }
];

class DayStatistics extends React.Component<DayStatisticsProps, DayStatisticsState> {
  currentYear = new Date().getFullYear();
  currentMonth = new Date().getMonth();

  state = {
    statisticData: [],
    statisticsSum: {},
    markets: [],
    projects: [],
    statistic: {},
    market: this.props.match.params.market || 'all',
    projectId: this.props.match.params.projectId || 'all',
    year: this.props.match.params.year || String(this.currentYear),
    month: this.props.match.params.month || String(this.currentMonth + 1),
    isErrorCritical: true,
    errorMessage: '',
    statisticLoaded: false,
  };

  loadStatisticFromApi(): void {
    const market = this.state.market;
    const projectId = this.state.projectId;
    const year = this.state.year;
    const month = this.state.month;

    const parameters = '?market=' + market + '&project=' + projectId + '&year=' + year + '&month=' + month;
    const url = process.env.REACT_APP_API_URL + `/day-statistics${parameters}`;
    const promise: Promise<string> = Ajax.createGetXhr(url);

    this.setState({
      statisticLoaded: false,
      statisticData: [],
      statisticsSum: {},
    }, () => {
      promise.then(response => {
        const parsedResponse = JSON.parse(response);

        if (parsedResponse.errorMessage || parsedResponse.warningMessage) {
          this.setState({
            isErrorCritical: !parsedResponse.success,
            errorMessage: parsedResponse.errorMessage || parsedResponse.warningMessage,
            statisticLoaded: false,
          });
          return;
        }

        this.setState({
          statisticData: parsedResponse.data.statistics,
          statisticsSum: parsedResponse.data.statisticsSum,
          markets: parsedResponse.data.markets,
          projects: parsedResponse.data.projects,
          errorMessage: '',
          statisticLoaded: true,
        });
      }).catch(error => {
        console.error(error);
        this.setState({
          errorMessage: 'An unexpected error occured. Please try again later.',
        });
      });
    });
  }

  updateList(filterName: string, value: string) {
    this.setState({
      [filterName]: value,
    }, () => {
      let newUrl = window.location.protocol + '//' + window.location.host + '/statistics';

      const paramList = {
        market: this.state.market,
        projectId: this.state.projectId,
        year: this.state.year,
        month: this.state.month,
      };

      paramList[filterName] = value;
      newUrl += '/' + paramList.year + '/' + paramList.month + '/' + paramList.projectId + '/' + paramList.market;

      // Wenn einer weiß wie die nicht global version der history ist, bitte ändern.
      // eslint-disable-next-line
      history.pushState({url: newUrl}, 'Statistic Page', newUrl);

      this.loadStatisticFromApi();
    });
  }

  componentDidMount() {
    this.loadStatisticFromApi();
  }

  getCsvExportParameters() {
    return {
      market: this.state.market,
      project: this.state.projectId,
      year: this.state.year,
      month: this.state.month,
    };
  }

  changeState(paritalState: Object) {
    this.setState({...paritalState})
  }

  render() {
    const years = [];
    const targetYear = 2017;
    for (let i = this.currentYear - 1; i >= targetYear; i--) {
      years.push(i);
    }

    const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const projectIds = this.state.projects;
    const markets = this.state.markets;

    return (
      <>
        <PageTitle
          title="Day statistics"
          breadcrumbs={[
            {
              link: '/dashboard',
              name: 'Dashboard',
            },
            {
              name: 'Day statistics',
            },
          ]}
        />
        <PageRow>
          <div className="card">
            <div className="card-body">
              <div id="datatable-buttons_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                <div className="row">
                  <div className="col-sm-2 col-md-2">
                    <div id="datatable-buttons_filter" className="dataTables_filter">
                      <label>Year:</label>
                      <ArrayDropDown
                        name="year"
                        options={years}
                        updateList={this.updateList.bind(this)}
                        defaultValue={`${DateValue.now('year')}`}
                        selectedValue={this.state.year}
                        defaultName={`current year (${DateValue.now('year')})`}
                        disabled={false}
                      />
                    </div>
                  </div>
                  <div className="col-sm-2 col-md-2">
                    <div id="datatable-buttons_filter" className="dataTables_filter">
                      <label>Month:</label>
                      <ArrayDropDown
                        name="month"
                        options={months}
                        updateList={this.updateList.bind(this)}
                        defaultValue={`${DateValue.now('month')}`}
                        selectedValue={this.state.month}
                        defaultName={`current month (${DateValue.now('month')})`}
                        disabled={false}
                      />
                    </div>
                  </div>
                  <div className="col-sm-2 col-md-2">
                    <div id="datatable-buttons_filter" className="dataTables_filter">
                      <label>Project:</label>
                      <ArrayDropDown
                        name="projectId"
                        options={projectIds}
                        updateList={this.updateList.bind(this)}
                        defaultValue="all"
                        defaultName="all projects"
                        selectedValue={this.state.projectId}
                        disabled={false}
                      />
                    </div>
                  </div>
                  <div className="col-sm-2 col-md-2">
                    <div id="datatable-buttons_filter" className="dataTables_filter">
                      <label>Market:</label>
                      <ArrayDropDown
                        name="market"
                        options={markets}
                        updateList={this.updateList.bind(this)}
                        defaultValue="all"
                        defaultName="all markets"
                        selectedValue={this.state.market}
                        disabled={false}
                      />
                    </div>
                  </div>
                  <div className="col-sm-2 col-md-2">
                  </div>
                  <div className="col-sm-2 col-md-2 filter_button">
                    <div className="dt-buttons btn-group">
                      <BrowserView>
                        <CsvExportButton
                          getParametersFunction={this.getCsvExportParameters.bind(this)}
                          csvControllerUrl={process.env.REACT_APP_API_URL + '/dayStatisticsCsvExport'}
                          exportFilename="day-statistics-export"
                          changeState={this.changeState.bind(this)}
                          warningMessage={''}
                        />
                      </BrowserView>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="table-responsive">
                    <DataTable
                      columns={columns}
                      data={this.state.statisticData}
                      progressPending={!this.state.statisticLoaded}
                      highlightOnHover={true}
                      responsive={true}
                      noHeader={true}
                    />
                    { this.renderStatisticsSum() }
                  </div>
                </div>
              </div>
              <Error
                isErrorCritical={this.state.isErrorCritical}
                errorMessage={this.state.errorMessage}
              />
            </div>
          </div>
        </PageRow>
        <PublisherStatisticInfo/>
      </>
    );
  }

  renderStatisticsSum() {
    if (this.state.statisticLoaded && this.state.statisticsSum.clicks !== 0 && this.state.statisticsSum.revenue !== 0) {
      return (
        <>
          <div className="rdt_TableHead sc-bwzfXH gXdTHx daystatisticssum-footer">
            <div className="rdt_TableHeadRow sc-bxivhb bojDWV dt-custom-line">
              <hr/>
            </div>
          </div>

          <div className="sc-iwsKbI jHIgX rdt_TableRow">
            <div id="column-date" className="sc-bxivhb sc-ifAKCX sc-EHOje hBLBhZ rdt_TableCell">
              <div className="statistic-sum"/>
            </div>
            <div id="column-clicks" className="sc-bxivhb sc-ifAKCX sc-EHOje hBLBhZ rdt_TableCell">
              <div className="statistic-sum float-right">{(this.state.statisticsSum.clicks + '').replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
            </div>
            <div id="column-revenue" className="sc-bxivhb sc-ifAKCX sc-EHOje hBLBhZ rdt_TableCell">
              <div className="statistic-sum float-right">{(this.state.statisticsSum.revenue + '').replace(/\B(?=(\d{3})+(?!\d))/g, ",")} €</div>
            </div>
          </div>
        </>
      );
    }
  }
}

export default DayStatistics;

class BlobDownloadHelper {
    /**
     * Returns current date as string in the format YYYY-MM-DD-HH-MM-SS
     * @return {string}
     */
    static getDatePrefix() {
        const date = new Date();
        const datePrefix = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + (date.getDate()) +
            '-' + date.getHours() + '-' + date.getMinutes() + '-' + date.getSeconds();
        return datePrefix;
    }

    /**
     * Downloads a blob as a file
     * @param {Blob} blobData
     * @param {string} filename
     * @param {string} type
     */
    static downloadBlob(blobData: string, filename: string, type: string = 'text/csv') {
        const blob = new Blob([blobData], {type: type});
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
        link.remove();
        window.URL.revokeObjectURL(link.href);
    }
}

export default BlobDownloadHelper;

// @flow

import IsEmail from '../../../Helper/Validator/IsEmail';
import StringLength from '../../../Helper/Validator/StringLength';

class EmailValidator {
  static errorMessages = [];
  static isValid       = true;

  static emailMinLength = 5;
  static emailMaxLength = 255;

  static validate(email: string): boolean {
    const isEmail      = new IsEmail();
    const stringLength = new StringLength('greater');
    this.errorMessages = [];
    this.isValid       = true;

    stringLength.setMode('less');
    stringLength.setLength(this.emailMinLength);

    if (stringLength.isValid(email)) {
      this.errorMessages.push(`The e-mail address has a minimum length of ${this.emailMinLength} characters.`);
      this.isValid = false;
    }

    stringLength.setMode('greater');
    stringLength.setLength(this.emailMaxLength);

    if (stringLength.isValid(email)) {
      this.errorMessages.push(`The e-mail address has a maximum length of ${this.emailMaxLength} characters.`);
      this.isValid = false;
    }

    if (email !== '' && !isEmail.isValid(email)) {
      this.errorMessages.push('The e-mail address has no valid format.');
      this.isValid = false;
    }
    return this.isValid;
  }

  static getErrorMessages() {
    return this.errorMessages;
  }
}

export default EmailValidator;

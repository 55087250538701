// @flow

import React from 'react';
import Ajax from '../../../Helper/Ajax';
import Error from '../../App/components/Messages/Error';
import PageTitle from '../../Layout/PageTitle';
import DataTable from 'react-data-table-component';
import Search from '../../Publisher/components/Search';
import {FormGroup} from 'reactstrap';
import PageRow from '../../Layout/PageRow';
import Loader from '../../ReactLayout/Components/Loader';
import Restricted from "../../../Helper/PermissionProvider/Restricted";
import {userGroup} from "../../../Helper/UserGroups";

type Account = {
  accountId: string,
  publisherId: string,
  publisherName: string,
  emailAddress: string,
  projectNames: string[],
  customerPublisherNexusId: string,
}

type AccountListState = {
  accounts: Account,
  isErrorCritical: boolean,
  errorMessage: string,
  accountsLoaded: boolean,
  searchTerm: string,
}

class AccountList extends React.Component<{}, AccountListState> {
  state: AccountListState = {
    accounts: [],
    searchTerm: '',
    isErrorCritical: true,
    errorMessage: '',
    accountsLoaded: false,
  };

  columns = [
    {
      name: 'Customer ID',
      selector: 'customerPublisherNexusId',
      ignoreRowClick: true,
      sortable: true,
    },
    {
      name: 'Publisher ID',
      selector: 'publisherId',
      ignoreRowClick: true,
    },
    {
      name: 'Publisher name',
      selector: 'publisherName',
      sortable: true,
      ignoreRowClick: true,
    },
    {
      name: 'E-mail address',
      selector: 'emailAddress',
      sortable: true,
      ignoreRowClick: true,
    },
    {
      name: 'Projects',
      selector: 'projectNames',
      sortable: true,
      cell: (account: Account) => AccountList.formatProjects(account),
      ignoreRowClick: true,
    },
    {
      name: 'Action',
      selector: 'impersonate',
      cell: (account: Account) => this.actionButtons(account),
      ignoreRowClick: true,
    },
  ];

  actionButtons(account: Account) {
    let accountButton = '';
    if (account.emailAddress || account.emailAddress !== '') {
       accountButton = <button
        onClick={this.impersonate.bind(this, account.accountId, account.publisherName)}
        type="button"
        className="btn btn-xs btn-outline-blue acc-nav margin-right"
        title={'Impersonate ' + account.publisherName}
      >
        <i className="mdi mdi-large mdi-account-switch"/>
      </button>;
    }

    let settingsButton = <button
      onClick={() => {
        window.location.href = `/admin/account-settings?pid=${account.publisherId}`;
      }}
      type="button"
      className="btn btn-xs btn-outline-secondary acc-nav margin-right"
      title={'Account settings'}
    >
      <i className="mdi mdi-large mdi-settings"/>
    </button>;

    let accountButtonAdd = '';
    if (!account.emailAddress || account.emailAddress === '') {
      accountButtonAdd = <button
        onClick={() => {
          window.location.href = '/admin/account?pid=' + account.publisherId;
        }}
        type="button"
        className="btn btn-xs btn-outline-success acc-nav margin-right"
        title={'Add account for ' + account.publisherName}
      >
        <i className="mdi mdi-large mdi-account-plus"/>
      </button>;

      settingsButton = '';
    }

    return <FormGroup className="account-action-buttons">
      <Restricted to={'accounts.impersonate'} loadingComponent={<Loader/>}>
        {accountButton}
      </Restricted>
      <Restricted to={'account.create'}>
        {accountButtonAdd}
      </Restricted>
      <button
        onClick={() => {
          window.location.href = '/admin/customer-information?pid=' + account.publisherId;
        }}
        type="button"
        className="btn btn-xs btn-outline-warning float-left margin-right color-white"
        title={'Edit customer information'}
      >
        <i className="mdi mdi-large mdi-account-card-details"/>
      </button>
      {settingsButton}
    </FormGroup>;
  }

  static formatProjects(account: Account) {
    return Object.values(JSON.parse(account.projectNames)).map((project, key) => (
      <div key={key}>{project}<br/></div>
    ));
  }

  loadAccountsFromApi(): void {
    if (this.state.accountsLoaded || this.state.errorMessage !== '') {
      return;
    }
    const postData = {
      searchTerm: this.state.searchTerm,
    };

    const promise: Promise<string> = Ajax.createPostXhr(process.env.REACT_APP_API_URL + '/account/list', postData);

    this.setState({accountsLoaded: true});

    promise.then(response => {
      const parsedResponse = JSON.parse(response);

      if (parsedResponse.errorMessage || parsedResponse.warningMessage) {
        this.setState({
          isErrorCritical: !parsedResponse.success,
          errorMessage: parsedResponse.errorMessage || parsedResponse.warningMessage,
        });
        return;
      }

      this.setState({
        accounts: parsedResponse.data,
        errorMessage: '',
      });
    }).catch(error => {
      console.error(error);
      this.setState({
        errorMessage: 'An unexpected error occured. Please try again later.',
      });
    });
  }

  impersonate(accountId: string, publisherName: string): void {
    const promise: Promise<string> = Ajax.createPostXhr(
      process.env.REACT_APP_API_URL + '/admin/impersonate',
      {accountId}
    );

    promise.then(response => {
      const parsedResponse = JSON.parse(response);

      if (parsedResponse.errorMessage || parsedResponse.warningMessage) {
        this.setState({
          isErrorCritical: !parsedResponse.success,
          errorMessage: parsedResponse.errorMessage || parsedResponse.warningMessage,
        });
        return;
      }
      localStorage.setItem('impersonating', publisherName);
      localStorage.setItem('userGroup', userGroup.PUBLISHER)

      window.location.href = '/dashboard';
    }).catch(error => {
      console.error(error);
      this.setState({
        errorMessage: 'An unexpected error occured. Please try again later.',
      });
    });
  }

  updateSearchValue(searchTerm: string): void {
    this.setState({
      searchTerm: searchTerm,
      accountsLoaded: false,
    }, () => {
      this.loadAccountsFromApi();
    });
  }

  componentDidMount(): void {
    this.loadAccountsFromApi();
  }

  render() {
    return (
        <>
          <PageTitle
              title="Account list"
              breadcrumbs={[
                {
                  link: '/admin/dashboard',
                  name: 'Dashboard',
                },
                {
                  name: 'Account list',
                },
              ]}
          />
          <PageRow>
            <div className="card">
              <div className="card-body">
                <div id="datatable-buttons_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                  <div className="row">
                    <div className="col-sm-2 col-md-2">
                      <div id="datatable-buttons_filter" className="dataTables_filter">
                        <label>Search:</label>
                        <Search
                          updateSearchValue={this.updateSearchValue.bind(this)}
                          minLength={0}
                          initialSearchTerm={''}
                        />
                      </div>
                    </div>
                    <div className="col-sm-2 col-md-2 filter_button margin-left-auto">
                      <button
                        className="btn btn-secondary buttons-csv buttons-html5 margin-left-auto"
                        type="button"
                        name="Bank information"
                        onClick={() => {
                          window.location.href = '/admin/bank-information'
                        }}
                      >
                        <span className="float-left">Bank information</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="table-responsive">
                      <DataTable
                        columns={this.columns}
                        data={this.state.accounts}
                        paginationPerPage={25}
                        pagination={true}
                        paginationDefaultPage={1}
                        paginationRowsPerPageOptions={[25, 50, 100]}
                        highlightOnHover={true}
                        responsive={true}
                        noHeader={true}
                        defaultSortField={'customerPublisherNexusId'}
                        defaultSortAsc={true}
                        paginationResetDefaultPage={true}
                      />
                    </div>
                  </div>
                </div>
                <Error
                    isErrorCritical={this.state.isErrorCritical}
                    errorMessage={this.state.errorMessage}
                />
              </div>
            </div>
          </PageRow>
        </>
    );
  }
}

export default AccountList;

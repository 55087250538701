// @flow

import type { ValidatorInterface } from './ValidatorInterface';
import type { ValidatorOptions } from './IsFloat';

class IsEmail implements ValidatorInterface {
  setOptions(options: ValidatorOptions) {
    return this;
  }

  isValid(value: string): boolean {
    // eslint-disable-next-line no-useless-escape
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(value);
  }
}

export default IsEmail;

import React from "react";

class Backend extends React.Component {
  render() {
    return (
      <div>Backend</div>
    );
  }
}

export default Backend;

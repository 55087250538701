import React from 'react';

export type BankInformation = {
  accountOwner: string,
  bankName: string,
  bic: string,
  countryName: string,
  customerPublisherNexusId: string,
  emailAddress: string,
  iban: string,
  publisherId: string,
  vat: string,
}

type BankViewTableProps = {
  bankInformations: BankInformation[],
}

class BankViewTable extends React.Component<BankViewTableProps> {
  columns = ['Customer ID', 'VAT', 'E-mail address', 'Country', 'Bank name', 'IBAN', 'BIC', 'Account owner', 'Actions'];

  renderRow(bankInformation: BankInformation, index: number) {
    return <tr className="rdt_TableRow" key={index}>
      <td className="react-data-table--cell-content">{bankInformation.customerPublisherNexusId}</td>
      <td className="react-data-table--cell-content">{bankInformation.vat}</td>
      <td className="react-data-table--cell-content">{bankInformation.emailAddress}</td>
      <td className="react-data-table--cell-content">{bankInformation.countryName}</td>
      <td className="react-data-table--cell-content">{bankInformation.bankName}</td>
      <td className="react-data-table--cell-content">{bankInformation.iban}</td>
      <td className="react-data-table--cell-content">{bankInformation.bic}</td>
      <td className="react-data-table--cell-content">{bankInformation.accountOwner}</td>
      <td className="react-data-table--cell-content">
        <button
          onClick={() => { window.location.href = '/admin/customer-information?pid=' + bankInformation.publisherId; }}
          type="button"
          className="btn btn-xs btn-outline-warning float-left margin-right color-white"
          title={'Edit customer information'}
        >
          <i className="mdi mdi-large mdi-account-card-details" />
        </button>
      </td>
    </tr>;
  }

  render() {
    return(
      <>
        <div>
          <table className="bankInformation-table">
            <tbody>
              <tr>
                {this.columns.map((columnTitle) => <th key={columnTitle}>{columnTitle}</th>)}
              </tr>
              {
                this.props.bankInformations.map(
                  (bankInformation: BankInformation, index) => this.renderRow(bankInformation, index)
                )
              }
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default BankViewTable;

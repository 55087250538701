import React from 'react';
import {AvFeedback, AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import {FormGroup, Label} from 'reactstrap';
import Success from '../../../App/components/Messages/Success';
import Error from '../../../App/components/Messages/Error';
import Ajax from '../../../../Helper/Ajax';

type ChangePasswordState = {
    currentPassword: string,
    newPassword: string,
    newPasswordRepitition: string,
    success: boolean,
    successMessage: string,
    isErrorCritical: boolean,
    errorMessage: string,
}

class ChangePassword extends React.Component<{}, ChangePasswordState> {
    state: ChangePasswordState = {
        currentPassword: '',
        newPassword: '',
        newPasswordRepitition: '',
        success: false,
        successMessage: '',
        isErrorCritical: true,
        errorMessage: '',
    };
    
    submit(e: Event) {
        e.preventDefault();

        const postData = {
            currentPassword: this.state.currentPassword,
            newPassword: this.state.newPassword,
            newPasswordRepitition: this.state.newPasswordRepitition,
        };

        const promise = Ajax.createPostXhr(process.env.REACT_APP_API_URL + '/change-password', postData);

        promise.then(response => {
            const parsedResponse = JSON.parse(response);

            if (parsedResponse.errorMessage || parsedResponse.warningMessage) {
                this.setState({
                    success: false,
                    isErrorCritical: !parsedResponse.success,
                    errorMessage: parsedResponse.errorMessage || parsedResponse.warningMessage,
                });
                return;
            }

            this.setState({
                ...this.state,
                errorMessage: '',
                success: true,
                successMessage: 'Your password was changed successfully.'
            });
        }).catch(error => {
            console.error(error);
            this.setState({
                ...this.state,
                isErrorCritical: true,
                errorMessage: 'An unexpected error occured. Please try again later.',
            });
        });
    }

    render() {
        return (
            <>
                <div className="col-md-6">
                    <div className="card-box">

                        <AvForm onValidSubmit={this.submit.bind(this)}>

                            <AvGroup className="mb-3">
                                <Label for="currentPassword">Current password</Label>
                                <AvInput
                                    className={'form-control'}
                                    placeholder={ 'Type in your current password...' }
                                    type={ 'password' }
                                    value={ this.state.currentPassword }
                                    onChange={ e => this.setState({ currentPassword: e.target.value }) }
                                    name={"currentPassword"}
                                />
                                <AvFeedback>This field is invalid</AvFeedback>
                            </AvGroup>
                            <AvGroup className="mb-3">
                                <Label for="newPassword">New password</Label>
                                <AvInput
                                    className={'form-control'}
                                    placeholder={ 'Type in your new password...' }
                                    type={ 'password' }
                                    value={ this.state.newPassword }
                                    onChange={ e => this.setState({ newPassword: e.target.value }) }
                                    name={"newPassword"}
                                />
                                <AvFeedback>This field is invalid</AvFeedback>
                            </AvGroup>
                            <AvGroup className="mb-3">
                                <Label for="newPasswordRepitition">Repeat new password</Label>
                                <AvInput
                                    className={'form-control'}
                                    placeholder={ 'Repeat your new password...' }
                                    type={ 'password' }
                                    value={ this.state.newPasswordRepitition }
                                    onChange={ e => this.setState({ newPasswordRepitition: e.target.value }) }
                                    name={"newPasswordRepitition"}
                                />
                                <AvFeedback>This field is invalid</AvFeedback>
                            </AvGroup>

                            <FormGroup>
                                <button
                                    color="primary"
                                    onClick={this.submit.bind(this)}
                                    className={'btn btn-primary btn-block'}
                                >
                                    Change password
                                </button>
                            </FormGroup>

                            <Success
                                success={ this.state.success }
                                successMessage={ this.state.successMessage }
                            />
                            <Error
                                isErrorCritical={ this.state.isErrorCritical }
                                errorMessage={ this.state.errorMessage }
                            />

                        </AvForm>
                    </div>
                </div>
            </>
        );
    }
}

export default ChangePassword;

import React from 'react';
import Ajax from "../../../../Helper/Ajax";
import HelpFunctions from "../../../../Helper/HelpFunctions";
import {FormGroup} from "reactstrap";
import DownloadPublisherCreditNotePdfButton from "./DownloadPublisherCreditNotePdfButton";
import PageRow from "../../../Layout/PageRow";
import DataTable from "react-data-table-component";
import { BrowserView, MobileView } from "react-device-detect";

type LastCreditNote = {
    id: string,
    total: string,
    dateFrom: string,
}
type PublisherLastCreditNoteState = {
    lastCreditNote: LastCreditNote[],
    isErrorCritical: boolean,
    errorCode: number,
    errorMessage: string,
    loadingFromApi: false
}

class PublisherLastCreditNote extends React.Component<{}, PublisherLastCreditNoteState> {
    state = {
        lastCreditNote: [],
        isErrorCritical: true,
        errorCode: 0,
        errorMessage: '',
    };

    columns = [
        {
            name: 'Month',
            selector: 'accountingPeriodCount',
            ignoreRowClick: true,
            cell: (lastCreditNote: LastCreditNote)=>
                HelpFunctions.getFullMonthName(lastCreditNote.dateFrom)
                + ' ' + new Date(lastCreditNote.dateFrom).getFullYear(),
            grow: 0.4,
            sortable: true,
            sortFunction: (rowA: LastCreditNote, rowB: LastCreditNote) => {
                const timestampA = new Date(rowA.dateFrom).getTime();
                const timestampB = new Date(rowB.dateFrom).getTime();

                return timestampA - timestampB;
            },
        },
        {
            name: 'Total',
            selector: 'total',
            ignoreRowClick: true,
            cell: (lastCreditNote: LastCreditNote)=>
                HelpFunctions.addCommas(lastCreditNote.total) + ' €',
            grow: 0.25,
            sortable: true,
            sortFunction: (rowA: LastCreditNote, rowB: LastCreditNote) => {
                const totalA = parseFloat(rowA.total);
                const totalB = parseFloat(rowB.total);

                return totalA - totalB;
            },
        },
        {
            name: 'Actions',
            selector: '',
            ignoreRowClick: true,
            cell:(lastCreditNote: LastCreditNote) => this.actionButtons(lastCreditNote),
            grow: 0.35,
        },
    ];

    actionButtons(lastCreditNote: LastCreditNote) {
        return <FormGroup className="account-action-buttons">
            <BrowserView>
                <button
                    onClick={() => {
                        window.location.href = '/credit-notes';
                    }}
                    type="button"
                    className="btn btn-xs btn-outline-blue margin-right color-white"
                    title={'View all credit notes'}
                >
                    <i className="mdi mdi-large mdi-file-search-outline"/>
                </button>
                <DownloadPublisherCreditNotePdfButton
                    creditNoteId={ lastCreditNote.id }
                    changeState={this.changeState.bind(this)}
                    openPdf={false}
                />
            </BrowserView>
            <MobileView>
                <button
                    onClick={() => {
                        window.location.href = '/credit-notes';
                    }}
                    type="button"
                    className="btn btn-xs btn-outline-blue margin-right color-white"
                    title={'View all credit notes'}
                >
                    <i className="mdi mdi-large mdi-file-search-outline"/>
                </button>
                <DownloadPublisherCreditNotePdfButton
                    creditNoteId={ lastCreditNote.id }
                    changeState={this.changeState.bind(this)}
                    openPdf={true}
                />
            </MobileView>
        </FormGroup>;
    };

    loadFromApi(): void {
        this.setState({
            loadingFromApi: true,
        },() => {
            const url = process.env.REACT_APP_API_URL + `/last-credit-note`;
            const promise: Promise<string> = Ajax.createGetXhr(url);

            promise.then(response => {
                const parsedResponse = JSON.parse(response);
                if (parsedResponse.errorMessage || parsedResponse.warningMessage) {
                    this.setState({
                        isErrorCritical: !parsedResponse.success,
                        errorMessage: parsedResponse.errorMessage || parsedResponse.warningMessage,
                    });
                    return;
                }
                this.setState({
                    lastCreditNote: parsedResponse.data,
                    loadingFromApi:false,
                    errorCode: 0,
                });
            }).catch(error => {
                console.error(error);
                this.setState({
                    errorCode: 500,
                    errorMessage: 'An unexpected error occured. Please try again later.',
                });
            });
        });
    }

    changeState(paritalState: Object) {
        this.setState({...paritalState})
    };

    componentDidMount(): void {
        this.loadFromApi();
    }

    render() {
        return(
            <>
                <PageRow>
                    <div className="card" id="main">
                        <div className="card-body">
                            <div id="datatable-buttons_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="table-responsive">
                                            <label>Last credit note:</label>
                                            <div className="cn-dashboard">
                                                <DataTable
                                                    columns={this.columns}
                                                    data={this.state.lastCreditNote}
                                                    highlightOnHover={true}
                                                    responsive={true}
                                                    noHeader={true}
                                                    defaultSortField={'accountingPeriod'}
                                                    defaultSortAsc={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </PageRow>
            </>
        )
    };
}

export default PublisherLastCreditNote;

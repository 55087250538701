import React from 'react';
import Ajax from '../../../../Helper/Ajax';
import Error from '../../../App/components/Messages/Error';
import DataTable from 'react-data-table-component';
import HelpFunctions from "../../../../Helper/HelpFunctions";
import PageTitle from '../../../Layout/PageTitle';
import PageRow from '../../../Layout/PageRow';
import ReactModal from 'react-modal';
import {FormGroup} from 'reactstrap';
import GenerateCreditNoteCollectionModal from './GenerateCreditNoteCollectionModal';
import DownloadCreditNoteCollectionPdfButton from './DownloadCreditNoteCollectionPdfButton';
import Restricted from "../../../../Helper/PermissionProvider/Restricted";

type CreditNoteCollection = {
    id: number,
    accountingPeriodFrom: string,
    accountingPeriodTo: string,
    totalCreditNoteCount: number,
    unprocessedCreditNoteCount: number,
    workflowStatus: number,
}

type CreditNoteCollectionListState = {
    creditNoteCollections: CreditNoteCollection[],
    canCloseModal: boolean,
    isModalOpen: boolean,
    isErrorCritical: boolean,
    errorCode: number,
    errorMessage: string,
}

class CreditNoteCollectionList extends React.Component<{}, CreditNoteCollectionListState> {
    intervalID;

    state: CreditNoteCollectionListState = {
        creditNoteCollections: [],
        canCloseModal: true,
        isModalOpen: false,
        isErrorCritical: true,
        errorCode: 0,
        errorMessage: '',
    };

    columns = [
        {
            name: 'Month',
            selector: 'accountingPeriodCount',
            ignoreRowClick: true,
            cell: (creditNoteCollection: CreditNoteCollection)=>
                HelpFunctions.getFullMonthName(creditNoteCollection.accountingPeriodFrom)
                + ' ' + new Date(creditNoteCollection.accountingPeriodFrom).getFullYear(),
            grow: 0.7,
        },
        {
            name: 'Total credit notes',
            selector: 'totalCreditNoteCount',
            ignoreRowClick: true,
            sortable: true,
            grow: 0.3,
        },
        {
            name: 'Unprocessed credit notes',
            selector: 'unprocessedCreditNoteCount',
            ignoreRowClick: true,
            sortable: true,
            grow: 0.3,
        },
        {
            name: ' Payment pending',
            selector: 'paymentPendingCount',
            ignoreRowClick: true,
            sortable: true,
            grow: 0.3,
        },
        {
            name: 'Action',
            selector: '',
            ignoreRowClick: true,
            cell:(creditNoteCollection: CreditNoteCollection) => this.actionButtons(creditNoteCollection),
            grow: 0.7,
        }
    ];

    changeState(paritalState: Object) {
        this.setState({...paritalState})
    }

    actionButtons(creditNoteCollection: CreditNoteCollection) {
        if (creditNoteCollection.workflowStatus !== 2) {
            return <>
                <i className="mdi mdi-large mdi-spin mdi-loading"/>
                <p className="credit-note-progress">In progress</p>
            </>
        }

        return <FormGroup className="account-action-buttons">
            <button
                onClick={() => {
                    window.location.href = '/admin/credit-notes/collection/' + creditNoteCollection.id;
                }}
                type="button"
                className="btn btn-xs btn-outline-blue margin-right color-white"
                title={'Details'}
            >
                <i className="mdi mdi-large mdi-file-search-outline"/>
            </button>
            <DownloadCreditNoteCollectionPdfButton
              creditNoteCollectionId={ creditNoteCollection.id }
              changeState={this.changeState.bind(this)}
            />
        </FormGroup>;
    };

    loadCreditNotesCollectionFromApi = () => {
         const promise: Promise<string> = Ajax.createGetXhr(
             process.env.REACT_APP_API_URL + '/admin/credit-note-collections/list'
         );

        promise.then(response => {
            const parsedResponse = JSON.parse(response);

            if (parsedResponse.errorMessage || parsedResponse.warningMessage) {
                this.setState({
                    isErrorCritical: !parsedResponse.success,
                    errorMessage: parsedResponse.errorMessage || parsedResponse.warningMessage,
                });
                return;
            }

            let list = [];

            if (parsedResponse.data) {
                list = parsedResponse.data;
            }

            this.setState({
                creditNoteCollections: list,
                errorCode: 0,
            });

            this.intervalID = setTimeout(this.loadCreditNotesCollectionFromApi.bind(this), 5000);
        }).catch(error => {
            console.error(error);
            this.setState({
                errorCode: 500,
                errorMessage: 'An unexpected error occured. Please try again later.',
            });
        });
    };

    updateCloseModalStatus(canCloseModal: boolean) {
        this.setState({
            canCloseModal: canCloseModal,
        });
    }

    componentDidMount(): void {
        this.loadCreditNotesCollectionFromApi();
        ReactModal.setAppElement('body');
    };

    openModal() {
        this.setState({
            isModalOpen: true
        });
    }

    closeModal() {
        if(this.state.canCloseModal) {
            this.setState({
                isModalOpen: false
            });
        }
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    render() {
        return(
            <>
                <PageTitle
                    title="Credit notes"
                    breadcrumbs={[
                        {
                            link: '/admin/dashboard',
                            name: 'Dashboard',
                        },
                        {
                            name: 'Credit notes',
                        },
                    ]}
                />
                <div className="card" id="main">
                    <div className="card-body">
                        <div id="datatable-buttons_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                            <div className="row">
                                <div className="col-sm-2 col-md-2 filter_button margin-left-auto">
                                    <Restricted to={'credit_note.write'}>
                                        <button
                                            className="btn btn-secondary margin-left-auto"
                                            type="button"
                                            name="Generate credit notes"
                                            onClick= {() => this.openModal()}
                                        >
                                            <span className="float-left">Generate credit notes</span>
                                        </button>
                                    </Restricted>
                                    <ReactModal
                                        isOpen={this.state.isModalOpen}
                                        contentLabel='generate-credit-notes'
                                        onRequestClose={this.closeModal.bind(this)}
                                        className="generate-credit-notes-modal"
                                        shouldCloseOnOverlayClick={this.state.canCloseModal}
                                        shouldCloseOnEsc={this.state.canCloseModal}
                                    >
                                        <GenerateCreditNoteCollectionModal
                                            closeModal={this.closeModal.bind(this)}
                                            updateCloseModalStatus={this.updateCloseModalStatus.bind(this)}
                                        />
                                    </ReactModal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <PageRow>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="table-responsive">
                                        <DataTable
                                            columns={this.columns}
                                            data={this.state.creditNoteCollections}
                                            paginationPerPage={25}
                                            pagination={true}
                                            paginationRowsPerPageOptions={[25, 50, 100]}
                                            highlightOnHover={true}
                                            responsive={true}
                                            noHeader={true}
                                            defaultSortField={'accountingPeriod'}
                                            defaultSortAsc={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <Error
                                isErrorCritical={this.state.isErrorCritical}
                                errorMessage={this.state.errorMessage}
                            />
                        </div>
                    </div>
                </PageRow>
            </>
        );
    }
}

export default CreditNoteCollectionList;

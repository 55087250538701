import React from 'react';
import Restricted from "../../../../Helper/PermissionProvider/Restricted";

type CreditNoteLineItemDeleteProps = {
    creditNoteAction: Function,
    creditNoteAggregateStatus: number,
    description: string,
    clicks: number,
    cpc: string,
    amount: string,
    lineItemId: string,
    bold: boolean,
}

class CreditNoteLineItemDelete extends React.Component<CreditNoteLineItemDeleteProps> {
    fontFormat(type:string) {
        if (this.props.bold === true) {
            return 'font-13 font-weight-bold '
        }

        if (type === 'amount') {
            return 'd-flex justify-content-end'
        }

        return 'font-13'
    }

    renderDeleteButton() {
        if (this.props.creditNoteAggregateStatus !== 1) {
            return '';
        }

        return <div>
            <button
                className="btn btn-xs btn-outline-danger color-white"
                type="submit"
                name="delete"
                onClick= {() => this.props.creditNoteAction(
                    '/admin/delete-credit-note-line-item',
                    {creditNoteLineItemId: this.props.lineItemId},
                )}
                title={'delete'}
            >
                <i className="mdi mdi-minus"/>
            </button>
        </div>
    }

    render() {
        return <>
            <div className="row">
                <div className="col-lg-12">
                    <div className="d-flex">
                        <div className="flex-direction-column cn-list-item-description">
                            <p className={this.fontFormat('description')}>{this.props.description}</p>
                        </div>
                        <div className="flex-direction-column cn-list-item-small">
                        </div>
                        <div className="flex-direction-column cn-list-item">
                        </div>
                        <div className="flex-direction-column cn-list-item">
                            <p className={this.fontFormat('amount')}>{this.props.amount}</p>
                        </div>
                        <Restricted to={'credit_note.write'}>
                            {this.renderDeleteButton()}
                        </Restricted>
                    </div>
                </div>
            </div>
        </>
    }
}

export default CreditNoteLineItemDelete;

import React from 'react';
import Statistics from './Statistics';
import PageTitle from '../../Layout/PageTitle';
import PageRow from '../../Layout/PageRow';
import PublisherStatisticInfo from "./PublisherStatisticInfo";
// import NewsList from './News/NewsList';
import NewMerchantsList from './Merchant/NewMerchantsList';
import { isMobile} from "react-device-detect";
import PublisherLastCreditNote from "./CreditNotes/PublisherLastCreditNote";
import Restricted from "../../../Helper/PermissionProvider/Restricted";

class Dashboard extends React.Component<> {
    // static newsListOnClickHandler(newsId: string, e: Event) {
    //     e.preventDefault();
    //
    //     window.location.href = window.location.protocol + '//' + window.location.host + '/news/' + newsId;
    // }

    static renderCol() {
        if (isMobile) {
            return 'col-md-12';
        }

        return 'col-md-6';
    }

    render() {
        return (
            <>
                <PageTitle
                    title="Dashboard"
                    breadcrumbs={[
                        {
                            link: '/dashboard',
                            name: 'Dashboard',
                        },
                    ]}
                />
                <PageRow>
                    <div className="row">
                        <div className={Dashboard.renderCol()}>
                            <Statistics />
                        </div>

                        {/*<NewsList*/}
                        {/*    limit={20}*/}
                        {/*    onClickHandler={ Dashboard.newsListOnClickHandler.bind(this) }*/}
                        {/*    getLatestNews={ () => {} }*/}
                        {/*/>*/}
                        
                        <div className="col-md-3">
                            <Restricted to={'credit_note.read'}>
                                <PublisherLastCreditNote />
                            </Restricted>
                        </div>
                        <div className="col-md-3">
                            <NewMerchantsList />
                        </div>
                    </div>
                </PageRow>
                <PublisherStatisticInfo/>
            </>
        );
    }
}

export default Dashboard;

import React from 'react';

type SearchProps = {
    updateSearchValue: Function,
    minLength: number,
    initialSearchTerm: string,
}

class Search extends React.Component<SearchProps> {
    state = {
        timeout: null,
    };

    inputRef = React.createRef();

    handleChange() {
        if (this.state.timeout) {
            clearTimeout(this.state.timeout)
        }

        if (this.inputRef.current.value.length >= this.props.minLength) {
            const timeoutId = setTimeout(() => {
                this.props.updateSearchValue(this.inputRef.current.value);
            }, 300);

            this.setState({
                timeout: timeoutId,
            })
        }
    }

    componentDidMount(): void {
        if (this.props.initialSearchTerm && this.props.initialSearchTerm !== 'all') {
            this.inputRef.current.value = this.props.initialSearchTerm;
        }
    }

    render() {
        return (
            <input
                ref={this.inputRef}
                type="text"
                onChange={this.handleChange.bind(this)}
                className={'form-control'}
            />
        )
    }
}

export default Search;

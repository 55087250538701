import React from 'react';
import {AvForm, AvGroup} from "availity-reactstrap-validation";
import {Label} from "reactstrap";
import Ajax from "../../../../Helper/Ajax";
import Error from "../../../App/components/Messages/Error";
import Success from "../../../App/components/Messages/Success";

type GenerateCreditNoteCollectionModalProps = {
    closeModal: Function,
    updateCloseModalStatus: Function,
}

type GeneratePeriod = {
    month: string,
    year: string,
}

type GenerateCreditNoteCollectionModalState = {
    generatePeriod: GeneratePeriod,
    isErrorCritical: boolean,
    errorCode: number,
    errorMessage: string,
    success: boolean,
    successMessage: string,
    loading: boolean,
}

class GenerateCreditNoteCollectionModal extends React.Component<GenerateCreditNoteCollectionModalProps, GenerateCreditNoteCollectionModalState> {
    currentYear = new Date().getFullYear();
    currentMonth = new Date().getMonth() + 1;

    state: GenerateCreditNoteCollectionModalState = {
        generatePeriod: {
            month: '10',
            year: '2019'
        },
        isErrorCritical: true,
        errorCode: 0,
        errorMessage: '',
        success: false,
        successMessage: '',
        loading: false,
    };

    submit(): void {
        // eslint-disable-next-line no-restricted-globals
        if (!confirm('The report data will be synchronized manually.')) {
            return;
        }

        this.setState({
           loading: true,
        });
        this.props.updateCloseModalStatus(false);

        const promise = Ajax.createPostXhr(process.env.REACT_APP_API_URL + '/admin/create-credit-notes', {
            month: this.state.generatePeriod.month,
            year: this.state.generatePeriod.year,
        });

        promise.then(response => {
            const parsedResponse = JSON.parse(response);

            if (parsedResponse.errorMessage || parsedResponse.warningMessage) {
                this.setState({
                    success: false,
                    loading: false,
                    isErrorCritical: !parsedResponse.success,
                    errorMessage: parsedResponse.errorMessage || parsedResponse.warningMessage,
                });
                this.props.updateCloseModalStatus(true);
                return;
            }

            this.setState({
                errorCode: 0,
                success: true,
                successMessage: 'Credit notes will be created.',
                loading: false,
            });
            this.props.updateCloseModalStatus(true);
        }).catch(error => {
            console.error(error);
            this.setState({
                ...this.state,
                success: false,
                loading: false,
                isErrorCritical: true,
                errorCode: 500,
                errorMessage: 'An unexpected error occured. Please try again later.',
            });
            this.props.updateCloseModalStatus(true);
        });
    }

    updateState(inputRef): void {
        if (inputRef.current.name === "month") {
            this.setState({
                ...this.state,
                generatePeriod: {
                    [inputRef.current.name]: parseInt(inputRef.current.value),
                    year: this.state.generatePeriod.year,
                },
                success: false,
                successMessage: "",
                isErrorCritical: true,
                errorCode: 0,
                errorMessage: "",
            });
        }

        if (inputRef.current.name === "year") {
            this.setState({
                ...this.state,
                generatePeriod: {
                    month: this.state.generatePeriod.month,
                    [inputRef.current.name]: parseInt(inputRef.current.value),
                },
                success: false,
                successMessage: "",
                isErrorCritical: true,
                errorCode: 0,
                errorMessage: "",
            });
        }
    }

    generateMonthOptions(options: {}) {
        const optionElements = [];

        for (let key in options) {
            optionElements.push(<option key={key} value={key}>{options[key]}</option>);
        }

        return optionElements;
    }

    generateYearOptions(options: []) {
        const optionElements = [];

        for (let option of options) {
            optionElements.push(<option key={option} value={option}>{option}</option>);
        }

        return optionElements;
    }

    renderGenerateButton() {
            if (this.state.success === true) {
                return (
                    <div className="col-lg-6 d-flex justify-content-start">
                        <button
                            className="btn btn-success"
                            type="submit"
                            name="generateCreditNotes"
                            onClick= {() => this.submit()}
                            disabled={true}
                        >
                            <span>Generate</span>
                        </button>
                    </div>
                )
            }

            if (this.state.loading === true) {
                return (
                    <div className="col-lg-6 d-flex justify-content-start">
                        <button
                            className="btn btn-success"
                            type="submit"
                            name="generateCreditNotes"
                            onClick= {() => this.submit()}
                            disabled={true}
                        >
                            <span>Generate</span>
                        </button>
                        <div className="spinner-generate-credit-notes">
                            <div className="spinner-border text-primary m-0" role="status"/>
                        </div>
                    </div>
                )
            }
        return (
            <div className="col-lg-6 d-flex justify-content-start">
                <button
                    className="btn btn-success"
                    type="submit"
                    name="generateCreditNotes"
                    onClick= {() => this.submit()}
                >
                    <span>Generate</span>
                </button>
            </div>
        )
    }

    componentDidMount(): void {
        this.setState({
            ...this.state,
            generatePeriod: {
                month: this.currentMonth,
                year: this.currentYear,
            },
        });
    }

    render() {
        const monthRef = React.createRef();
        const yearRef = React.createRef();

        const years = [];
        const targetYear = 2017;
        for (let i = this.currentYear; i >= targetYear; i--) {
            years.push(i);
        }

        const months = {
            1: "January",
            2: "February",
            3: "March",
            4: "April",
            5: "May",
            6: "June",
            7: "July",
            8: "August",
            9: "September",
            10: "October",
            11: "November",
            12: "December",
        };

        return(
            <div className="card-body background-white">
                <Label for="title">Generate credit notes</Label>
                <div className="col-lg-12 display-flex">
                    <AvForm>
                        <div className="row">
                            <AvGroup className="mb-3 col-lg-6">
                                <Label for="month">Month</Label>
                                <select
                                    value={this.state.generatePeriod.month}
                                    ref={monthRef}
                                    name="month"
                                    onChange={this.updateState.bind(this, monthRef)}
                                    className={'form-control'}
                                    disabled={false}
                                >
                                    {this.generateMonthOptions(months)}
                                </select>
                            </AvGroup>
                            <AvGroup className="mb-3 col-lg-6">
                                <Label for="year">Year</Label>
                                <select
                                    value={this.state.generatePeriod.year}
                                    ref={yearRef}
                                    name="year"
                                    onChange={this.updateState.bind(this, yearRef)}
                                    className={'form-control'}
                                    disabled={false}
                                >
                                    {this.generateYearOptions(years)}
                                </select>
                            </AvGroup>
                        </div>
                        <div className="row">
                            {this.renderGenerateButton()}
                            <div className="col-lg-6 d-flex justify-content-end">
                                <button
                                    className="btn btn-secondary"
                                    type="button"
                                    name="close"
                                    disabled={this.state.loading}
                                    onClick={this.props.closeModal}
                                >
                                    <span>Close</span>
                                </button>
                            </div>
                        </div>
                    </AvForm>
                    <Success
                        success={this.state.success}
                        successMessage={this.state.successMessage}
                    />
                    <Error
                        isErrorCritical={this.state.isErrorCritical}
                        errorMessage={this.state.errorMessage}
                    />
                </div>
            </div>
        )
    }
}

export default GenerateCreditNoteCollectionModal;

import React from 'react';

function MyProjectsEmptyState() {
    return (
        <div className="card-box">
            <div className="media-body">
                You currently don't have any projects
            </div>
        </div>
    )
}

export default MyProjectsEmptyState;

import React from 'react';
import {userGroup} from "../../Helper/UserGroups";
import Restricted from "../../Helper/PermissionProvider/Restricted";

type TopbarProps = {
  isTopMenuOpen: boolean,
  openSubmenues: {
    statistic: boolean,
    myAccount: boolean,
  },
}

class Topbar extends React.Component<TopbarProps> {
  renderIsTopMenuOpen() {
    if (this.props.isTopMenuOpen === true) {
      return {display:'block'};
    }
    return {};
  }

  renderIsSubmenueStatisticOpen(type) {
    if(this.props.openSubmenues.statistic === true) {
      return type + ' open';
    }
    return type;
  }

  renderIsSubmenueMyAccountOpen(type) {
    if(this.props.openSubmenues.myAccount === true) {
      return type + ' open';
    }
    return type;
  }

  renderAdminTopbar() {
    return (
      <div className="topbar-menu">
        <div className="container-fluid">
          <div id="navigation" style={this.renderIsTopMenuOpen()}>
            <ul className="navigation-menu">
              <li className="has-submenu">
                <a href="/admin/dashboard">
                  <i className="fe-airplay" />Dashboard</a>
              </li>
              <li className="has-submenu">
                <a href="/admin/accounts">
                  <i className="fe-grid" />
                  Account list
                </a>
              </li>
              <li className="has-submenu">
                <a href="/admin/faq">
                  <i className="mdi mdi-comment-multiple-outline" />FAQ</a>
              </li>
              <Restricted to={'credit_notes.read'}>
                <li className="has-submenu">
                  <a href="/admin/credit-notes">
                    <i className="mdi mdi-cash-multiple" />Credit notes</a>
                </li>
              </Restricted>

              {/*<li className="has-submenu">*/}
              {/*  <a href="/admin/news">*/}
              {/*    <i className="fe-bell" />*/}
              {/*    News*/}
              {/*    <div className="arrow-down" />*/}
              {/*  </a>*/}
              {/*  <ul className="submenu">*/}
              {/*    <li className="has-submenu">*/}
              {/*      <ul>*/}
              {/*        <li>*/}
              {/*          <a href="/admin/news">List news</a>*/}
              {/*        </li>*/}
              {/*      </ul>*/}
              {/*    </li>*/}
              {/*    <li className="has-submenu">*/}
              {/*      <ul>*/}
              {/*        <li>*/}
              {/*          <a href="/admin/add-news">Add news</a>*/}
              {/*        </li>*/}
              {/*      </ul>*/}
              {/*    </li>*/}
              {/*  </ul>*/}
              {/*</li>*/}
            </ul>

            <div className="clearfix" />
          </div>
        </div>
      </div>
    );
  }

  renderPublisherTopbar() {
    return (
      <div className="topbar-menu">
        <div className="container-fluid">
          <div id="navigation" style={this.renderIsTopMenuOpen()}>
            <ul className="navigation-menu">

              <li className="has-submenu">
                <a href="/dashboard">
                  <i className="fe-airplay" />Dashboard</a>
              </li>
              <li className="has-submenu">
                <a href="#nav" id="subNavigationStatistic" onClick={this.props.openSubmenuStatistic}>
                  <i className="fe-grid" />
                  Statistics
                  <div className="arrow-down" />
                </a>


                <ul className={this.renderIsSubmenueStatisticOpen('submenu')} >
                  <li className={this.renderIsSubmenueStatisticOpen('has-submenu')} >
                    <ul>
                      <li>
                        <a href="/statistics">Statistics</a>
                      </li>
                      <li>
                        <a href="/clicks-statistics">Clicks statistics</a>
                      </li>
                      <li>
                        <a href="/merchant">Merchant statistics</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

              {/*<li className="has-submenu">*/}
              {/*  <a href="/news">*/}
              {/*    <i className="fe-bell" />*/}
              {/*    News*/}
              {/*  </a>*/}
              {/*</li>*/}

              <Restricted to={'credit_note.read'}>
                <li className="has-submenu">
                  <a href="/credit-notes">
                    <i className="mdi mdi-cash-multiple"/>
                    Credit Notes
                  </a>
                </li>
              </Restricted>

              <li className="has-submenu">
                <a href="/my-projects">
                  <i className="fe-box"/>
                  My Projects
                </a>
              </li>

              <li className="has-submenu">
                <a href="/faq">
                  <i className="mdi mdi-comment-multiple-outline" />FAQ</a>
              </li>

              <li className="has-submenu">
                <a href="/merchant-list">
                  <i className="fe-shopping-bag" />
                  Merchant list
                </a>
              </li>

              <li className="has-submenu">
                <a href="#nav" id="subNavigationMyAccount" onClick={this.props.openSubmenuMyAccount}>
                  <i className="fe-user" />
                  Account
                  <div className="arrow-down" />
                </a>
                <ul className={this.renderIsSubmenueMyAccountOpen('submenu')} >
                  <li className={this.renderIsSubmenueMyAccountOpen('has-submenu')} >
                    <ul>
                      <li>
                        <a href="/change-password">Change password</a>
                      </li>
                      <li>
                        <a href="/account-information">Account information</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>

            </ul>

            <div className="clearfix" />
          </div>
        </div>
      </div>
    );
  }

  renderEmptyTopbar() {
    return (
      <div className="topbar-menu">
        <div className="container-fluid">
          <div id="navigation">
            <ul className="navigation-menu">
              <li className="has-submenu">
              </li>
            </ul>
            <div className="clearfix" />
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (localStorage['userGroup'] === userGroup.ADMIN  && localStorage['impersonating'] !== undefined) {
      return this.renderPublisherTopbar();
    }

    if (localStorage['userGroup'] === userGroup.ADMIN ) {
      return this.renderAdminTopbar();
    }

    if (localStorage['userGroup'] === userGroup.PUBLISHER) {
      return this.renderPublisherTopbar();
    }

    if (!localStorage['userGroup']) {
      return this.renderEmptyTopbar();
    }

    return '';
  }
}

export default Topbar;

import React from 'react';
import Ajax from '../../../../Helper/Ajax';

type Props = {
    creditNoteId: string,
    creditNoteNumber: string,
}
type State = {
    isBusy: boolean,
}

class RecreateCreditNoteButton extends React.Component<Props, State> {
    state: State = {
        isBusy: false,
    };

    recreateCreditNote(): void {
        const promise: Promise<string> = Ajax.createGetXhr(
            process.env.REACT_APP_API_URL + '/admin/recreate-credit-note?creditNoteId=' + this.props.creditNoteId
        );

        this.setState({isBusy: true});

        promise.then(response => {
            const parsedResponse = JSON.parse(response);

            if (parsedResponse.errorMessage || parsedResponse.warningMessage) {
                // eslint-disable-next-line no-restricted-globals
                alert('The credit note "' + this.props.creditNoteNumber + '" could not be recreated.\nPlease try again later.');

                this.setState({isBusy: false});
                return;
            }

            this.setState({isBusy: false});

            // eslint-disable-next-line no-restricted-globals
            alert('The credit note "' + this.props.creditNoteNumber + '" was successfully recreated.');

            window.location.reload();
        }).catch(error => {
            console.error(error);
            // eslint-disable-next-line no-restricted-globals
            alert('The credit note "' + this.props.creditNoteNumber + '" could not be recreated.\nPlease try again later.');

            this.setState({isBusy: false});
        });
    }

    render() {
        return (
            <button
                onClick={this.recreateCreditNote.bind(this)}
                type="button"
                className="btn btn-xs btn-outline-secondary margin-right"
                title="Recreate credit note"
                disabled={this.state.isBusy}
            >
                <i className="mdi mdi-large mdi-restore" />
                {
                    this.state.isBusy && <span className="spinner-border text-primary float-right download-pdf-button-spinner" />
                }
            </button>
        );
    }
}

export default RecreateCreditNoteButton;

import React, { useRef } from "react";
import PermissionContext from "./PermissionContext";
import {MutableRefObject} from "react";

export type Permission = string;

type Props = {
    fetchPermission: (p: Permission) => Promise<boolean>;
}

type PromiseCache = {
    [key: string]: Promise<boolean>;
}

const PermissionProvider: React.FC<Props> = ({ fetchPermission, children }) => {
    const promises : MutableRefObject<PromiseCache> = useRef({});

    const isAllowedTo = async (permission: Permission): Promise<boolean> => {
        if (!promises.current[permission]) {
            promises.current[permission] = fetchPermission(permission);
        }

        return promises.current[permission];
    };

    return <PermissionContext.Provider value={{ isAllowedTo }}>{children}</PermissionContext.Provider>;
}

export default PermissionProvider;

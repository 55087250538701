import React, {StrictMode} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Login from './Publisher/components/Login';
import Dashboard from './Publisher/components/Dashboard';
import Backend from './Backend';
import NotFound from './NotFound';
import AdminDashboard from './Admin/components/AdminDashboard';
import AccountForm from './Admin/components/AccountForm';
import AccountList from './Admin/components/AccountList';
import DayStatistics from './Publisher/components/DayStatistics';
import ClicksStatistics from './Publisher/components/ClicksStatistics/ClicksStatistics';
import MerchantStatistics from './Publisher/components/Merchant/MerchantStatistics';
import ForgotPassword from './Publisher/components/PasswordReset/ForgotPassword';
import ResetPassword from './Publisher/components/PasswordReset/ResetPassword';
import LogoutPage from './App/components/LogoutPage';
import Header from './Layout/Header';
import Footer from './Layout/Footer';
import MainContent from './Layout/MainContent';
import InitialPassword from './Publisher/components/PasswordReset/InitialPassword';
import MyAccount from './Publisher/components/MyAccount/MyAccount';
import MyProjectsSite from './Publisher/components/MyProjects/MyProjectsSite'
import Imprint from './LegalText/Imprint';
import DataProtection from './LegalText/DataProtection';
import CustomerInformation from './Admin/components/CustomerInformation/CustomerInformation';
import AdminFaqList from './Admin/components/Faq/AdminFaqList'
import AdminFaqEdit from './Admin/components/Faq/AdminFaqEdit'
import AdminFaqAdd from './Admin/components/Faq/AdminFaqAdd';
import FaqList from './Publisher/components/Faq/FaqList';
import PublisherCustomerInformation from './Publisher/components/CustomerInformation/PublisherCustomerInformation';
import MerchantList from './Publisher/components/Merchant/MerchantList';
import BankView from './Admin/components/BankView';
import AccountSettings from './Admin/components/Account/AccountSettings';
import CreditNoteCollectionList from './Admin/components/CreditNotes/CreditNoteCollectionList';
import DetailCreditNote from './Admin/components/CreditNotes/DetailCreditNote';
import CreditNoteCollection from "./Admin/components/CreditNotes/CreditNoteCollection";
import PublisherCreditNoteList from "./Publisher/components/CreditNotes/PublisherCreditNoteList";
import PermissionProvider from "../Helper/PermissionProvider/PermissionProvider";
import Ajax from "../Helper/Ajax";
// import NewsSite from './Publisher/components/News/NewsSite';
// import ListNews from './Admin/components/News/ListNews';
// import AddNews from './Admin/components/News/AddNews';
// import EditNews from './Admin/components/News/EditNews';

const Router = () => (
    <div>
        <StrictMode>
            <PermissionProvider fetchPermission={Ajax.fetchPermission}>
                <Header/>
                <MainContent>
                    <BrowserRouter>
                        <Switch>
                            <Route exact path='/' component={Login}/>
                            <Route path='/dashboard' component={Dashboard}/>
                            <Route path='/statistics/:year/:month/:projectId/:market' component={DayStatistics}/>
                            <Route path='/statistics' component={DayStatistics}/>
                            <Route path='/clicks-statistics/:dateFrom/:dateTo/:page/:resultsPerPage' component={ClicksStatistics}/>
                            <Route path='/clicks-statistics' component={ClicksStatistics}/>
                            <Route path='/backend/:sessionId' component={Backend}/>
                            <Route path='/account-information' component={PublisherCustomerInformation}/>
                            <Route path='/admin/dashboard' component={AdminDashboard}/>
                            <Route path='/admin/account' component={AccountForm}/>
                            <Route path='/admin/accounts' component={AccountList}/>
                            <Route path='/admin/customer-information' component={CustomerInformation}/>
                            <Route path='/admin/faq' component={AdminFaqList}/>
                            <Route path='/admin/add-faq' component={AdminFaqAdd}/>
                            <Route path='/admin/edit-faq' component={AdminFaqEdit}/>
                            <Route path='/admin/edit-faq/:faqId' component={AdminFaqEdit}/>
                            <Route path='/admin/account-settings' component={AccountSettings}/>
                            <Route path='/admin/bank-information' component={BankView}/>
                            <Route path='/admin/credit-notes/detail/:creditNoteId/:page' component={DetailCreditNote}/>
                            <Route path='/admin/credit-notes/collection/:creditNoteCollectionId' component={CreditNoteCollection}/>
                            <Route path='/admin/credit-notes' component={CreditNoteCollectionList}/>
                            <Route
                                path='/merchant/:market/:project/:dateFrom/:dateTo/:merchantName/:page/:rowsPerPage/:sortColumn/:sortDirection'
                                component={MerchantStatistics}/>
                            <Route path='/merchant' component={MerchantStatistics}/>
                            <Route path='/initial-password/:passwordResetJwt' component={InitialPassword}/>
                            <Route path='/forgot-password' component={ForgotPassword}/>
                            <Route path='/reset-password/:passwordResetJwt' component={ResetPassword}/>
                            <Route path='/logout' component={LogoutPage}/>
                            {/*<Route path='/admin/news' component={ListNews} />*/}
                            {/*<Route path='/admin/add-news' component={AddNews} />*/}
                            {/*<Route path='/admin/edit-news/:newsId' component={EditNews} />*/}
                            {/*<Route path='/news/:newsId' component={NewsSite} />*/}
                            {/*<Route path='/news' component={NewsSite} />*/}
                            <Route path='/change-password' component={MyAccount}/>
                            <Route path='/my-projects' component={MyProjectsSite}/>
                            <Route path='/imprint' component={Imprint}/>
                            <Route path='/data-protection' component={DataProtection}/>
                            <Route path='/faq' component={FaqList}/>
                            <Route path='/merchant-list' component={MerchantList}/>
                            <Route path='/credit-notes' component={PublisherCreditNoteList}/>
                            <Route component={NotFound}/>
                        </Switch>
                    </BrowserRouter>
                </MainContent>
                <Footer/>
            </PermissionProvider>
        </StrictMode>
    </div>
);

export default Router;

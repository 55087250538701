import React from 'react';
import PageTitle from "../../../Layout/PageTitle";
import { Button, Card, CardBody, Label } from "reactstrap";
import PageRow from "../../../Layout/PageRow";
import {AvFeedback, AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import Success from "../../../App/components/Messages/Success";
import Error from "../../../App/components/Messages/Error";
import Ajax from "../../../../Helper/Ajax";
import ArrayDropDown from "../../../Publisher/components/ArrayDropDown";

type CustomerInformationType = {
    customerPublisherNexusId: string,
    publisherId: string,
    publisherName: string,
    vat: string,
    isExempted: string,
}

type CustomerAddress = {
    id: string,
    company: string,
    street: string,
    houseNumber: string,
    postCode: string,
    city: string,
    countryId: string,
    additionalInformation: string,
}

type BankInformation = {
    id: string,
    bankCompany: string,
    bankStreet: string,
    bankHouseNumber: string,
    bankPostCode: string,
    bankCity: string,
    bankCountryId: string,
    bankCountryName: string,
    bankAdditionalInformation: string,
    iban: string,
    bic: string,
    bankAccountOwner: string,
}

type Country = {
    id: string,
    name: string,
}

type CustomerInformationOptions = {
    additionalInformation: number,
    bankAdditionalInformation: number,
    addressId: number,
    bankAccountOwner: number,
    bankAddressId: number,
    bic: number,
    isExempted: string,
    city: number,
    bankCity: number,
    company: number,
    bankCompany: number,
    countryId: number,
    bankCountryId: number,
    customerPublisherNexusId: number,
    houseNumber: number,
    bankHouseNumber: number,
    iban: number,
    id: number,
    postCode: number,
    bankPostCode: number,
    publisherId: number,
    street: number,
    bankStreet: number,
    vat: number
}

type CustomerInformationState = {
    customerInformation: CustomerInformationType,
    customerAddress: CustomerAddress,
    bankInformation: BankInformation,
    customerInformationOptions: CustomerInformationOptions,
    countries: Country[],
    loadingFromApi: boolean,
    isErrorCritical: boolean,
    errorMessage: string,
    success: boolean,
    successMessage: string,
}

class CustomerInformation extends React.Component<{}, CustomerInformationState> {
    state: CustomerInformationState = {
        customerInformation: {},
        customerAddress: {},
        bankInformation: {},
        customerInformationOptions: {},
        countries: [],
        loadingFromApi: false,
        isErrorCritical: true,
        errorMessage: '',
        success: false,
        successMessage: '',
    };

    isValid = [];

    buttonOnClickSave(): void {
        for (let valid in this.isValid) {
            if (!this.isValid.hasOwnProperty(valid)) {
                continue;
            }

            if (this.isValid[valid] === false) {
                this.setState({
                    success: false,
                    errorMessage: 'Customer information is invalid. Please correct the highlighted fields and try again.',
                });

                return;
            }
        }

        const url = process.env.REACT_APP_API_URL + `/admin/customer-information/edit`;
        const promise: Promise<string> = Ajax.createPostXhr(
            url,
            {
                ...this.state.customerInformation,
                ...this.state.customerAddress,
                ...this.state.bankInformation,
            }
        );

        promise.then(response => {
            const parsedResponse = JSON.parse(response);

            if (parsedResponse.errorMessage || parsedResponse.warningMessage) {
                this.setState({
                    success: false,
                    isErrorCritical: !parsedResponse.success,
                    errorMessage: parsedResponse.errorMessage || parsedResponse.warningMessage,
                });
                return;
            }

            this.setState({
                errorMessage: '',
                success: true,
                successMessage: 'Customer information for "' + this.state.customerInformation.publisherName +
                    '" was saved successfully.',
            });
        }).catch(error => {
            console.error(error);
            this.setState({
                ...this.state,
                success: false,
                isErrorCritical: true,
                errorMessage: 'An unexpected error occured. Please try again later.',
            });
        });
    }

    componentDidMount() {
        const url = new URL(window.location);
        const publisherId = url.searchParams.get('pid');

        if (publisherId === null || publisherId === '') {
            alert('There must be a publisher ID to get customer information.');
            window.location.href = '/admin/accounts';
        }

        this.setState({
            ...this.state,
            customerInformation: {
                publisherId: publisherId
            },
            loadingFromApi: true,
        },() => {
            const url = process.env.REACT_APP_API_URL + `/admin/customer-information/get`;
            const promise: Promise<string> = Ajax.createPostXhr(url, {publisherId:this.state.customerInformation.publisherId});

            promise.then(response => {
                const parsedResponse = JSON.parse(response);
                if (parsedResponse.errorMessage || parsedResponse.warningMessage) {
                    this.setState({
                        isErrorCritical: !parsedResponse.success,
                        errorMessage: parsedResponse.errorMessage || parsedResponse.warningMessage,
                    });
                    return;
                }

                const countries    = parsedResponse.data.countries;
                const countryArray = [];

                for (let country of countries) {
                    countryArray.push(JSON.parse(country));
                }

                this.setState({
                    ...this.state,
                    customerInformation: parsedResponse.data.customerInformation.customerInformation,
                    customerAddress: parsedResponse.data.customerInformation.customerAddress,
                    bankInformation: parsedResponse.data.customerInformation.bankInformation,
                    customerInformationOptions: parsedResponse.data.customerInformationOptions,
                    countries: countryArray,
                    errorMessage: '',
                });
            }).catch(error => {
                console.error(error);
                this.setState({
                    errorMessage: 'An unexpected error occured. Please try again later.',
                });
            });
        });
    }

    updateState(layer: string, key: string, e: Event): void {
        let value = e.target['value'];
        if (key === 'isExempted') {
            value = value === "false";
        }

        this.setState({
            [layer]: {
                ...this.state[layer],
                [key]: value,
            }
        });
    }

    countrySelectHandler(layer: string, key: string, name: string, value: string): void {
        this.setState({
            [layer]: {
                ...this.state[layer],
                [key]: value,
            }
        });
    }

    validate(value, ctx, input, cb) {
        if (value.length > this.state.customerInformationOptions[input.props.name]) {
            cb(false);
            this.isValid[input.props.name] = false;
            return;
        }
        this.isValid[input.props.name] = true;
        cb(true);
    }

    render() {
        return (
            <>
                <PageTitle
                    title="Customer information"
                    breadcrumbs={[
                        {
                            link: '/admin/dashboard',
                            name: 'Dashboard',
                        },
                        {
                            link: '/admin/accounts',
                            name: 'Account list',
                        },
                        {
                            name: 'Customer information'
                        },
                    ]}
                />
                <PageRow>
                    <div className="row">
                        <div className="col-md-8 col-lg-6">
                            <Card>
                                <CardBody className="p-4">
                                    <Label className="customer-label-l">
                                        General Information
                                    </Label>
                                    <br/>
                                    <Label>Customer number: </Label><br/>
                                    <p>{this.state.customerInformation.customerPublisherNexusId}</p>
                                    <Label>Publisher name: </Label><br/>
                                    <p>{this.state.customerInformation.publisherName}</p>
                                    <AvForm>
                                        <AvGroup className="mb-3">
                                            <Label>VAT-ID</Label>
                                            <AvInput
                                                name="vat"
                                                placeholder="VAT-ID"
                                                value={this.state.customerInformation.vat}
                                                onChange={ this.updateState.bind(this, 'customerInformation', 'vat') }
                                                validate={{ custom: this.validate.bind(this) }}
                                            />
                                            <AvFeedback>This field is invalid</AvFeedback>
                                        </AvGroup>
                                        <AvGroup>
                                            <Label>Exempted:</Label>
                                            <div className="custom-control custom-switch">
                                                <AvInput
                                                    name="isExempted"
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    value={this.state.customerInformation.isExempted}
                                                    checked={this.state.customerInformation.isExempted}
                                                    id="customSwitch1"
                                                    onChange={ this.updateState.bind(this, 'customerInformation', 'isExempted') }
                                                />
                                                <Label
                                                    className="custom-control-label"
                                                    htmlFor="customSwitch1"> </Label>
                                                <AvFeedback>This field is invalid</AvFeedback>
                                            </div>
                                        </AvGroup>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8 col-lg-6">
                            <Card>
                                <CardBody className="p-4">
                                    <Label className="customer-label-l">Address</Label>
                                    <AvForm>
                                        <div className="row">
                                            <AvGroup className="mb-3 col-lg-9">
                                                <Label>Company</Label>
                                                <AvInput
                                                    name="company"
                                                    placeholder="Company"
                                                    value={this.state.customerAddress.company}
                                                    onChange={ this.updateState.bind(this, 'customerAddress', 'company') }
                                                    validate={{custom: this.validate.bind(this)}}
                                                />
                                                <AvFeedback>This field is invalid</AvFeedback>
                                            </AvGroup>
                                            <AvGroup className="mb-3 col-lg-9">
                                                <Label>Street</Label>
                                                <AvInput
                                                    name="street"
                                                    placeholder="Street"
                                                    value={this.state.customerAddress.street}
                                                    onChange={ this.updateState.bind(this, 'customerAddress', 'street') }
                                                    validate={{ custom: this.validate.bind(this) }}
                                                />
                                                <AvFeedback>This field is invalid</AvFeedback>
                                            </AvGroup>
                                            <AvGroup className="mb-3 col-lg-3">
                                                <Label>House number</Label>
                                                <AvInput
                                                    name="houseNumber"
                                                    placeholder="House number"
                                                    value={this.state.customerAddress.houseNumber}
                                                    onChange={ this.updateState.bind(this, 'customerAddress', 'houseNumber') }
                                                    validate={{ custom: this.validate.bind(this) }}
                                                />
                                                <AvFeedback>This field is invalid</AvFeedback>
                                            </AvGroup>
                                        </div>
                                        <div className="row">
                                            <AvGroup className="mb-3 col-lg-9">
                                                <Label>City</Label>
                                                <AvInput
                                                    name="city"
                                                    placeholder="City"
                                                    value={this.state.customerAddress.city}
                                                    onChange={ this.updateState.bind(this, 'customerAddress', 'city') }
                                                    required
                                                    validate={{ custom: this.validate.bind(this) }}
                                                />
                                                <AvFeedback>This field is invalid</AvFeedback>
                                            </AvGroup>
                                            <AvGroup className="mb-3 col-lg-3">
                                                <Label>Postal Code</Label>
                                                <AvInput
                                                    name="postCode"
                                                    placeholder="Postal Code"
                                                    value={this.state.customerAddress.postCode}
                                                    onChange={ this.updateState.bind(this, 'customerAddress', 'postCode') }
                                                    validate={{ custom: this.validate.bind(this) }}
                                                />
                                                <AvFeedback>This field is invalid</AvFeedback>
                                            </AvGroup>
                                        </div>
                                        <div className="row">
                                            <AvGroup className="mb-3 col-lg-12">
                                                <Label>Additional information</Label>
                                                <AvInput
                                                    type="textarea"
                                                    name="additionalInformation"
                                                    placeholder="Additional information"
                                                    value={this.state.customerAddress.additionalInformation}
                                                    onChange={ this.updateState.bind(this, 'customerAddress', 'additionalInformation') }
                                                    validate={{ custom: this.validate.bind(this) }}
                                                />
                                                <AvFeedback>This field is invalid</AvFeedback>
                                            </AvGroup>
                                        </div>
                                        <div className="row">
                                            <AvGroup className="mb-1 col-lg-6">
                                                <Label>Country</Label>
                                                <ArrayDropDown
                                                    name="country"
                                                    options={this.state.countries}
                                                    updateList={this.countrySelectHandler.bind(this, 'customerAddress', 'countryId')}
                                                    defaultValue={'64'}
                                                    selectedValue={this.state.customerAddress.countryId}
                                                    defaultName={'Germany'}
                                                    disabled={false}
                                                />
                                            </AvGroup>
                                        </div>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8 col-lg-6">
                            <Card>
                                <CardBody className="p-4">
                                    <Label className="customer-label-l">Bank Information</Label>
                                    <AvForm>
                                        <AvGroup className="mb-3">
                                            <Label>Bank name</Label>
                                            <AvInput
                                                name="bankCompany"
                                                placeholder="Bank name"
                                                value={this.state.bankInformation.bankCompany}
                                                onChange={ this.updateState.bind(this, 'bankInformation', 'bankCompany') }
                                                validate={{ custom: this.validate.bind(this) }}
                                            />
                                            <AvFeedback>This field is invalid</AvFeedback>
                                        </AvGroup>
                                        <div className="row">
                                            <AvGroup className="mb-3 col-lg-9">
                                                <Label>Street</Label>
                                                <AvInput
                                                    name="bankStreet"
                                                    placeholder="Street"
                                                    value={this.state.bankInformation.bankStreet}
                                                    onChange={ this.updateState.bind(this, 'bankInformation', 'bankStreet') }
                                                    validate={{ custom: this.validate.bind(this) }}
                                                />
                                                <AvFeedback>This field is invalid</AvFeedback>
                                            </AvGroup>
                                            <AvGroup className="mb-3 col-lg-3">
                                                <Label>House number</Label>
                                                <AvInput
                                                    name="bankHouseNumber"
                                                    placeholder="House number"
                                                    value={this.state.bankInformation.bankHouseNumber}
                                                    onChange={ this.updateState.bind(this, 'bankInformation', 'bankHouseNumber') }
                                                    validate={{ custom: this.validate.bind(this) }}
                                                />
                                                <AvFeedback>This field is invalid</AvFeedback>
                                            </AvGroup>
                                        </div>
                                        <div className="row">
                                            <AvGroup className="mb-3 col-lg-9">
                                                <Label>City</Label>
                                                <AvInput
                                                    name="bankCity"
                                                    placeholder="City"
                                                    value={this.state.bankInformation.bankCity}
                                                    onChange={ this.updateState.bind(this, 'bankInformation', 'bankCity') }
                                                    validate={{ custom: this.validate.bind(this) }}
                                                />
                                                <AvFeedback>This field is invalid</AvFeedback>
                                            </AvGroup>
                                            <AvGroup className="mb-3 col-lg-3">
                                                <Label>Postal Code</Label>
                                                <AvInput
                                                    name="bankPostCode"
                                                    placeholder="Postal Code"
                                                    value={this.state.bankInformation.bankPostCode}
                                                    onChange={ this.updateState.bind(this, 'bankInformation', 'bankPostCode') }
                                                    validate={{ custom: this.validate.bind(this) }}
                                                />
                                                <AvFeedback>This field is invalid</AvFeedback>
                                            </AvGroup>
                                        </div>
                                        <div className="row">
                                            <AvGroup className="mb-3 col-lg-12">
                                                <Label>Additional information</Label>
                                                <AvInput
                                                    type="textarea"
                                                    name="bankAdditionalInformation"
                                                    placeholder="Additional information"
                                                    value={this.state.bankInformation.bankAdditionalInformation}
                                                    onChange={ this.updateState.bind(this, 'bankInformation', 'bankAdditionalInformation') }
                                                    validate={{ custom: this.validate.bind(this) }}
                                                />
                                                <AvFeedback>This field is invalid</AvFeedback>
                                            </AvGroup>
                                        </div>
                                        <AvGroup className="mb-3">
                                            <Label>Account owner</Label>
                                            <AvInput
                                                name="bankAccountOwner"
                                                placeholder="Account owner"
                                                value={this.state.bankInformation.bankAccountOwner}
                                                onChange={ this.updateState.bind(this, 'bankInformation', 'bankAccountOwner') }
                                                validate={{ custom: this.validate.bind(this) }}
                                            />
                                            <AvFeedback>This field is invalid</AvFeedback>
                                        </AvGroup>
                                        <AvGroup className="mb-3">
                                            <Label>IBAN</Label>
                                            <AvInput
                                                name="iban"
                                                placeholder="IBAN"
                                                value={this.state.bankInformation.iban}
                                                onChange={ this.updateState.bind(this, 'bankInformation', 'iban') }
                                                validate={{ custom: this.validate.bind(this) }}
                                            />
                                            <AvFeedback>This field is invalid</AvFeedback>
                                        </AvGroup>
                                        <AvGroup className="mb-3">
                                            <Label>BIC</Label>
                                            <AvInput
                                                name="bic"
                                                placeholder="BIC"
                                                value={this.state.bankInformation.bic}
                                                onChange={ this.updateState.bind(this, 'bankInformation', 'bic') }
                                                validate={{ custom: this.validate.bind(this) }}
                                            />
                                            <AvFeedback>This field is invalid</AvFeedback>
                                        </AvGroup>
                                        <div className="row">
                                            <AvGroup className="mb-1 col-lg-6">
                                                <div className="form-group mb-3">
                                                    <Label>Country</Label>
                                                    <ArrayDropDown
                                                        name="bankCountry"
                                                        options={this.state.countries}
                                                        updateList={this.countrySelectHandler.bind(this, 'bankInformation', 'bankCountryId')}
                                                        defaultValue={'64'}
                                                        selectedValue={this.state.bankInformation.bankCountryId}
                                                        defaultName={'Germany'}
                                                        disabled={false}
                                                    />
                                                </div>
                                            </AvGroup>
                                        </div>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8 col-lg-6">
                            <Card>
                                <CardBody>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <Success success={this.state.success} successMessage={this.state.successMessage} />
                                            <Error
                                                isErrorCritical={this.state.isErrorCritical}
                                                errorMessage={this.state.errorMessage}
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                            <Button
                                                onClick={this.buttonOnClickSave.bind(this)}
                                                color="primary"
                                                className="btn btn-success float-right"
                                                title={'Save'}
                                            >
                                                <i className="mdi mdi-label-l mdi-content-save" />
                                            </Button>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </PageRow>
            </>
        )
    }
}

export default CustomerInformation;

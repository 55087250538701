import React from 'react';
import PageTitle from "../Layout/PageTitle";
import PageRow from "../Layout/PageRow";

class Imprint extends React.Component {
    render() {
        return (
            <>
                <PageTitle
                    title="Imprint"
                    breadcrumbs={[]}
                />
                <PageRow>
                    <div className="card">
                        <div className="card-body">
                            <p>
                                <b>Yadore GmbH</b><br/>
                                Bavariafilmplatz 7<br/>
                                82031 Grünwald<br/>
                                <br/>
                                mail(at)yadore.com<br/>
                                www.yadore.com<br/>
                                Telefon: +49 89 96058289<br/>
                                <br/>
                                <b>CEO:</b> Fabian Gärtner<br/>
                                <b>Commercial register:</b> HRB 235343<br/>
                                <b>VAT registration number:</b> DE314199135<br/>
                                <b>Register court:</b> Amtsgericht Munich<br/>
                            </p>
                        </div>
                    </div>
                </PageRow>
            </>
        )
    }
}

Imprint.propTypes = {};

export default Imprint;
import React from 'react';
import Ajax from '../../../../Helper/Ajax';
import Error from '../../../App/components/Messages/Error';
import Success from '../../../App/components/Messages/Success';
import {FormGroup} from "reactstrap";
import {AvFeedback, AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";

type ResetPasswordRouteMatch = {
    isExact: true,
    passwordResetJwt: String,
}

type ResetPasswordProps = {
    match: ResetPasswordRouteMatch,
}

type ResetPasswordState = {
    isErrorCritical: boolean,
    errorMessage: String,
    dataTableProgressPending: boolean,
}

class ResetPassword extends React.Component<ResetPasswordProps, ResetPasswordState> {
    state = {
        password: '',
        passwordRepitition: '',
        passwordResetJwt: this.props.match.params.passwordResetJwt || '',
        success: false,
        successMessage: '',
        isErrorCritical: true,
        errorMessage: '',
    };

    submit() {
        const postData = {
            password: this.state.password,
            passwordRepitition: this.state.passwordRepitition,
            passwordResetJwt: this.state.passwordResetJwt,
        };

        const promise = Ajax.createPostXhr(process.env.REACT_APP_API_URL + '/reset-password', postData);

        promise.then(response => {
            const parsedResponse = JSON.parse(response);

            if (parsedResponse.errorMessage || parsedResponse.warningMessage) {
                this.setState({
                    success: false,
                    isErrorCritical: !parsedResponse.success,
                    errorMessage: parsedResponse.errorMessage || parsedResponse.warningMessage,
                });
                return;
            }

            this.setState({
                ...this.state,
                errorMessage: '',
                success: true,
                successMessage: 'Your password was changed successfully.'
            });
        }).catch(error => {
            console.error(error);
            this.setState({
                ...this.state,
                isErrorCritical: true,
                errorMessage: 'An unexpected error occured. Please try again later.',
            });
        });
    }

    render() {
        let  homeLink = '/dashboard';

        if (localStorage['userGroup'] === undefined) {
            homeLink = '/';
        }

        return (
            <>
                <div className="login-bg">
                    <img src="/images/login.jpg" className="fixed-image" alt="Laptop" type="Login"/>
                    <div className="center">
                        <img className="logo" src="/images/yadore.svg" alt="Logo der Yadore GmbH"
                             title="Yadore – We love conversions." />
                        <h1 className="headline-one">Forgot password</h1>
                        <p>Forgot your password?
                            To reset your password, type in your new password and repeat it.
                        </p>
                        <AvForm onValidSubmit={this.submit.bind(this)} class="text-danger form-group">
                            <AvGroup>
                                <AvInput
                                    className="is-touched is-dirty av-invalid is-invalid form-control yde-input"
                                    placeholder={ 'Type in your new password...' }
                                    type={ 'password' }
                                    value={ this.state.password }
                                    onChange={ e => this.setState({ password: e.target.value }) }
                                    name={"password"}
                                />
                                <AvFeedback>This field is invalid</AvFeedback>
                            </AvGroup>
                            <AvGroup>
                                <AvInput
                                    className="is-touched is-dirty av-invalid is-invalid form-control yde-input"
                                    placeholder={ 'Repeat your new password...' }
                                    type={ 'password' }
                                    value={ this.state.passwordRepitition }
                                    onChange={ e => this.setState({ passwordRepitition: e.target.value }) }
                                    name={"password-repitition"}
                                />
                                <AvFeedback>This field is invalid</AvFeedback>
                            </AvGroup>

                            <FormGroup>
                                <button className="yde-button yde-button--dark btn btn-secondary" onClick={this.submit.bind(this)}>
                                    Reset
                                </button>
                            </FormGroup>

                            <Success
                                success={this.state.success}
                                successMessage={this.state.successMessage}
                            />
                            <Error
                                isErrorCritical={this.state.isErrorCritical}
                                errorMessage={this.state.errorMessage}
                            />

                        </AvForm>
                    </div>
                    <p><a className="forgot text-link" href={homeLink}>Back to Home</a></p>
                </div>
            </>
        );
    }
}

export default ResetPassword;

import React from 'react';
import type {CreditNoteType} from "./CreditNoteCollection";

type PendingCreditNotesViewProps = {
  pendingCreditNotes: CreditNoteType[]
}

type PendingCreditNotesViewState = {}

class PendingCreditNotesView extends React.Component<PendingCreditNotesViewProps, PendingCreditNotesViewState> {

  render() {
    let creditNoteSum = 0;
    if (Object.entries(this.props.pendingCreditNotes).length === 0) {
      return <div></div>;
    }

    return <div>
      <div className="pending-items">
        <div className="pending-credit-note-header">Pending credit notes</div>
        {this.props.pendingCreditNotes.map((creditNote) => {
          const crediteNoteDate = new Date(creditNote.dateFrom);

          const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
          ];

          const monthShort = monthNames[crediteNoteDate.getMonth()];

          creditNoteSum += parseFloat(creditNote.subTotal);
          return <div key={creditNote.id}>
            <div className="pending-item1">#{creditNote.creditNoteNumber}</div>
            <div className="pending-item2">{monthShort} {crediteNoteDate.getFullYear()}</div>
            <div className="pending-item3">{creditNote.subTotal} &euro;</div>
          </div>
        })}
        <div>
          <div className="pending-item-bottom-col2">Sum of pending <br/>months</div>
          <div className="pending-item-bottom">{creditNoteSum.toFixed(2)} &euro;</div>
        </div>
        <br className="clear"/>
      </div>
    </div>
  }
}

export default PendingCreditNotesView;

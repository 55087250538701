import React from 'react';
import LoginForm from '../../App/components/Form/LoginForm';

class Login extends React.Component<> {
  render() {
      return(
      <div className={'container'}>
        <LoginForm
          baseUrl ={process.env.REACT_APP_API_URL}
          unauthorizedRedirectUrl={'/'}
        />
      </div>
    );
  }
}

export default Login;

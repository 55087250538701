import React from 'react';

type MainContentProps = {
}

class MainContent extends React.Component<MainContentProps> {
  render() {
    if (window.location.pathname.indexOf('/admin/credit-notes/detail/') !== -1) {
      return this.props.children;
    }

    return (
        <div className="wrapper">
          <div className="container-fluid">
            {this.props.children}
          </div>
        </div>
    );
  }
}

export default MainContent;

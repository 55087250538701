// @flow
import React from 'react';
import Ajax from '../../../../Helper/Ajax';
import Error from '../Messages/Error';
import { Label, Button } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { Link } from 'react-router-dom';
import {userGroup} from "../../../../Helper/UserGroups";

type LoginFormProps = {
  baseUrl: string,
  unauthorizedRedirectUrl: string,
}

type LoginFormState = {
  email: string,
  password: string,
  isErrorCritical: boolean,
  errorMessage: string,
}

class LoginForm extends React.Component<LoginFormProps, LoginFormState> {
  state = {
    email: '',
    password: '',
    isErrorCritical: true,
    errorMessage: '',
  };

  componentDidMount() {
    if (localStorage['expired']) {
      this.setState({
        ...this.state,
        isErrorCritical: true,
        errorMessage: 'Your session has expired. Please log in again.',
      })
    }
  }

  submit() {
    const formData = {
      email: this.state.email,
      password: this.state.password,
    };

    const promise = Ajax.createPostXhr(this.props.baseUrl + '/login', formData);

    promise.then(async response => {
      const parsedResponse = JSON.parse(response);

      if (parsedResponse.errorMessage || parsedResponse.warningMessage) {
        this.setState({
          isErrorCritical: !parsedResponse.success,
          errorMessage: parsedResponse.errorMessage || parsedResponse.warningMessage,
        });
        return;
      }

      if (parsedResponse.success === true) {
        localStorage['apiKey'] = parsedResponse.data.apiKey;
        localStorage['unauthorizedRedirectUrl'] = this.props.unauthorizedRedirectUrl;
      }

      let userGroupResp = await Ajax.createGetXhr(this.props.baseUrl +'/user-group',);

      let parsedUserGroupResp = JSON.parse(userGroupResp);
      if(parsedUserGroupResp.success === true){
        localStorage['userGroup'] = parsedUserGroupResp.data.userGroup;
      }

      if(parsedUserGroupResp.data.userGroup === userGroup.ADMIN) {
        window.location.href = '/admin/dashboard';
        return;
      }

      window.location.href = '/dashboard';
      return;

    }).catch(error => {
      console.error(error);
      this.setState({
        ...this.state,
        isErrorCritical: true,
        errorMessage: 'An unexpected error occured. Please try again later.',
      });
    });
  }

  renderForgotPasswordLink() {

    return (
        <p>
          <Link
              to={ '/forgot-password' }
              className="forgot text-link"
          >Forgot your password?</Link>
        </p>
    );
  }

  renderEmailInput() {
    if (this.props.area === 'Admin') {
      return (
          <AvGroup>
            <Label className="own-label" for="username">Username</Label>
            <AvInput
                className="is-touched is-dirty av-valid form-control yde-input"
                id="username"
                type="text"
                name="username"
                label="Username"
                placeholder="Enter your username"
                value={this.state.email}
                required
                onChange={ e => this.setState({ email: e.target.value }) }
            />
            <AvFeedback
                className="av-invalid"
            >
              This field is invalid
            </AvFeedback>
          </AvGroup>
      );
    }

    return (
        <AvGroup>
          <Label className="own-label" for="emailAddress">E-mail</Label>
          <AvInput
              className="is-touched is-dirty av-valid form-control yde-input"
              type="email"
              name="emailAddress"
              label="E-mail"
              placeholder="Enter your e-mail"
              value={this.state.email}
              required
              onChange={ e => this.setState({ email: e.target.value }) }
          />
          <AvFeedback>This field is invalid</AvFeedback>
        </AvGroup>
    );
  }

  renderHeadLine() {
    if (this.props.area === 'Admin') {
      return <h1 className="headline-one">Admin Login</h1>

    }

    return <h1 className="headline-one">Publisher Login</h1>
  }

  render() {

    return (
        <>
          <div className="login-bg">
            <img src="../images/login.jpg" className="fixed-image" alt="Laptop" type="Login"/>
            <div className="center">
              <img className="logo" src="../images/yadore.svg" alt="Logo der Yadore GmbH"
                   title="Yadore – We love conversions." />
              { this.renderHeadLine() }
              <AvForm onValidSubmit={this.submit.bind(this)} className="text-danger form-group">
                { this.renderEmailInput() }
                <AvGroup>
                  <Label className="own-label" for="password">Password</Label>
                  <AvInput
                      className="is-touched is-dirty av-invalid is-invalid form-control yde-input"
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Enter your password"
                      minLength="8"
                      value={this.state.password}
                      required
                      onChange={ e => this.setState({ password: e.target.value }) }
                  />
                  <AvFeedback
                      className="av-invalid"
                  >
                    This field is invalid
                  </AvFeedback>
                </AvGroup>
                <Error
                    isErrorCritical={this.state.isErrorCritical}
                    errorMessage={this.state.errorMessage}
                />
                <AvGroup>
                  <Button className="yde-button yde-button--dark btn btn-secondary">Log In</Button>
                </AvGroup>
              </AvForm>
            </div>
            { this.renderForgotPasswordLink() }
          </div>
        </>
    );
  }
}

export default LoginForm;

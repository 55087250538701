type Ymd = {
    year: number,
    month: number,
    day: number,
};

class DatePickerHelper {
    static handleChangeStartDate(
        endDate: Ymd,
        updateStateMethod: function,
        startDateNameInState: string,
        date: Date[]
    ): void {
        DatePickerHelper.setHoursToNormalizeTimezone(date[0]);

        let stateString = endDate.year + '-';
        stateString    += DatePickerHelper.attachLeadingZero(endDate.month) + '-';
        stateString    += DatePickerHelper.attachLeadingZero(endDate.day);

        let dateString = date[0].getFullYear() + '-';
        dateString    += DatePickerHelper.attachLeadingZero(date[0].getMonth() + 1)  + '-';
        dateString    += DatePickerHelper.attachLeadingZero(date[0].getDate());

        if (stateString >= dateString) {
            updateStateMethod(startDateNameInState, DatePickerHelper.stateFromDate(date[0]));
        }
    }

    static handleChangeEndDate(
        startDate: Ymd,
        updateStateMethod: function,
        endDateNameInState: string,
        date: Date[]
    ): void {
        DatePickerHelper.setHoursToNormalizeTimezone(date[0]);

        let stateString = startDate.year + '-';
        stateString    += DatePickerHelper.attachLeadingZero(startDate.month) + '-';
        stateString    += DatePickerHelper.attachLeadingZero(startDate.day);

        let dateString = date[0].getFullYear() + '-';
        dateString    += DatePickerHelper.attachLeadingZero(date[0].getMonth() + 1)  + '-';
        dateString    += DatePickerHelper.attachLeadingZero(date[0].getDate());

        if (stateString <= dateString) {
            updateStateMethod(endDateNameInState, DatePickerHelper.stateFromDate(date[0]));
        }
    }

    static attachLeadingZero(num: number): string {
        let s = num + '';

        if (num < 10) {
            s = '0' + s;
        }

        return s;
    }

    static stateFromDate(date: Date): Ymd {
        return {
            year: date.getUTCFullYear(),
            month: date.getUTCMonth() + 1,
            day: date.getDate(),
        };
    }

    static setHoursToNormalizeTimezone(date: Date) {
        // Die Zeitzone wird auf die Stunden des Tages gerechnet, damit diese den UTC Zeitpunkt 0:00 des Tages ergeben.
        const timezoneOffset = date.getTimezoneOffset();
        const milliseconds   = ((timezoneOffset) * 60 * 1000);

        // Fix für alles was östlich ist.
        if (timezoneOffset < 0) {
            const timestamp = date.getTime() - milliseconds;

            date.setTime(timestamp);
        }
    }

    static getYesterday(): Ymd {
        const newDate = new Date();
        newDate.setDate(newDate.getDate() - 1);

        return {
            year: newDate.getUTCFullYear(),
            month: newDate.getUTCMonth() + 1,
            day: newDate.getDate(),
        };
    }
    
    static initDate(date: string): Ymd {
        if (typeof date !== 'string') {
            return DatePickerHelper.getYesterday();
        }

        const arraystuff = date.split('-');

        return  {
            year: parseInt(arraystuff[0]),
            month: parseInt(arraystuff[1]),
            day: parseInt(arraystuff[2]),
        };
    }

    static getStringFromYmd(ymd: Ymd): string {
        const month = DatePickerHelper.attachLeadingZero(parseInt(ymd.month));
        const day   = DatePickerHelper.attachLeadingZero(parseInt(ymd.day));

        return ymd.year + '-' + month + '-' + day;
    }
}

export default DatePickerHelper;
export type { Ymd };

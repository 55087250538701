import React from 'react';
import PageTitle from "../../../Layout/PageTitle";
import Ajax from "../../../../Helper/Ajax";
import Error from "../../../App/components/Messages/Error";
import type {FaqTypes} from "../../../Admin/components/Faq/AdminFaqList";
import Faq from "./Faq";

type FaqListState = {
    faqs: FaqTypes,
    language: string,
    isErrorCritical: boolean,
    errorMessage: string,
}

class FaqList extends React.Component<{}, FaqListState>  {
    state: FaqListState = {
        faqs: [],
        language: 'DE',
        isErrorCritical: true,
        errorMessage: '',
    };

    loadFromApi() {
        const promise: Promise<string> = Ajax.createGetXhr(process.env.REACT_APP_API_URL + '/faq/list');

        promise.then(response => {
            const parsedResponse = JSON.parse(response);

            if (parsedResponse.errorMessage || parsedResponse.warningMessage) {
                this.setState({
                    isErrorCritical: !parsedResponse.success,
                    errorMessage: parsedResponse.errorMessage || parsedResponse.warningMessage,
                });
                return;
            }

            let list = [];

            if (parsedResponse.data) {
                list = parsedResponse.data;
            }

            this.setState({
                faqs: list,
                errorMessage: '',
            });
        }).catch(error => {
            console.error(error);
            this.setState({
                errorMessage: 'An unexpected error occured. Please try again later.',
            });
        });
    }

    componentDidMount(): void {
        this.loadFromApi()
    }


    renderFaqs() {
        let column  = [];
        for (let faq of this.state.faqs) {
            let title = faq.englishTitle;
            let text = faq.englishText;

            if (this.state.language === 'DE') {
                title = faq.germanTitle;
                text = faq.germanText;
            }

            let faqComponent = <Faq
                    key={faq.id}
                    title={title}
                    text={text}
                />;

            column.push(faqComponent);
        }

        return (
            <>
                <div className="col-12 faq-text">
                    {column}
                </div>
            </>
        )
    }

    swapLanguage(): void {
        if(this.state.language === 'EN') {
            this.setState({
                ...this.state,
                language: 'DE',
            });

            return;
        }

        this.setState({
            ...this.state,
            language: 'EN',
        });
    }

    displayLanguageForSwap(): string {
         if (this.state.language === 'DE') {
             return 'EN';
         }

         return 'DE';
    }

    render() {
        return (
            <>
                <PageTitle
                    title="FAQ"
                    breadcrumbs={[
                        {
                            name: 'Dashboard',
                            link: '/dashboard',
                        },
                        {
                            name: 'FAQ',
                        },
                    ]}
                />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="text-center">
                                <i className="h1 mdi mdi-comment-multiple-outline text-muted"/>
                                <h3 className="mb-3">Frequently Asked Questions</h3>
                                <button type="button"
                                        className="btn btn-secondary waves-effect waves-light mt-2 mr-1"
                                        onClick={() => {this.swapLanguage()}}
                                ><i className="mdi mdi-swap-horizontal"/> Switch: {this.displayLanguageForSwap()}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        {this.renderFaqs()}
                    </div>
                    <Error
                        isErrorCritical={ this.state.isErrorCritical }
                        errorMessage={ this.state.errorMessage }
                    />
                </div>
            </>
        );
    }
}

export default FaqList;